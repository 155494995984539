import CdnImage from 'components/util/CdnImage'
// import CloudinaryImage from 'components/util/CloudinaryImage'
import React from 'react'
import LinkButton, { LinkButtonProp } from '../common/button'
import './index.css'
import bulletPointFallbackImage from '../../../images/BulletPoint.svg'

interface Props {
  cloudinaryMainImage: any
  builderMainImage: any
  mainImageAltText: string
  cloudinarySecondaryImage: any
  builderSecondaryImage: any
  secondaryImageAltText: string
  cloudinaryThirdImage: any
  builderThirdImage: any
  thirdImageAltText: string
  squareBackgroundColor: string
  headingText: string
  headingTextColor: string
  subHeadingText: string
  subHeadingTextColor: string
  paragraph: string
  paragraphColor: string
  imagesPosition: string
  buttonEnabled: boolean
  buttonProp: LinkButtonProp
  bulletPoint: string
  bulletAltText: string
  points: [{ text: string }]
}

const ProductFeatures = ({
  cloudinaryMainImage,
  builderMainImage,
  mainImageAltText = '',
  cloudinarySecondaryImage,
  builderSecondaryImage,
  secondaryImageAltText = '',
  cloudinaryThirdImage,
  builderThirdImage,
  thirdImageAltText = '',
  squareBackgroundColor,
  headingText,
  headingTextColor,
  subHeadingText,
  subHeadingTextColor,
  paragraph,
  paragraphColor,
  imagesPosition,
  buttonEnabled,
  buttonProp,
  bulletPoint,
  bulletAltText = '',
  points
}: Props) => {
  return (
    <div id={subHeadingText} className="bg-white">
      <div className="mx-auto max-w-2xl py-24 px-4 sm:py-32 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="grid grid-cols-1 items-center gap-y-16 gap-x-8 lg:grid-cols-2">
          <div className={imagesPosition === 'left' ? 'order-last' : ''}>
            <div className="border-b border-gray-200 pb-10">
              <h2 className="font-serif font-medium text-gray-500" style={{ color: headingTextColor }}>
                {headingText}
              </h2>
              <p
                className="mt-2 font-serif text-3xl font-medium tracking-tight text-gray-900 sm:text-4xl"
                style={{ color: subHeadingTextColor }}
              >
                {subHeadingText}
              </p>
            </div>

            <dl className="mt-10 space-y-10" style={{ color: paragraphColor }}>
              <div dangerouslySetInnerHTML={{ __html: paragraph }}></div>
              {points?.length > 0 && (
                <div>
                  {points?.map(point => (
                    <div className="flex items-center">
                      <img src={bulletPoint || bulletPointFallbackImage} className="mr-2 h-8 w-8" alt={bulletAltText} />
                      <p className="font-normal text-black">{point.text}</p>
                    </div>
                  ))}
                </div>
              )}
            </dl>
          </div>

          <div>
            <div className="aspect-w-1 aspect-h-1 relative">
              <div
                style={{ backgroundColor: squareBackgroundColor }}
                className="absolute -left-[3%] h-full w-full -rotate-3 transform"
              >
                <CdnImage
                  className="relative h-full w-full rotate-3 transform object-cover object-center"
                  url={builderMainImage}
                  alt={mainImageAltText}
                  steps={[625]}
                />

                {/* {builderMainImage ? (
                  <CdnImage
                    className="relative h-full w-full rotate-3 transform object-cover object-center"
                    url={builderMainImage}
                    alt={mainImageAltText}
                    steps={[625]}
                  />
                ) : (
                  <CloudinaryImage
                    className="relative h-full w-full rotate-3 transform object-cover object-center"
                    publicId={cloudinaryMainImage?.public_id}
                    alt={mainImageAltText}
                    steps={[625]}
                  />
                )} */}
              </div>
            </div>
            {(builderSecondaryImage || builderThirdImage) && (
              <div className="mt-4 grid grid-cols-2 gap-4 sm:mt-6 sm:gap-6 lg:mt-8 lg:gap-8">
                {builderSecondaryImage && (
                  <div className="aspect-w-1 aspect-h-1 relative">
                    <div
                      style={{ backgroundColor: squareBackgroundColor }}
                      className="absolute -left-[3%] h-full w-full -rotate-3 transform"
                    >
                      <CdnImage
                        className="relative h-full w-full rotate-3 transform object-cover object-center"
                        url={builderSecondaryImage}
                        alt={secondaryImageAltText}
                        steps={[300]}
                      />

                      {/* {builderSecondaryImage ? (
                        <CdnImage
                          className="relative h-full w-full rotate-3 transform object-cover object-center"
                          url={builderSecondaryImage}
                          alt={secondaryImageAltText}
                          steps={[300]}
                        />
                      ) : (
                        <CloudinaryImage
                          className="relative h-full w-full rotate-3 transform object-cover object-center"
                          publicId={cloudinarySecondaryImage?.public_id}
                          alt={secondaryImageAltText}
                          steps={[300]}
                        />
                      )} */}
                    </div>
                  </div>
                )}
                {/* {cloudinaryThirdImage?.public_id && ( */}
                <div className="aspect-w-1 aspect-h-1 relative">
                  <div
                    style={{ backgroundColor: squareBackgroundColor }}
                    className="absolute -left-[3%] h-full w-full -rotate-3 transform"
                  >
                    {builderThirdImage && (
                      <CdnImage
                        className="relative h-full w-full rotate-3 transform object-cover object-center"
                        url={builderThirdImage}
                        alt={thirdImageAltText}
                        steps={[300]}
                      />
                      // ) : (
                      //   <CloudinaryImage
                      //     className="relative h-full w-full rotate-3 transform object-cover object-center"
                      //     publicId={cloudinaryThirdImage?.public_id}
                      //     alt={thirdImageAltText}
                      //     steps={[300]}
                      //   />
                    )}
                  </div>
                </div>
                {/* // )} */}
              </div>
            )}
          </div>
        </div>
        {buttonEnabled && (
          <div className="mx-auto mt-10">
            <LinkButton
              buttonColor={buttonProp?.buttonColor}
              buttonText={buttonProp?.buttonText}
              loggedInButtonText={buttonProp?.loggedInButtonText}
              buttonTextColor={buttonProp?.buttonTextColor}
              buttonUrl={buttonProp?.buttonUrl}
              isLink={buttonProp.isLink}
              classList={
                'mx-auto w-full md:w-fit flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-full text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:text-lg'
              }
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default ProductFeatures
