import { AddressFinder } from '@ideal-postcodes/address-finder'
import { navigate } from '@reach/router'
import { Auth } from 'aws-amplify'
import FaqAccordion from 'components/ui/faq-accordion'
import ErrorModal from 'components/ui/modal/ErrorModal'
import StickyFooter from 'components/ui/sticky-footer/StickyFooter'
import Toast from 'components/ui/toast/Toast'
import dayjs from 'dayjs'
import { HeadingClass, HeadingType } from 'enums'
import { Field, Formik, useFormikContext } from 'formik'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useState, useEffect, useRef } from 'react'
import { CalendarContainer } from 'react-datepicker'
import { DatePicker } from 'react-formik-ui'
import { DefaultRootState, useDispatch, useSelector } from 'react-redux'
import { RootState } from 'state'
import { resetFlowDogs } from 'state/actions/account/flow/dogAction'
import { resetFlowPlan, updatePlanEditedStatus } from 'state/actions/account/flow/planAction'
import {
  createShippingConfirmDogsExisting,
  resetFlowShipping,
  saveShippingConfirmForm
} from 'state/actions/account/flow/shippingAction'
import { updateCouponCode } from 'state/actions/parentAction'
import { fetchPlanSchedule } from 'state/apis/account/planApi'
import Api from 'state/apis/api'
import { PlanType } from 'state/types/planType'
import { AccountReviewPlanSchema } from 'utils/yup-validation-schema'
import loadingGIF from '../../../../images/loadingspinner.gif'

const ShippingConfirmAccount = () => {
  const {
    recipesSelected,
    addonsSelected,
    plan,
    noOfPacks,
    weeks,
    packSize,
    fixedOrCustomPlan,
    packType
  }: PlanType | any = useSelector((state: RootState) => state.accountDogPlanFlow)
  const { dogInfo, defaultPack, pack, shippingDates, treatDiscount } = useSelector(
    (state: RootState) => state.accountDogFlow
  )
  const { shipping, errorMessage, changed, loading } = useSelector((state: RootState) => state.accountDogShippingFlow)
  const { plans } = useSelector((state: RootState) => state.accountPlan)
  const { summary } = useSelector((state: RootState) => state.accountSummary)
  const { spoofMode, spoofedUserId } = useSelector((state: RootState) => state.ui)
  const {
    coupon,
    isValidCoupon,
    currCouponCode,
    lastValidatedCoupon,
    loading: isCouponValidationLoading
  } = useSelector((state: RootState) => state.dog)
  const dispatch = useDispatch()
  const [localErrorMessage, setErrorMessage] = useState<string | undefined>()
  const [couponCode, setCouponCode] = useState(dogInfo?.defaultCoupon?.code || '')
  const [doesCouponValidated, setDoesCouponValidated] = useState(true)
  const [showPopup, setShowPopup] = useState(false)
  const [totalAddonPrice, setTotalAddonPrice] = useState(0)
  const [_subTotal, setSubTotal] = useState(0)

  const [currentPrices, setCurrentPrices] = useState(null)
  const [pricesError, setPricesError] = useState(false)

  const [pricesLoading, setPricesLoading] = useState(false)

  const [isPlanInRedZone, setIsPlanInRedZone] = useState(false)
  const [planStatus, setPlanStatus] = useState('')

  const [dateChanged, setDateChanged] = useState(dogInfo.nextDelivery || null)
  const [activeDeliveryPrice, setActiveDeliveryPrice] = useState(399)
  const [priceDateChange, setPriceDateChange] = useState('')
  const [freeSubscription, setFreeSubscription] = useState(false)
  const [newCoupon, setNewCoupon] = useState(dogInfo.defaultCoupon || null)
  const [showToast, setShowToast] = useState(false)
  const [toastMsg, setToastMsg] = useState('')

  var formikRef = useRef<any>()

  useEffect(() => {
    // Upgrade or downgrade of plan
    if (currentPrices?.subtotal) {
      const plan = plans?.filter((p: any) => p.subscriptionId === dogInfo?.subscriptionId)
      if (plan?.length) {
        const planPrice = plan[0]?.price
        if (planPrice !== currentPrices?.subtotal) {
          const planChange = planPrice < currentPrices?.subtotal ? 'Upgrade' : 'Downgrade'
          if (typeof window.gtag !== 'undefined') {
            window.gtag('event', `plan_change_${planChange}`, {
              page: 'account_flow_plan'
            })
          }
        }
      }
    }
  }, [currentPrices])

  useEffect(() => {
    // Abandon popup handler
    const abandonHandler = (e: any) => {
      e.preventDefault()
      // Chrome requires returnValue to be set
      e.returnValue = ''
    }

    window.addEventListener('beforeunload', abandonHandler)
    dispatch(updatePlanEditedStatus(true))
    return () => {
      window.removeEventListener('beforeunload', abandonHandler)
    }
  }, [])

  // Handle free subscription
  useEffect(() => {
    if (newCoupon?.type === 'amount') {
      if (newCoupon?.amount > currentPrices?.discount) {
        setFreeSubscription(true)
      }
    }
  })

  useEffect(() => {
    if (currCouponCode) setCouponCode(currCouponCode)
    handleValidateCouponCode()
  }, [coupon])

  useEffect(() => {
    if (isValidCoupon) {
      setNewCoupon(lastValidatedCoupon)
      setDoesCouponValidated(true)
    }
  }, [isValidCoupon])

  useEffect(() => {
    const weekdays = shippingDates.filter(date => date.price != '499')
    if (weekdays.find(day => day.price === 399)) {
      const priceChangeDate = weekdays.find(day => day.price === 399)
      if (priceChangeDate && weekdays.find(day => day.price === 0)) {
        const orderDate = weekdays.findIndex(day => day.price === 399)
        const shippingDate = weekdays[orderDate + 4]
        setPriceDateChange(dayjs(shippingDate.date).format('Do MMMM'))
      }
    }
  }, [shippingDates, priceDateChange])

  useEffect(() => {
    if (formikRef?.current?.values?.deliveryDate) {
      const selectedDate: any[] = shippingDates?.filter(
        (dateFromShippingDates: any) =>
          dayjs(dateFromShippingDates.date).format('DD/MM/YYYY') ===
          dayjs(formikRef?.current?.values?.deliveryDate).format('DD/MM/YYYY')
      )

      if (selectedDate?.length === 1) {
        setActiveDeliveryPrice(selectedDate[0].price)
      }
    }
  }, [formikRef?.current?.values?.deliveryDate])

  useEffect(() => {
    if (plans) {
      plans?.map(p => {
        if (p.subscriptionId === dogInfo.subscriptionId && p.redZone) {
          setIsPlanInRedZone(true)
        }
        if (p.subscriptionId === dogInfo.subscriptionId) {
          setPlanStatus(p.status)
        }
      })
    } else if (summary) {
      summary.plans?.map(p => {
        if (p.subscriptionId === dogInfo.subscriptionId) {
          setPlanStatus(p.status)
        }
      })
    }
  }, [dogInfo, plans, summary])

  useEffect(() => {
    let totalAddonPrice = 0
    addonsSelected.map((addon: any) => {
      totalAddonPrice += addon.price * addon.quantity
    })
    setTotalAddonPrice(totalAddonPrice)
    setSubTotal(totalAddonPrice + plan.totalValue)
  }, [])

  useEffect(() => {
    if (errorMessage || errorMessage?.popup) {
      setShowPopup(true)
    }
  }, [errorMessage])

  useEffect(() => {
    const removeStorageAndRedirect = async () => {
      const url = spoofMode ? '/account/plan/?userId=' + spoofedUserId : '/account/plan/'
      navigate(url)
    }

    if (changed) {
      setToastMsg('Plan updated successfully')
      setShowToast(true)
      setTimeout(() => {
        removeStorageAndRedirect()
      }, 500)
    }
  }, [changed])

  useEffect(() => {
    const planObj = {
      id: packSize + 'g-' + weeks + 'w',
      type: fixedOrCustomPlan,
      grams: packSize
    }

    if (packType !== 'CUSTOM') {
      planObj.level = pack.planLevel

      if (packType !== 'CUSTOM') {
        planObj.size = packType
      }
    }
    let coupon = ''
    if (couponCode && doesCouponValidated) coupon = couponCode
    // Only pass discount through if selected treats are eligible for discount
    const filteredAddons = plan.addons.filter((planAddon: any) =>
      addonsSelected.some((selectedAddon: any) => selectedAddon.id === planAddon.id)
    )
    const applicableAddon = filteredAddons.filter((addon: any) => addon.type == 'TREAT')
    if (!couponCode && [20, 30, 50].includes(treatDiscount) && applicableAddon.length > 0) {
      coupon = treatDiscount.toString()
    }

    if (recipesSelected && addonsSelected) {
      const url = spoofMode
        ? '/v1/private/admin/account/plan/estimate?userId=' + spoofedUserId
        : '/v1/private/account/plan/estimate'

      let deliveryDate = null
      if (formikRef?.current?.values?.deliveryDate) {
        deliveryDate = dayjs(formikRef?.current?.values?.deliveryDate).format('YYYY-MM-DD')
      }
      const selectedAddOn = addonsSelected.map((item: any) => {
        return { id: item.id, quantity: item.quantity }
      })

      const selectedRecipes = recipesSelected.map((item: any) => {
        return { id: item.id, quantity: item.quantity }
      })

      setPricesLoading(true)
      Api.put('wwwREST', url, {
        body: {
          coupon: coupon,
          recipes: selectedRecipes,
          addons: selectedAddOn,
          plan: planObj,
          subscriptionId: dogInfo.subscriptionId,
          deliveryDate
        }
      })
        .then(({ current }: any) => {
          setCurrentPrices(current)
          setPricesError(false)
          setPricesLoading(false)
          if (current.discount > 0 && coupon === dogInfo?.defaultCoupon?.code) setDoesCouponValidated(true)
        })
        .catch((err: any) => {
          if (err?.response?.data?.popup) {
            setErrorMessage(err?.response?.data?.popup)
            setDoesCouponValidated(false)
            setPricesLoading(false)
          } else {
            console.error(JSON.stringify(err, null, 2))
            setPricesError(true)
            setPricesLoading(false)
          }
        })
    }
  }, [dateChanged, lastValidatedCoupon, newCoupon])

  const recipesList = plan.recipes.map((r: any) => {
    const matched = recipesSelected.map((rs: any) => {
      if (rs.id === r.id) {
        const quantityDifference = r.quantity > 0 ? rs.quantity - r.quantity : 0
        return (
          <p className={`mb-2 ${quantityDifference !== 0 ? 'font-bold' : ''}`}>
            {rs.quantity + ' x ' + r.name} {quantityDifference > 0 && `(` + quantityDifference + ' added)'}{' '}
            {quantityDifference < 0 && `(` + Math.abs(quantityDifference) + ' removed)'}
          </p>
        )
      }
    })
    return matched
  })

  const removedRecipesList = () => {
    const selectedRecipeIds = recipesSelected.map((rs: any) => rs.id)
    const allRecipeIds = plan.recipes.map((r: any) => r.id)
    const removedRecipes: any = []

    allRecipeIds.forEach((rId: string) => {
      if (selectedRecipeIds.indexOf(rId) === -1) {
        plan.recipes.forEach((pr: any) => {
          if (pr.id === rId) {
            if (pr.quantity > 0) {
              removedRecipes.push(
                <p className="mb-2">
                  <span className="strikethrough strikethrough-regular">
                    {pr.quantity} x {pr.name}
                  </span>
                </p>
              )
            }
          }
        })
      }
    })

    return removedRecipes
  }

  const addonsList = plan.addons.map((a: any) => {
    const matched = addonsSelected.map((as: any) => {
      if (as.id === a.id) {
        const quantityDifference = a.quantity > 0 ? as.quantity - a.quantity : 0
        return (
          <p className={`mb-2 ${quantityDifference !== 0 ? 'font-bold' : ''}`}>
            {as.quantity + ' x ' + a.name} {quantityDifference > 0 && `(` + quantityDifference + ' added)'}{' '}
            {quantityDifference < 0 && `(` + Math.abs(quantityDifference) + ' removed)'}
          </p>
        )
      }
    })
    return matched
  })

  const removedAddonsList = () => {
    const selectedAddonIds = addonsSelected.map((as: any) => as.id)
    const allAddonIds = plan.addons.map((a: any) => a.id)
    const removedAddons: any = []

    allAddonIds.forEach((aId: string) => {
      if (selectedAddonIds.indexOf(aId) === -1) {
        plan.addons.forEach((ar: any) => {
          if (ar.id === aId) {
            if (ar.quantity > 0) {
              removedAddons.push(
                <p className="mb-2">
                  <span className="strikethrough strikethrough-regular">
                    {ar.quantity} x {ar.name}
                  </span>
                </p>
              )
            }
          }
        })
      }
    })

    return removedAddons
  }

  const getDiscountedPrice = (price: number) => {
    let total = price
    if (currentPrices?.credit > 0) {
      return price - currentPrices?.credit / 100
    }
    if (price > 0 && newCoupon?.code) {
      if (newCoupon?.type === 'percentage') {
        return price - (newCoupon?.amount / 100) * price
      } else if (newCoupon?.type === 'amount') {
        return currentPrices?.total / 100
      }
      if (freeSubscription) {
        return 0
      }
      if (price < newCoupon?.amount / 100) {
        setFreeSubscription(true)
        return newCoupon.amount / 100 - price
      }
    }
    if (currentPrices?.total !== currentPrices?.subtotal && currentPrices?.deliveryDiscount !== 0) {
      return (currentPrices?.total - (currentPrices?.delivery - currentPrices?.deliveryDiscount)) / 100
    }
    return total
  }

  const handleValidateCouponCode = async () => {
    setDoesCouponValidated(false)
    if (!isCouponValidationLoading && couponCode) {
      if (couponCode === dogInfo?.defaultCoupon?.code) {
        setDoesCouponValidated(true)
        dispatch(updateCouponCode(couponCode as string))
      } else {
        const url = spoofMode
          ? '/v1/private/admin/account/plan/coupon?userId=' + spoofedUserId
          : '/v1/private/account/plan/coupon'
        Api.post('wwwREST', url, { body: { coupon: couponCode } })
          .then(res => {
            setErrorMessage('')
            setDoesCouponValidated(true)
            dispatch(updateCouponCode(couponCode as string))
            setNewCoupon(res.couponCode)
          })
          .catch(err => {
            setErrorMessage(err?.response?.data?.popup)
          })
      }
    }
  }

  const backClick = async () => {
    if (formikRef.current) {
      dispatch(saveShippingConfirmForm(formikRef.current.values, fixedOrCustomPlan))
    }
    const url = spoofMode ? '/account/treats/?userId=' + spoofedUserId : '/account/treats/'
    navigate(url)
  }

  const nextClick = (call: any) => {
    call()
  }

  const handleSetShowToast = (show: boolean) => {
    setShowToast(show)
  }

  const getBillingDate = () => {
    if (formikRef.current && formikRef.current.values.deliveryDate) {
      const date = dayjs(formikRef.current.values.deliveryDate).subtract(4, 'day')
      return date.isBefore(dayjs())
        ? 'You will be charged today'
        : 'You will not be charged until ' + date.format('DD-MM-YYYY')
    }
    return ''
  }

  return (
    <>
      <div className="mb-14">
        <div>
          <Formik
            innerRef={formikRef}
            initialValues={{
              deliveryDate: dogInfo?.nextDelivery,
              sendEmailNotification: true
            }}
            validationSchema={AccountReviewPlanSchema}
            onSubmit={async (values: any, { setSubmitting }) => {
              let coupon = ''
              if (couponCode && doesCouponValidated) coupon = couponCode
              // Only pass discount through if selected treats are eligible for discount
              const filteredAddons = plan.addons.filter((planAddon: any) =>
                addonsSelected.some((selectedAddon: any) => selectedAddon.id === planAddon.id)
              )
              const applicableAddon = filteredAddons.filter((addon: any) => addon.type == 'TREAT')
              if (!couponCode && [20, 30, 50].includes(treatDiscount) && applicableAddon.length > 0) {
                coupon = treatDiscount.toString()
              }

              const deliveryDate = dayjs(values.deliveryDate).format('YYYY-MM-DD')
              const planObj = {
                id: packSize + 'g-' + weeks + 'w',
                type: fixedOrCustomPlan,
                grams: packSize
              }

              if (fixedOrCustomPlan !== 'CUSTOM') {
                planObj.level = pack.planLevel
                planObj.size = packType
              }

              const selectedAddOn = addonsSelected.map((item: any) => {
                return { id: item.id, quantity: item.quantity }
              })

              const selectedRecipes = recipesSelected.map((item: any) => {
                return { id: item.id, quantity: item.quantity }
              })

              if (plans && plans.length > 0) {
                plans.map(plan => {
                  if (plan.subscriptionId === dogInfo.subscriptionId) {
                    if (plan.status === 'paused') {
                      if (typeof window.gtag !== 'undefined') {
                        window.gtag('event', 'resume_paused_plan', {
                          page: 'account_flow_plan'
                        })
                      }
                    } else if (plan.status === 'cancelled') {
                      if (typeof window.gtag !== 'undefined') {
                        window.gtag('event', 'reactivate_cancelled_plan', {
                          page: 'account_flow_plan'
                        })
                      }
                    }
                  }
                })
              }

              const dataToForward: any = {
                coupon: coupon,
                recipes: selectedRecipes,
                addons: selectedAddOn,
                deliveryDate,
                subscriptionId: dogInfo.subscriptionId,
                plan: planObj,
                dogs: dogInfo.dogs,
                sendEmailNotification: values.sendEmailNotification
              }
              await dispatch(createShippingConfirmDogsExisting(dataToForward))
              dispatch(updatePlanEditedStatus(false))
              setSubmitting(false)
            }}
            validateOnMount
          >
            {props => {
              return (
                <div>
                  <div className="grid grid-cols-1 items-start gap-y-6 p-4 sm:gap-x-8">
                    <div className="grid grid-cols-1 gap-y-3 rounded-xl border border-third bg-thankyouBG p-4 text-center">
                      <h1 data-testid="reviewYourPlan" className="p-4 text-center text-3xl tracking-tight sm:text-5xl">
                        Review Your Plan
                      </h1>

                      <div className="m-auto flex w-full flex-col text-left md:w-2/3">
                        {isPlanInRedZone && (
                          <div className="relative mx-auto mt-8 mb-4 w-full rounded-xl border border-[#FB5D34] bg-[#FAD1CD] p-4 pl-16 text-sm">
                            <StaticImage
                              width={64}
                              className="absolute -top-2 -left-2 md:-left-4 md:-top-4"
                              src="../../../../images/change-delivery-date.svg"
                              alt="Order processing"
                              imgStyle={{ position: 'absolute' }}
                              style={{ position: 'absolute' }}
                            />
                            <p>
                              Please note that you have an order processing which cannot be changed. These changes will
                              apply to future orders only.
                            </p>
                          </div>
                        )}

                        <div className="flex w-full flex-wrap items-start border-b border-[#C4C4C4] py-8">
                          <h2 data-testid="deliveryDate" className="mb-4 w-full text-lg md:mb-0 md:w-1/2">
                            Delivery Date
                          </h2>
                          <label className="w-full md:w-1/2">
                            {/* @ts-ignore This library has some type conflicts with one of its dependency */}
                            <DatePicker
                              data-testid="deliveryDate"
                              name="deliveryDate"
                              disabled={shippingDates?.length === 0}
                              placeholder="Select delivery date"
                              calendarStartDay={1}
                              dateFormat="dd/MM/yyyy"
                              includeDates={shippingDates?.map(date => new Date(date.date))}
                              className="flex w-full rounded-md border border-gray-300 bg-white p-2 px-4 py-3 text-center focus:border-secondary focus:ring-secondary"
                              inputmode="none"
                              onFocus={(e: any) => e.target.blur()}
                              popperPlacement="bottom"
                              onSelect={(date: Date) => {
                                props.setFieldValue('deliveryDate', date)
                                setDateChanged(date)
                              }}
                              dayClassName={(date: any) => {
                                const dateObj = new Date(date)
                                const validDate = shippingDates?.filter(
                                  dateFromShippingDates =>
                                    dayjs(dateFromShippingDates.date).format('DD/MM/YYYY') ===
                                    dayjs(date).format('DD/MM/YYYY')
                                )

                                if (validDate?.length === 1 && dateObj.getDay() === 6) {
                                  return '!text-red-500'
                                } else if (validDate?.length === 0 && dateObj.getDay() === 6) {
                                  return '!text-red-300'
                                } else {
                                  return undefined
                                }
                              }}
                              calendarContainer={({ className, children }) => (
                                <div>
                                  <CalendarContainer className={className}>
                                    <div style={{ position: 'relative' }}>{children}</div>
                                    <div className="w-[240px] p-2 pt-0">
                                      <span className="w-auto font-bold">
                                        {activeDeliveryPrice === 0 &&
                                          !priceDateChange &&
                                          currentPrices?.delivery === 0 &&
                                          'Free weekday delivery on first order'}
                                        {activeDeliveryPrice === 0 &&
                                          !priceDateChange &&
                                          currentPrices?.delivery === 399 &&
                                          'Weekday delivery'}
                                        {(activeDeliveryPrice === 0 || activeDeliveryPrice === 399) &&
                                          priceDateChange &&
                                          'Free weekday delivery before ' + priceDateChange + ', £3.99 after this date'}
                                        {activeDeliveryPrice === 399 && !priceDateChange && '£3.99 Weekday delivery'}
                                      </span>
                                      <span className="flex w-full font-bold text-red-400">
                                        £4.99 - Saturday delivery
                                      </span>
                                    </div>
                                  </CalendarContainer>
                                </div>
                              )}
                            />
                            {props.errors.deliveryDate && props.touched.deliveryDate ? (
                              <div className="text-xs text-red-500">{props.errors.deliveryDate}</div>
                            ) : null}
                          </label>
                        </div>

                        <div className="flex w-full flex-wrap items-start border-b border-[#C4C4C4] py-8">
                          <h2 className="mb-4 w-full text-lg md:mb-0 md:w-1/2">Recipe Summary ({plan.packSize}g)</h2>
                          <div className="w-full md:w-1/2">
                            {recipesList} {removedRecipesList()}
                          </div>
                        </div>

                        {(addonsList || removedAddonsList()) && (
                          <div className="flex w-full flex-wrap items-start border-b border-[#C4C4C4] py-8">
                            <h2 className="mb-4 w-full text-lg md:mb-0 md:w-1/2">Treats and Extras</h2>
                            <div className="w-full md:w-1/2">
                              {addonsList} {removedAddonsList()}
                            </div>
                          </div>
                        )}

                        {(planStatus === 'cancelled' || planStatus === 'paused') && (
                          <div className="flex w-full flex-wrap items-start border-b border-[#C4C4C4] py-8">
                            <h2 className="mb-4 w-full text-lg md:mb-0 md:w-1/2">Discount</h2>
                            <div className={`relative flex w-full flex-row md:w-1/2`}>
                              <div className="w-full">
                                <input
                                  className={`coupon peer mb-2 block w-full self-center rounded-md border border-gray-300 bg-white pt-6 text-center focus:border-highlight focus:ring-highlight ${
                                    couponCode && !doesCouponValidated && !isValidCoupon
                                      ? 'field-error'
                                      : isValidCoupon && 'field-success'
                                  } ${!isValidCoupon && `mr-4`}`}
                                  data-testid="coupon"
                                  id="coupon"
                                  name="coupon"
                                  type="text"
                                  value={couponCode ? couponCode : ''}
                                  onChange={e => setCouponCode(e.target.value)}
                                  onBlur={handleValidateCouponCode}
                                  autoComplete="off"
                                />
                                <label
                                  className={`absolute top-0 inline-flex h-14 w-full transform cursor-text appearance-none items-center justify-center duration-300 peer-focus:-ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                    couponCode ? 'ml-0 mb-2 -translate-y-3 scale-75' : 'ml-0'
                                  }`}
                                  htmlFor="coupon"
                                >
                                  Discount code
                                </label>
                                <div className="flex w-full items-center justify-center gap-x-2">
                                  {isValidCoupon ? (
                                    ''
                                  ) : (
                                    <button
                                      id="validateCoupon"
                                      type="button"
                                      className={`btn-primary flex h-full w-full flex-col items-center justify-center text-center`}
                                      onClick={handleValidateCouponCode}
                                    >
                                      {isCouponValidationLoading ? (
                                        <div className="align-center flex justify-center">
                                          <div className="border-white-900 mr-2 h-5 w-5 animate-spin rounded-full border-b-2"></div>
                                          <p>Checking</p>
                                        </div>
                                      ) : (
                                        'Validate Coupon'
                                      )}
                                    </button>
                                  )}
                                </div>
                                {couponCode && !doesCouponValidated && !isValidCoupon && localErrorMessage?.message && (
                                  <div className="p-2 pb-0 text-xs text-red-500">{localErrorMessage?.message}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {(currentPrices?.credit || currentPrices?.credit > 0) && (
                          <div className="flex w-full flex-wrap items-start border-b border-[#C4C4C4] py-8">
                            <h2 className="mb-4 w-full text-lg md:mb-0 md:w-1/2">Available Credit</h2>
                            <div className="w-full md:w-1/2">
                              <span className="font-bold">£{(currentPrices?.credit / 100)?.toFixed(2)}</span>
                              <div>
                                <span className="text-xs">Automatically applied in the summary</span>
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="flex w-full flex-wrap items-start py-8">
                          <h2 className="mb-4 w-full text-lg md:w-1/2">Subscription Summary</h2>
                          <div className="w-full">
                            {((!currentPrices && !pricesError) || pricesLoading) && (
                              <div>
                                <div className="m-auto w-full text-center" style={{ marginTop: '5vh' }}>
                                  <img
                                    className="m-auto"
                                    width="200px"
                                    src={loadingGIF}
                                    alt="Loading, please wait..."
                                  />
                                </div>
                              </div>
                            )}
                            {pricesError && (
                              <div>
                                <p>There has been an error retrieving the plan price totals.</p>
                              </div>
                            )}
                            {currentPrices && !pricesError && !pricesLoading && (
                              <div className="border border-secondary p-4 text-sm text-black">
                                <div className="border-b-2 pb-2">
                                  <div className="flex flex-row justify-between">
                                    <span>{plan.weeks} weeks hand-cooked food</span>
                                    <span className="flex-shrink-0">
                                      <span className="mr-2">
                                        {(currentPrices?.discount > 0 || currentPrices.credit > 0) &&
                                          currentPrices?.subtotal !== currentPrices?.total && (
                                            <span className="strikethrough">
                                              £{((currentPrices?.plan + currentPrices?.recipes) / 100).toFixed(2)}
                                            </span>
                                          )}
                                      </span>
                                      <span data-testid="recipeTotal" className="font-bold">
                                        £
                                        {currentPrices?.discount > 0 &&
                                          currentPrices?.subtotal === currentPrices?.total &&
                                          ((currentPrices?.plan + currentPrices?.recipes) / 100).toFixed(2)}
                                        {(currentPrices?.discount > 0 || currentPrices.credit > 0) &&
                                          currentPrices?.subtotal !== currentPrices?.total &&
                                          getDiscountedPrice(
                                            (currentPrices?.plan + currentPrices?.recipes) / 100
                                          ).toFixed(2)}
                                        {currentPrices?.discount <= 0 &&
                                          currentPrices.credit <= 0 &&
                                          newCoupon?.type !== 'amount' &&
                                          ((currentPrices?.plan + currentPrices?.recipes) / 100).toFixed(2)}
                                      </span>
                                    </span>
                                  </div>

                                  {totalAddonPrice > 0 && (
                                    <div className="flex flex-row justify-between">
                                      <span>Treats &amp; Extras</span>
                                      <span className="flex-shrink-0">
                                        <span className="mr-2">
                                          {currentPrices?.addonDiscount > 0 && (
                                            <span className="strikethrough">
                                              £{(currentPrices?.addons / 100).toFixed(2)}
                                            </span>
                                          )}
                                        </span>
                                        <span className="font-bold">
                                          £
                                          {currentPrices?.addonDiscount > 0 &&
                                            newCoupon?.type !== 'amount' &&
                                            ((currentPrices?.addons - currentPrices?.addonDiscount) / 100).toFixed(2)}
                                          {newCoupon?.type === 'amount' && '0.00'}
                                          {currentPrices?.addonDiscount === 0 &&
                                            (currentPrices?.addons / 100).toFixed(2)}
                                        </span>
                                      </span>
                                    </div>
                                  )}

                                  <div className="flex flex-row justify-between">
                                    <span>Delivery</span>
                                    <div>
                                      <span className="flex-shrink-0">
                                        <span className="mr-2">
                                          {currentPrices?.deliveryDiscount > 0 && (
                                            <span className="strikethrough">
                                              £{(currentPrices?.delivery / 100).toFixed(2)}
                                            </span>
                                          )}
                                        </span>
                                      </span>
                                      {currentPrices?.deliveryDiscount !== 0 &&
                                        currentPrices?.delivery !== 0 &&
                                        newCoupon?.type !== 'amount' && (
                                          <span className="font-bold">
                                            £
                                            {currentPrices?.deliveryDiscount > 0 &&
                                              newCoupon?.type !== 'amount' &&
                                              (
                                                (currentPrices?.delivery - currentPrices?.deliveryDiscount) /
                                                100
                                              ).toFixed(2)}
                                            {currentPrices?.deliveryDiscount <= 0 &&
                                              newCoupon?.type !== 'amount' &&
                                              (currentPrices?.delivery / 100).toFixed(2)}
                                          </span>
                                        )}
                                      {currentPrices?.deliveryDiscount !== 0 &&
                                        currentPrices?.delivery !== 0 &&
                                        newCoupon?.type === 'amount' && (
                                          <span className="font-bold">
                                            {currentPrices?.deliveryDiscount > 0 &&
                                              newCoupon?.type === 'amount' &&
                                              'FREE'}
                                          </span>
                                        )}
                                      {currentPrices?.deliveryDiscount === 0 && currentPrices?.delivery !== 0 && (
                                        <span className="font-bold">
                                          £
                                          {currentPrices?.deliveryDiscount <= 0 &&
                                            (currentPrices?.delivery / 100).toFixed(2)}
                                        </span>
                                      )}
                                      {currentPrices?.deliveryDiscount === 0 &&
                                        currentPrices?.delivery === 0 &&
                                        currentPrices?.delivery !== 499 && (
                                          <>
                                            <span data-testid="deliveryFeeStrikethrough" className="strikethrough">
                                              {dayjs(formikRef.current?.values['deliveryDate'])?.day() === 6
                                                ? '£4.99'
                                                : '£3.99'}
                                            </span>
                                            <span
                                              data-testid="deliveryChargeSummary"
                                              className="pl-1 font-bold uppercase"
                                            >
                                              FREE
                                            </span>
                                          </>
                                        )}
                                    </div>
                                  </div>
                                </div>
                                <div className="flex flex-row justify-between py-2">
                                  <span className="font-bold">Total for {plan.weeks} weeks hand-cooked food</span>
                                  <span className="flex-shrink-0">
                                    <span className="mr-2">
                                      {currentPrices?.credit > 0 && (
                                        <span data-testid="totalRecipesStrikethrough" className="strikethrough">
                                          £{(currentPrices?.subtotal / 100).toFixed(2)}
                                        </span>
                                      )}
                                    </span>
                                    <span data-testid="total" className="font-bold">
                                      £{(currentPrices?.total / 100).toFixed(2)}
                                    </span>
                                  </span>
                                </div>
                                <div className="flex flex-row justify-between">
                                  <span className="font-bold"></span>
                                  <span className="flex-shrink-0 text-xs">
                                    {currentPrices?.credit > 0 && (
                                      <div>£{(currentPrices?.credit / 100).toFixed(2)} credit applied</div>
                                    )}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        {spoofMode && (
                          <div className="flex justify-center items-center flex-row mt-6">
                            <label className="flex flex-row items-center">
                              <Field
                                id="sendEmailNotification"
                                type="checkbox"
                                name="sendEmailNotification"
                                className="text-fourth-checkbox  h-6 w-6 rounded-[4px] ml-1"
                                onClick={(e: any) => {
                                  props.setFieldValue('sendEmailNotification', e.target.checked)
                                }}
                              />
                              <span className="flex-wrap pl-2 text-lg">Send confirmation email</span>
                            </label>
                          </div>
                        )}
                        {formikRef?.current?.values?.deliveryDate && (
                          <div className="flex justify-center items-center flex-row">
                            <span className="font-normal text-sm lg:text-base font-sans">&nbsp;{getBillingDate()}</span>
                          </div>
                        )}
                        <div className="flex flex-row justify-between py-8">
                          <button
                            data-testid="reviewPlanBack"
                            onClick={backClick}
                            type="button"
                            className="btn-secondary"
                          >
                            Back
                          </button>

                          <button
                            data-testid="confirmOrder"
                            onClick={() => nextClick(props.handleSubmit)}
                            disabled={!props.isValid || loading || props.isSubmitting}
                            type="submit"
                            className={`${!props.isValid || loading || props.isSubmitting ? 'opacity-50' : ''} btn-cta`}
                          >
                            {loading ? (
                              <div className="align-center flex justify-center">
                                <div className="border-white-900 mr-2 h-5 w-5 animate-spin rounded-full border-b-2"></div>
                                <div>Please wait</div>
                              </div>
                            ) : (
                              'Confirm'
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-12 w-full text-center font-serif">
                    <h3 className="mb-4">Have you got any questions?</h3>
                    <p>
                      Call us on{' '}
                      <a className="font-bold" href="tel:+441743384562">
                        01743 384 562
                      </a>
                    </p>
                    <p>
                      or email{' '}
                      <a className="font-bold" href="mailto:feedme@differentdog.com">
                        feedme@differentdog.com
                      </a>{' '}
                    </p>
                  </div>

                  <FaqAccordion
                    faqs={[
                      {
                        question: `When will I be billed?`,
                        answer: `You will be billed 4 days before your delivery arrives. For example, if you have selected a Wednesday delivery, we will attempt payment on the Saturday before. You can change your order until the day of billing.`
                      },
                      {
                        question: `Can I set a safe place for my delivery?`,
                        answer: `When your order is processed, you will receive an email or text message from DPD to advise you of your consignment number. To update your delivery instructions or to select a safe place for DPD to leave your delivery if you are not in, please either click on "Show my options" in the email you receive from DPD.`
                      }
                    ]}
                    backgroundColor={'#A6C7D1'}
                    headingText={'FAQs'}
                    headingTextColor={'primary'}
                    faqQuestionColor={'primary'}
                    faqAnswerColor={'primary'}
                    iconClass={'text-white'}
                    headingType={HeadingType.P}
                    headingClass={HeadingClass.H3}
                  />
                </div>
              )
            }}
          </Formik>
        </div>
      </div>
      <Toast showToast={showToast} msg={toastMsg} handleSetShowToast={handleSetShowToast} />
      {(errorMessage?.popup || localErrorMessage) && (
        <ErrorModal
          show={showPopup}
          setShow={setShowPopup}
          errorMessage={errorMessage?.popup?.message ?? localErrorMessage?.message}
          url={errorMessage?.popup?.url ?? localErrorMessage?.url}
          action={errorMessage?.popup?.action ?? localErrorMessage?.action}
          actionText={errorMessage?.popup?.actionText ?? localErrorMessage?.actionText}
        />
      )}
      {errorMessage && !errorMessage?.popup && !localErrorMessage && (
        <ErrorModal
          show={showPopup}
          setShow={setShowPopup}
          errorMessage={'Error updating subscription'}
          url={errorMessage?.popup?.url}
          action={errorMessage?.popup?.action}
          actionText={errorMessage?.popup?.actionText}
        />
      )}
    </>
  )
}

export default ShippingConfirmAccount
