import { Cache } from 'aws-amplify'
import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import updateDogsDetailsAlternateImage from '../../../../images/update-dog-details-alternate.svg'

interface AccordionProps {
  dogs: any
}

const UpdateDogAccordion: React.FC<AccordionProps> = ({ dogs }) => {
  const [dogsToUpdate, setDogsToUpdate] = useState<{}[]>([])
  const [showDogsToUpdateAccordion, setShowDogsToUpdateAccordion] = useState(true)
  const updateDogPrompt: boolean = Cache.getItem('updateDogPrompt')

  //  Check if updatedAt is greater than 3 months
  const dogToUpdate = (dog: any): boolean => {
    return new Date(dog.updatedAt) < new Date(new Date().setMonth(new Date().getMonth() - 3))
  }

  useEffect(() => {
    if (updateDogPrompt === false) {
      setShowDogsToUpdateAccordion(false)
    }
  }, [])

  useEffect(() => {
    const filteredDogs = dogs.filter(dog => dogToUpdate(dog))
    setDogsToUpdate(filteredDogs)
  }, [dogs])

  const hideUpdateDogPrompt = () => {
    Cache.setItem('updateDogPrompt', false, { expires: Date.now() + 30 * 24 * 60 * 60 * 1000 })
  }

  const renderButtons = () => {
    return (
      <>
        <button
          className="btn-secondary lg:hidden mb-6"
          onClick={() => {
            setShowDogsToUpdateAccordion(false)
            hideUpdateDogPrompt()
          }}
        >
          Hide this
        </button>
        <button
          className="btn-secondary !hidden lg:!flex mb-6"
          onClick={() => {
            setShowDogsToUpdateAccordion(false)
            hideUpdateDogPrompt()
          }}
        >
          Remind me later
        </button>
      </>
    )
  }

  return (
    <>
      {dogsToUpdate.length > 0 && showDogsToUpdateAccordion && (
        <div>
          {dogsToUpdate.length === 1 ? (
            dogsToUpdate.map((dog: any, index: number) => (
              <div
                key={index}
                className="mt-6 mx-auto flex w-full flex-wrap bg-ddBlue lg:flex-nowrap rounded-3xl lg:mx-auto"
              >
                <div className="w-auto">
                  <img
                    className="justify-center items-center m-6 w-full"
                    src={updateDogsDetailsAlternateImage}
                    alt="Update Dog Details"
                  />
                </div>

                <div className="flex h-1/3 w-2/3 xs:w-3/4 justify-between p-4 pb-0 md:p-4 md:pb-0 lg:w-2/3 xl:w-3/4 lg:px-6 lg:pb-4 xl:p-6">
                  <div className="w-full ml-6 my-2">
                    <p className="font-sans font-semibold text-xl text-base">Time to update {dog.name}'s details</p>
                    <p className="font-sans font-normal text-sm mt-2 mr-4">
                      As {dog.name} continues to grow and develop, it's important to update{' '}
                      {dog.gender === 'MALE' ? 'his' : 'her'} details regularly to ensure you are feeding{' '}
                      {dog.gender === 'MALE' ? 'him' : 'her'} the correct amount to help{' '}
                      {dog.gender === 'MALE' ? 'him' : 'her'} thrive.
                    </p>
                  </div>
                </div>

                <div className="w-full lg:w-1/3 xl:w-1/4 mt-3 flex-col items-center gap-y-4 lg:gap-y-8 text-left flex lg:mt-[1.25rem]">
                  <Link to="/account/dogs" className="btn-cta !px-6 font-sans !py-3">
                    Update {dog.name}'s details
                  </Link>
                  {renderButtons()}
                </div>
              </div>
            ))
          ) : (
            <div className="mt-6 mx-auto flex w-full flex-wrap bg-ddBlue lg:flex-nowrap rounded-3xl lg:mx-auto">
              <div className="w-auto">
                <img
                  className="justify-center items-center m-6 w-full"
                  src={updateDogsDetailsAlternateImage}
                  alt="Update Dog Details"
                />
              </div>

              <div className="flex h-1/3 w-2/3 xs:w-3/4 justify-between p-4 pb-0 md:p-4 md:pb-0 lg:w-full lg:px-6 lg:pb-4 xl:p-6">
                <div className="w-full ml-6 my-2">
                  <p className="font-sans font-semibold text-xl text-base">Time to update your dogs' details</p>
                  <p className="font-sans font-normal text-sm mt-2 lg:max-w-[750px]">
                    As your dogs continue to grow and develop, it's important to update their details regularly to
                    ensure you are feeding them the correct amount to help them thrive.
                  </p>
                </div>
              </div>

              <div className="w-full mt-3 flex-col items-center gap-y-4 lg:gap-y-8 text-left flex lg:mt-[1.25rem]">
                <Link to="/account/dogs" className="btn-cta !px-6 font-sans !py-3">
                  Update your dogs' details
                </Link>
                {renderButtons()}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default UpdateDogAccordion
