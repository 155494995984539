import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { navigate } from '@reach/router'
import { useLocation } from '@reach/router'
import TrustPilotMicroCombo from 'components/ui-builder/trustpilot/micro-combo'
import TrustPilotMini from 'components/ui-builder/trustpilot/mini'
import RecipeOrTreatAlterModal from 'components/ui/modal/RecipeOrTreatModalAlter'
import StickyFooter from 'components/ui/sticky-footer/StickyFooter'
import TrustBoxAlternate from 'components/ui/trustpilot/TrustBoxAlternate'
import CdnImage from 'components/util/CdnImage'
import { Field, Formik } from 'formik'
import { StaticImage } from 'gatsby-plugin-image'
import { parse, stringify } from 'query-string'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'state'
import { updateKey } from 'state/actions/keyAction'
import { addAddons, removeAddons, updateChangedStatus } from 'state/actions/planAction'
import { PlanType, RecipeType } from 'state/types/planType'
import { TreatsSchema } from 'utils/yup-validation-schema'
import BritishFlag from '../../../images/BritishFlag.svg'
import BulletPointImage from '../../../images/BulletPoint.svg'
import limitedEditionSticker from '../../../images/limited-edition-sticker.svg'
import NewSticker from '../../../images/new-sticker.svg'

const Treats = () => {
  const {
    plan,
    changed,
    loading,
    addonsSelected
  }: { plan: PlanType; changed: boolean; loading: boolean; addonsSelected: RecipeType[] } = useSelector(
    (state: RootState) => state.plan
  )
  const { dogInfo, coupon }: { dogInfo: DogType; coupon: any } = useSelector((state: RootState) => state.dog)
  const { lastValidatedCoupon } = useSelector((state: RootState) => state.dog)
  const { key } = useSelector((state: RootState) => state.key)
  const { addonList }: { addonList: RecipeAddonType[] } = useSelector((state: RootState) => state.products)
  const [addons, setAddons] = useState<Array<{ id: string; quantity: number; price: number }>>([])
  const [addonCount, setAddonCount] = useState(0)
  const [modalShow, setModalShow] = useState(false)
  const [modalActiveRecipe, setModalActiveRecipe] = useState({})

  const [freeTreatAvailable, setFreeTreatAvailable] = useState(true)
  const [freeTreatId, setFreeTreatId] = useState('')

  const fullAddonDetail: any = [] || addonList //used on the page (in order)
  const addonDetailMixed: any = [] || addonList //filtered list which is not in order

  // Add price to product list
  plan.addons.forEach(obj => {
    const matchingPriceObject: any = addonList.find(priceObj => priceObj.itemId === obj.itemId)
    addonDetailMixed.push({ ...matchingPriceObject, price: obj?.price })
  })

  // Order the product list to be used on page
  addonList.forEach(obj => {
    const matchingPriceObject: any = addonDetailMixed.find((item: any) => item.itemId === obj.itemId)
    if (matchingPriceObject) fullAddonDetail.push({ ...matchingPriceObject })
  })

  const treatsOnlyArray = fullAddonDetail
    .filter((a: any) => a.type === 'TREAT' && a.price < 700)
    .map((a: any) => a.itemId)

  const dispatch = useDispatch()
  const location = useLocation()

  const treatDetail: any = fullAddonDetail.filter(
    (addon: any) => addon.type === 'TREAT' && addon.type !== 'SUPPLEMENT' && addon.itemId !== 'TRET-PIZ3'
  )
  const supplementDetail: any = fullAddonDetail.filter(
    (addon: any) => addon.type !== 'TREAT' || addon.type === 'SUPPLEMENT' || addon.itemId === 'TRET-PIZ3'
  )

  useEffect(() => {
    if (key !== 'treats') {
      navigate(`/signup/${key}/${location.search && '?' + stringify(parse(location.search))}`)
    }
  }, [key])

  useEffect(() => {
    if (changed) {
      const updateKeyToCheckout = async () => {
        await dispatch(updateKey('checkout'))
      }
      updateKeyToCheckout()
      if (typeof window.gtag !== 'undefined') {
        window.gtag('event', 'chosen_addons', {
          page: 'sign_up_addon_pre_checkout'
        })
      }
    }
    return () => {
      dispatch(updateChangedStatus(false))
    }
  }, [changed])

  useEffect(() => {
    // Check if the addon list contains the old product Ids
    const oldAddonIds = addonsSelected.some(addonSelected =>
      addonList.some(addonList => addonList.itemId === addonSelected.id)
    )
    // If old product Ids, empty the  addon selected list
    if (!oldAddonIds) {
      dispatch(removeAddons())
    }
    if (addonList?.length > 0 && addonsSelected?.length === 0) {
      let _totalAddons = 0
      const addonsSelectedLocal: any = []
      addonList.map(addon => {
        if (addon.quantity > 0) {
          addonsSelectedLocal.push({ id: addon.itemId, quantity: addon.quantity })
          _totalAddons += addon.quantity
        }
      })
    }
  }, [])

  useEffect(() => {
    const discountEligibleAddonList = addons.map((a: any) => a.id).filter(item => treatsOnlyArray.includes(item))
    if (addons.length === 0 || discountEligibleAddonList.length === 0) {
      setFreeTreatId('')
      setFreeTreatAvailable(true)
    }

    if (discountEligibleAddonList.length > 0) {
      setFreeTreatId(discountEligibleAddonList[0])
      setFreeTreatAvailable(false)
    }
  }, [addons])

  useEffect(() => {
    if (addonsSelected) {
      let totalAddons = 0
      setAddons(addonsSelected)
      addonsSelected.forEach(addon => {
        totalAddons += addon.quantity
      })
      setAddonCount(totalAddons)
    }
  }, [addonsSelected])

  useEffect(() => {
    if (typeof window.gtag !== 'undefined' && addonList?.length > 0) {
      window.gtag('event', 'view_item_list', {
        page: 'sign_up_addon_pre_checkout',
        item_list_id: 'addons',
        item_list_name: 'Addons',
        items: addonList?.map(a => {
          return {
            item_id: a.itemId,
            item_name: a.name,
            coupon: lastValidatedCoupon.code,
            item_list_id: a.analytics?.item_list_id,
            item_list_name: a.analytics?.item_list_name
          }
        })
      })
    }
  }, [])

  const getDogNames = (dogs: any) => {
    return dogs
      .filter(d => d.name)
      .map(d => d.name)
      .join(', ')
      .replace(/,(?!.*,)/gim, ' &')
  }

  const backClick = () => {
    const updateKeyToRecipes = async () => {
      await dispatch(updateKey('recipes'))
    }

    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'clicked_back', {
        page: 'sign_up_addon_pre_checkout'
      })
    }

    updateKeyToRecipes()
  }

  const handleAddAddon = (addonId: string, price: number, outOfStock: boolean) => {
    if (addons) {
      const index = addons.findIndex(addon => addon.id === addonId)
      const previousAddonsIndex = addonList.findIndex((addon: any) => addon.itemId === addonId)

      if (index !== -1) {
        const addonsArr = [...addons]

        if (outOfStock) {
          if (addonsArr[index].quantity < addonList[previousAddonsIndex].quantity) {
            addonsArr[index].quantity = addonsArr[index].quantity + 1
            setAddons(addonsArr)
            setAddonCount(curr => curr + 1)
          }
        } else {
          addonsArr[index].quantity = addonsArr[index].quantity + 1
          setAddons(addonsArr)
          setAddonCount(curr => curr + 1)
        }
      } else {
        if (!outOfStock) {
          setAddons([...addons, { id: addonId, quantity: 1, price }])
          setAddonCount(curr => curr + 1)
        } else {
          if (addonList[previousAddonsIndex].quantity > 0) {
            setAddons([...addons, { id: addonId, quantity: 1, price }])
            setAddonCount(curr => curr + 1)
          }
        }
      }
    } else {
      if (!outOfStock) {
        setAddons([{ id: addonId, quantity: 1, price }])
        setAddonCount(curr => curr + 1)
      }
    }

    if (typeof window.gtag !== 'undefined' && addonList?.length > 0) {
      const index = addonList?.findIndex(addon => addon.itemId === addonId)
      const addonsIndex = addons?.findIndex(addon => addon.id === addonId)
      window.gtag('event', 'select_item', {
        item_list_id: 'addons',
        item_list_name: 'Addons',
        items: [
          {
            item_id: addonList[index].itemId,
            item_name: addonList[index].name,
            coupon: lastValidatedCoupon.code,
            item_list_id: addonList[index].analytics?.item_list_id,
            item_list_name: addonList[index].analytics?.item_list_name
          }
        ]
      })
      window.gtag('event', 'add_to_cart', {
        currency: 'GBP',
        value: (fullAddonDetail[index].price / 100).toFixed(2),
        items: [
          {
            item_id: addonList[index].itemId,
            item_name: addonList[index].name,
            coupon: lastValidatedCoupon.code,
            price: (fullAddonDetail[index].price / 100).toFixed(2),
            quantity: addonsIndex === -1 ? 1 : addons[addonsIndex].quantity,
            item_list_id: addonList[index].analytics?.item_list_id,
            item_list_name: addonList[index].analytics?.item_list_name
          }
        ]
      })
      window.gtag('event', 'added_treat', {
        page: 'sign_up_addon_pre_checkout',
        addon_id: addonList[index].itemId,
        addon_name: addonList[index].name,
        quantity: addonsIndex === -1 ? 1 : addons[addonsIndex].quantity
      })
    }
  }

  const handleRemoveAddon = (addonId: string) => {
    if (addons) {
      const index = addons.findIndex(addon => addon.id === addonId)
      const plansAddonsIndex = addonList.findIndex(addon => addon.itemId === addonId)

      if (index !== -1) {
        if (typeof window.gtag !== 'undefined' && addonList?.length > 0) {
          window.gtag('event', 'remove_from_cart', {
            currency: 'GBP',
            items: [
              {
                item_id: addonList[plansAddonsIndex].itemId,
                item_name: addonList[plansAddonsIndex].name,
                coupon: lastValidatedCoupon.code,
                quantity: addons[index].quantity - 1,
                item_list_id: addonList[plansAddonsIndex].analytics?.item_list_id,
                item_list_name: addonList[plansAddonsIndex].analytics?.item_list_name
              }
            ]
          })

          window.gtag('event', 'removed_treat', {
            page: 'sign_up_addon_pre_checkout',
            addon_id: addonList[plansAddonsIndex].itemId,
            addon_name: addonList[plansAddonsIndex].name,
            quantity: addons[index].quantity - 1
          })
        }
        if (addons[index].quantity > 1) {
          const addonsArr = [...addons]
          addonsArr[index].quantity = addonsArr[index].quantity - 1
          setAddons(addonsArr)
        } else {
          setAddons(addons.filter(addon => addon.id !== addonId))
        }
        setAddonCount(curr => curr - 1)

        if (typeof window.gtag !== 'undefined' && addonList?.length > 0) {
          window.gtag('event', 'removed_treat', {
            page: 'sign_up_addon_pre_checkout',
            addon_id: addonList[plansAddonsIndex].itemId,
            addon_name: addonList[plansAddonsIndex].name,
            quantity: addons[index].quantity - 1
          })
        }
      }
    }
  }

  const disableAddonAddButton = (addonId: string, outOfStock: boolean) => {
    const index = addons.findIndex(addon => addon.itemId === addonId)
    const previousAddonsIndex = addonList.findIndex((addon: any) => addon.itemId === addonId)

    if (index !== -1) {
      const addonsArr = [...addons]
      if (outOfStock) {
        if (addonsArr[index].quantity >= addonList[previousAddonsIndex].quantity) {
          return true
        }
      }
    } else {
      if (outOfStock) {
        if (addonList[previousAddonsIndex].quantity > 0) {
          return false
        }
        return true
      }
    }
    return false
  }

  const getAddonQuantity = (addonId: string) => {
    if (addons) {
      const index = addons.findIndex(addon => addon.id === addonId)
      if (index !== -1) {
        return addons[index].quantity
      }
    }
    return 0
  }

  const getNormalDiscountPrice = (price: number, treatId: string) => {
    const discountAppliedToAll = coupon?.applicableIds?.length === 0 ? true : false
    if (price > 0 && coupon?.code) {
      if (coupon.type === 'percentage') {
        if (discountAppliedToAll) {
          return price - (coupon.amount / 100) * price
        } else {
          if (dogInfo?.defaultCoupon?.applicableIds.includes(treatId)) {
            return price - (coupon.amount / 100) * price
          } else {
            return price
          }
        }
      }
      return price
    }
    return price
  }

  const getDiscountedAddonPrice = (price: number, treatId: string) => {
    const freeTreatDiscountApplicable = treatsOnlyArray.includes(treatId) ? true : false
    // handle free treat pricing
    if (coupon?.newCustomerTreatOffer && freeTreatDiscountApplicable) {
      if (freeTreatId && freeTreatId === treatId) {
        return 0
      } else if (price > 0 && freeTreatAvailable && freeTreatId !== treatId) {
        if (treatsOnlyArray.includes(treatId)) {
          return 0
        }
      } else {
        return getNormalDiscountPrice(price, treatId)
      }
    } else {
      // handle normal pricing
      return getNormalDiscountPrice(price, treatId)
    }
    return getNormalDiscountPrice(price, treatId)
  }

  const getTreatHighlight = (highlight: any) => {
    // Handle Plant based treats
    if (highlight[0].includes('Plant')) {
      return highlight[0]
    }
    // Create highlight from two highlights
    if (highlight.length > 1) {
      return highlight[0].replace('100% British', 'British') + ' & ' + highlight[1].replace('100% British', '')
    }

    return highlight[0].replace('British', '').replace('100%', 'British')
  }

  const renderInfoBox = () => {
    return (
      <div className=" p-4 pb-0 xl:p-6">
        <div className="xl:col-span-2">
          <p className="mb-4 text-left font-bold font-sans text-xl">What makes our treats different?</p>
        </div>
        <div className="xl:col-span-1 row-start-2 row-span-2 w-full xl:w-10/12">
          <p className="text-sm font-sans font-normal text-black text-left w-full xl:mt-2 xl:mb-16">
            Upgrade your treat jar and ensure you never run out of your dog's favourite treats again by adding them to
            your subscription.
            <br />
            {''}
            <br />
            Unlike many treats you find on supermarket shelves, our healthy treats are packed with flavour, high in
            protein and free from grains, so we know your dog will love them!
            <br />
            {''}
            <br />
            From bite-sized to long-lasting chews, Different Dog is your one-stop shop for delicious treats.
          </p>
        </div>
        <div className="pt-4 flex justify-center items-center w-11/12 md:w-2/3 mx-auto mb-4 md:mb-0">
          <StaticImage src="../../../images/infoBoxImage.png" alt="Dog with treats" />
        </div>
        <div className="my-2 xl:my-0 flex justify-center">
          <TrustPilotMicroCombo backgroundColor="transparent" />
        </div>
      </div>
    )
  }
  return (
    <>
      <div className="relative">
        <div className="header-wave-new h-[310px]" />
        <Formik
          initialValues={{ treats: [] }}
          validationSchema={TreatsSchema}
          onSubmit={async () => {
            if (typeof window.gtag !== 'undefined') {
              window.gtag('event', 'clicked_next', {
                page: 'sign_up_addon_pre_checkout'
              })
            }
            // Extra check to only pass addons with ids
            const filteredAddons = addons.filter((addon: any) => addon.id)
            await dispatch(addAddons(filteredAddons))
            await dispatch(updateKey('checkout'))
          }}
          validateOnMount
        >
          {props => {
            const getPackSize = (planDescription: string) => {
              if (!planDescription) return
              let index = planDescription.lastIndexOf('(')
              let splitArr = planDescription.split('(', index)
              let description = splitArr?.[0]?.trim()
              let packSize = splitArr.length && splitArr[splitArr.length - 1]
              splitArr = (packSize && packSize?.split(')')) || []
              packSize = splitArr.length && splitArr[0].trim()
              return [description, packSize]
            }
            const outOfStock = addonList[0]?.status === 'OUTOFSTOCK'

            return (
              <div className="relative">
                <div className="container mx-auto w-full md:max-w-[80%] -mt-4">
                  <div className="mb-4">
                    <h1
                      data-testid="howAboutSomeTreats"
                      className="p-4 text-center text-3xl tracking-tight sm:text-4xl font-serif font-medium pt-12"
                    >
                      How about some treats?
                    </h1>
                    <p className="text-center sm:text-lg">
                      Level up your training with natural, high-value treats and boost their health with
                      nutritionist-approved probiotics.
                    </p>
                  </div>
                  {coupon?.newCustomerTreatOffer && (
                    <div className="mx-auto flex w-[96%] flex-wrap bg-fourth lg:min-w-[900px] lg:max-w-[960px] rounded-3xl lg:w-11/12 mb-8">
                      <div className="px-4 pt-4 md:p-4 flex flex-row items-center justify-center">
                        <StaticImage
                          objectFit={'contain'}
                          className="mr-2 flex-shrink-0"
                          alt="1"
                          width={40}
                          src="../../../images/dog-details/gift1.svg"
                        />

                        <div className="flex flex-col">
                          <p className="text-left text-lg font-sans font-bold">
                            We're giving you a FREE Treat on your first order!
                          </p>
                          <p className="text-left text-xs font-sans hidden md:block">
                            Add any treat marked "FREE" from the list below. Any additional treats will be charged and
                            the cheapest will automatically qualify as the free treat.
                          </p>
                        </div>
                      </div>
                      <p className="text-left text-xs font-sans block md:hidden px-4 pt-1 pb-4">
                        Add any treat marked "FREE" from the list below. Any additional treats will be charged and the
                        cheapest will automatically qualify as the free treat.
                      </p>
                    </div>
                  )}
                  <div className="md:p-4">
                    <div
                      className={`mx-[5%] max-w-[90%] justify-center rounded-3xl p-2 text-center ${
                        getAddonQuantity(fullAddonDetail[0]?.itemId) > 0 ? `-mb-[4px]` : '-mb-2'
                      } `}
                    >
                      <h1
                        data-testid="ourRecommendation"
                        className="inline-block rounded-t-3xl bg-[#C1BCDC] p-2 px-6 text-sm tracking-tight md:text-2xl"
                      >
                        We think {getDogNames(dogInfo.dogs)} will love
                      </h1>
                    </div>
                    <div
                      className={`ml-[2%] -mr-[2%] flex h-[25rem] w-[96%] flex-wrap overflow-hidden bg-background md:h-96 lg:h-52 lg:min-w-[900px] lg:flex-nowrap xl:h-52 ${
                        getAddonQuantity(fullAddonDetail[0]?.itemId) > 0 ? `shadow-[0_0_0_4px_rgba(0,102,133,1)]` : ''
                      } flex flex-wrap rounded-3xl lg:mx-auto lg:w-2/3`}
                    >
                      <div className="relative flex h-1/2 w-1/2 pl-4 pt-4 md:h-[64%] md:pl-8 lg:h-full lg:w-full lg:max-w-[256px] lg:pl-0 lg:pt-0">
                        <CdnImage
                          url={fullAddonDetail[0]?.images?.secondary}
                          alt={fullAddonDetail[0]?.name}
                          steps={[400]}
                          className="lg:w[100%] rounded-3xl object-top md:h-[86%] md:w-[86%] block lg:h-[100%] lg:rounded-none lg:object-cover"
                        />
                        {fullAddonDetail[0]?.limitedEdition && !fullAddonDetail[0]?.isNew && (
                          <img
                            style={{ lineHeight: '1rem', right: '0.5rem' }}
                            className="index-1 absolute -top-4 left-0 inline-flex h-24 w-24 -rotate-12 md:top-0 md:h-20 md:w-20 lg:h-20 lg:w-20 xl:inline-flex"
                            src={limitedEditionSticker}
                            alt="Limited Edition"
                          />
                        )}
                        {fullAddonDetail[0]?.isNew && (
                          <span
                            style={{ lineHeight: '1rem', right: '0.5rem' }}
                            className="index-1 absolute -top-4 left-4 md:-top-0 lg:left-0 inline-flex w-24 h-24 lg:h-20 lg:w-20 -rotate-12 md:h-20 md:w-20 xl:inline-flex"
                          >
                            <img
                              className="w-1/2 max-w-[75px] -rotate-12 object-contain md:w-full"
                              src={NewSticker}
                              alt="New"
                            />
                          </span>
                        )}
                      </div>
                      <div className="flex h-1/3 w-1/2 justify-between p-4 pb-0 md:p-4 md:pb-0 lg:w-full lg:px-6 lg:pb-4 xl:p-6">
                        <div className="ml-0 w-full md:-ml-2 lg:-ml-6">
                          <div className="flex items-center">
                            <h4 className="text-xl text-primary font-sans font-semibold">{fullAddonDetail[0]?.name}</h4>
                          </div>

                          <p className="flex w-full items-center text-left text-xs text-black md:text-sm">
                            {getPackSize(fullAddonDetail[0]?.shortDescription)?.[0] ?? ''}
                          </p>

                          <div className="flex flex-col md:flex-row justify-start items-center w-full md:w-11/12">
                            {fullAddonDetail[0]?.bullets?.length > 0 && (
                              <div className="my-2 md:my-4 flex flex-row mx-auto justify-start items-center w-full md:w-1/2">
                                <img width={20} src={BulletPointImage} alt={'British Flag'} />
                                <span className="ml-2 inline-flex justify-center text-left lg:text-center text-xs md:text-sm">
                                  {fullAddonDetail[0].bullets[0]}
                                </span>
                              </div>
                            )}
                            {fullAddonDetail[0]?.highlights?.length > 0 && (
                              <div className="my-2 md:my-4 flex flex-row mx-auto justify-start items-center w-full md:w-1/2">
                                <img width={20} src={BritishFlag} alt={'British Flag'} />
                                <span className="ml-2 inline-flex justify-center text-left lg:text-center text-xs md:text-sm">
                                  {getTreatHighlight(fullAddonDetail[0].highlights)}
                                </span>
                              </div>
                            )}
                          </div>
                          <div
                            className={`hidden md:flex justify-start ${fullAddonDetail[0]?.highlights?.length > 0 || fullAddonDetail[0]?.bullets?.length > 0 ? '' : 'mt-6'} `}
                          >
                            <button
                              className="btn-secondary mb-4 text-sm"
                              onClick={() => {
                                setModalShow(true)
                                setModalActiveRecipe(fullAddonDetail[0])
                                if (typeof window.gtag !== 'undefined') {
                                  window.gtag('event', 'view_item', {
                                    currency: 'GBP',
                                    items: [
                                      {
                                        item_id: fullAddonDetail[0].itemId,
                                        item_name: fullAddonDetail[0].name,
                                        coupon: lastValidatedCoupon.code,
                                        item_list_id: fullAddonDetail[0].analytics?.item_list_id,
                                        item_list_name: fullAddonDetail[0].analytics?.item_list_name
                                      }
                                    ]
                                  })
                                }
                              }}
                            >
                              Additional information
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* Desktop view */}
                      <div className="mt-12 ml-0 hidden h-1/2 w-1/2 flex-col items-center gap-y-4 text-left md:-mt-4 md:-ml-2 md:flex lg:mr-8 lg:mt-12 lg:ml-0 lg:w-1/3">
                        {(fullAddonDetail[0]?.price / 100)?.toFixed(2) !== 'NaN' && (
                          <p className={`text-xl font-bold lg:text-2xl`}>
                            {getDiscountedAddonPrice(fullAddonDetail[0]?.price, fullAddonDetail[0]?.itemId) !==
                              fullAddonDetail[0]?.price && (
                              <span className={`strikethrough mr-2 text-[#5C5C5C]`}>
                                £{(fullAddonDetail[0]?.price / 100)?.toFixed(2)}
                              </span>
                            )}
                            <span className={'text-primary'}>
                              {getDiscountedAddonPrice(fullAddonDetail[0]?.price, fullAddonDetail[0]?.itemId) > 0
                                ? `£${getDiscountedAddonPrice(
                                    fullAddonDetail[0]?.price / 100,
                                    fullAddonDetail[0]?.itemId
                                  )?.toFixed(2)}`
                                : `FREE`}
                            </span>
                          </p>
                        )}

                        <div className="flex w-[200px] flex-col items-center gap-y-4">
                          {getAddonQuantity(fullAddonDetail[0]?.itemId) === 0 && (
                            <div className="min-h-[50px]">
                              <button
                                data-testid="treatsAddBtn"
                                className="btn-cta !px-8 disabled:cursor-not-allowed disabled:grayscale disabled:filter"
                                disabled={disableAddonAddButton(fullAddonDetail[0]?.itemId, outOfStock)}
                                onClick={() =>
                                  handleAddAddon(fullAddonDetail[0]?.itemId, fullAddonDetail[0]?.price, outOfStock)
                                }
                              >
                                {outOfStock ? 'Out of stock' : 'Add'}
                              </button>
                            </div>
                          )}

                          {getAddonQuantity(fullAddonDetail[0]?.itemId) > 0 && (
                            <div>
                              <div className="flex items-center justify-center">
                                {getAddonQuantity(fullAddonDetail[0]?.itemId) > 0 && (
                                  <button
                                    className="btn-cta mr-4 h-10 w-10"
                                    type="button"
                                    onClick={() => handleRemoveAddon(fullAddonDetail[0]?.itemId)}
                                  >
                                    -
                                  </button>
                                )}
                                {getAddonQuantity(fullAddonDetail[0]?.itemId) > 0 && (
                                  <Field
                                    name="treats"
                                    type="text"
                                    placeholder="0"
                                    className="w-1/3 rounded-3xl border-[#D1D5DA] bg-checkoutBG py-3 px-4 text-center"
                                    value={getAddonQuantity(fullAddonDetail[0]?.itemId)}
                                    inputMode="none"
                                    onFocus={(e: any) => e.target.blur()}
                                  />
                                )}
                                {getAddonQuantity(fullAddonDetail[0]?.itemId) > 0 && (
                                  <button
                                    className="btn-cta ml-4 h-10 w-10 disabled:cursor-not-allowed disabled:grayscale disabled:filter"
                                    type="button"
                                    disabled={disableAddonAddButton(fullAddonDetail[0]?.itemId, outOfStock)}
                                    onClick={() =>
                                      handleAddAddon(fullAddonDetail[0]?.itemId, fullAddonDetail[0]?.price, outOfStock)
                                    }
                                  >
                                    +
                                  </button>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {/* Mobile view */}
                      <div className="w-full flex flex-col items-center justify-center px-4 md:ml-0 md:hidden md:pt-4">
                        <p className={`mb-2 ml-2 text-xl font-bold lg:text-2xl`}>
                          {getDiscountedAddonPrice(fullAddonDetail[0]?.price, fullAddonDetail[0]?.itemId) !==
                            fullAddonDetail[0]?.price && (
                            <span className={`strikethrough ml-2 text-[#5C5C5C]`}>
                              £{(fullAddonDetail[0]?.price / 100)?.toFixed(2)}
                            </span>
                          )}
                          <span className={'ml-2 text-primary'}>
                            {getDiscountedAddonPrice(fullAddonDetail[0]?.price, fullAddonDetail[0]?.itemId) > 0
                              ? `£${getDiscountedAddonPrice(
                                  fullAddonDetail[0]?.price / 100,
                                  fullAddonDetail[0]?.itemId
                                )?.toFixed(2)}`
                              : `FREE`}
                          </span>
                        </p>

                        <div className="flex w-[250px] flex-col items-center gap-y-4">
                          {getAddonQuantity(fullAddonDetail[0]?.itemId) === 0 && (
                            <div className="min-h-[50px]">
                              <button
                                className="btn-cta !px-8 disabled:cursor-not-allowed disabled:grayscale disabled:filter"
                                disabled={disableAddonAddButton(fullAddonDetail[0]?.itemId, outOfStock)}
                                onClick={() =>
                                  handleAddAddon(fullAddonDetail[0]?.itemId, fullAddonDetail[0]?.price, outOfStock)
                                }
                              >
                                {outOfStock ? 'Out of stock' : 'Add'}
                              </button>
                            </div>
                          )}

                          {getAddonQuantity(fullAddonDetail[0]?.itemId) > 0 && (
                            <div>
                              <div className="flex items-center justify-center">
                                {getAddonQuantity(fullAddonDetail[0]?.itemId) > 0 && (
                                  <button
                                    className="btn-cta mr-2 h-10 w-10"
                                    type="button"
                                    onClick={() => handleRemoveAddon(fullAddonDetail[0]?.itemId)}
                                  >
                                    -
                                  </button>
                                )}
                                {getAddonQuantity(fullAddonDetail[0]?.itemId) > 0 && (
                                  <Field
                                    name="treats"
                                    type="text"
                                    placeholder="0"
                                    className="w-1/3 rounded-3xl border-[#D1D5DA] bg-checkoutBG py-3 px-4 text-center"
                                    value={getAddonQuantity(fullAddonDetail[0]?.itemId)}
                                    inputMode="none"
                                    onFocus={(e: any) => e.target.blur()}
                                  />
                                )}
                                {getAddonQuantity(fullAddonDetail[0]?.itemId) > 0 && (
                                  <button
                                    className="btn-cta ml-4 h-10 w-10 disabled:cursor-not-allowed disabled:grayscale disabled:filter"
                                    type="button"
                                    disabled={disableAddonAddButton(fullAddonDetail[0]?.itemId, outOfStock)}
                                    onClick={() =>
                                      handleAddAddon(fullAddonDetail[0]?.itemId, fullAddonDetail[0]?.price, outOfStock)
                                    }
                                  >
                                    +
                                  </button>
                                )}
                              </div>
                            </div>
                          )}
                          <button
                            className="btn-secondary mb-4 text-sm"
                            onClick={() => {
                              setModalShow(true)
                              setModalActiveRecipe(fullAddonDetail[0])
                              if (typeof window.gtag !== 'undefined') {
                                window.gtag('event', 'view_item', {
                                  currency: 'GBP',
                                  items: [
                                    {
                                      item_id: fullAddonDetail[0].itemId,
                                      item_name: fullAddonDetail[0].name,
                                      coupon: lastValidatedCoupon.code,
                                      item_list_id: fullAddonDetail[0].analytics?.item_list_id,
                                      item_list_name: fullAddonDetail[0].analytics?.item_list_name
                                    }
                                  ]
                                })
                              }
                            }}
                          >
                            Additional information
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="mx-auto py-10">
                      <ul className="grid grid-cols-2 gap-y-6 gap-x-2 sm:gap-x-8 lg:grid-cols-3 xl:grid-cols-4 2xl:mx-auto 2xl:max-w-[75%]">
                        {treatDetail &&
                          treatDetail.map(
                            (
                              addon: {
                                id: string
                                name: string
                                imageUrlNormal: string
                                price: number
                                itemId: string
                                shortDescription: string
                                status: string
                              },
                              i: any
                            ) => {
                              const outOfStock = addon.status === 'OUTOFSTOCK'
                              if (i === 0) return <React.Fragment key={`empty-${i}`}></React.Fragment>
                              return (
                                <li
                                  className={`relative mx-auto ml-[2%] -mr-[2%] w-[96%] flex-row flex-nowrap overflow-hidden rounded-3xl bg-background md:mr-0 md:ml-0 md:flex-col ${
                                    getAddonQuantity(addon?.itemId) > 0
                                      ? 'shadow-[0_0_0_4px_rgba(0,102,133,1)]'
                                      : 'shadow-[0_0_0_4px_rgba(209,213,218,0)]'
                                  }`}
                                  key={addon.itemId}
                                >
                                  {addon?.isNew && (
                                    <span
                                      style={{ lineHeight: '1rem', right: '0.5rem' }}
                                      className="index-1 absolute -top-8 left-0 inline-flex h-24 w-24 -rotate-12 md:top-0 md:h-20 md:w-20 lg:h-20 lg:w-20 xl:inline-flex"
                                    >
                                      <img
                                        className="w-1/2 max-w-[75px] -rotate-12 object-contain md:w-full"
                                        src={NewSticker}
                                        alt="New"
                                      />
                                    </span>
                                  )}
                                  <div className="flex h-full flex-1 flex-col justify-between text-center mt-0">
                                    <div className="flex h-full flex-col">
                                      <div className="block grid-cols-3 md:grid md:grid-cols-1">
                                        <div className="aspect-h-1 relative mx-auto w-full overflow-hidden md:rounded-t-3xl mt-0 2xl:max-h-[485px]">
                                          <CdnImage
                                            url={addon?.images?.secondary}
                                            alt={addon.name}
                                            steps={[450]}
                                            className={`w-full scale-100 transform xs:min-h-[150px] object-cover md:max-h-full lg:block bg-[#F6E9DB]`}
                                          />
                                          {addon.limitedEdition && !addon?.isNew && (
                                            <img
                                              style={{ lineHeight: '1rem', right: '0.5rem' }}
                                              className="index-1 absolute -top-4 left-0 inline-flex h-24 w-24 -rotate-12 md:top-0 md:h-20 md:w-20 lg:h-20 lg:w-20 xl:inline-flex"
                                              src={limitedEditionSticker}
                                              alt="Limited Edition"
                                            />
                                          )}
                                        </div>
                                        <div className="col-span-2 flex h-auto flex-grow flex-col p-4">
                                          <h4 className="text-center text-sm text-primary sm:text-xl md:mb-4 font-sans font-semibold">
                                            {addon?.name}
                                          </h4>
                                          <p className="mb-4 flex w-full items-center text-left text-xs text-black md:text-sm">
                                            {getPackSize(addon?.shortDescription)?.[0] ?? ''}
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="flex flex-col md:flex-row mx-auto justify-center items-center md:items-start w-full ml-4 md:ml-0 md:mb-4">
                                      {addon?.bullets?.length > 0 && (
                                        <div className="flex flex-row md:flex-col mx-auto justify-start items-center w-full md:w-1/2">
                                          <img width={20} src={BulletPointImage} alt={'Logo'} />
                                          <span className="ml-2 mt-[0.3rem] inline-flex justify-center text-left lg:text-center text-xs md:text-sm">
                                            {addon.bullets[0]}
                                          </span>
                                        </div>
                                      )}
                                      {addon?.highlights?.length > 0 && (
                                        <div className="my-4 md:my-0 flex flex-row md:flex-col mx-auto justify-start items-center w-full md:w-1/2">
                                          {addon.highlights[0].includes('Plant') && (
                                            <img width={20} src={BulletPointImage} alt={'Logo'} />
                                          )}
                                          {!addon.highlights[0].includes('Plant') && (
                                            <img width={20} src={BritishFlag} alt={'British Flag'} />
                                          )}
                                          <span
                                            className={`${
                                              addon.highlights[0].includes('Plant') ? 'mt-[0.3rem]' : 'mt-0'
                                            } ml-2 inline-flex justify-center text-left lg:text-center text-xs md:text-sm`}
                                          >
                                            {getTreatHighlight(addon.highlights)}
                                          </span>
                                        </div>
                                      )}
                                    </div>

                                    <div className="mx-auto flex flex-col items-center justify-center text-center">
                                      <p className={`text-xl font-bold lg:text-2xl`}>
                                        {getDiscountedAddonPrice(addon?.price, addon.itemId) !== addon.price && (
                                          <span className="strikethrough mr-2 text-[#5C5C5C]">
                                            £{(addon?.price / 100)?.toFixed(2)}
                                          </span>
                                        )}
                                        <span
                                          className={`${
                                            getDiscountedAddonPrice(addon.price, addon.itemId) > 0 ? 'ml-2' : ''
                                          } text-primary`}
                                        >
                                          {getDiscountedAddonPrice(addon.price, addon.itemId) > 0
                                            ? `£${getDiscountedAddonPrice(addon.price / 100, addon.itemId)?.toFixed(2)}`
                                            : `FREE`}
                                        </span>
                                      </p>
                                    </div>

                                    <div className="flex items-center justify-center">
                                      {getAddonQuantity(addon.itemId) > 0 && (
                                        <button
                                          className="btn-cta mr-2 h-10 w-10 md:mr-4"
                                          type="button"
                                          onClick={() => handleRemoveAddon(addon.itemId)}
                                        >
                                          -
                                        </button>
                                      )}
                                      {getAddonQuantity(addon.itemId) > 0 && (
                                        <Field
                                          name="treats"
                                          type="text"
                                          placeholder="0"
                                          className="w-1/3 rounded-3xl border-[#D1D5DA] bg-checkoutBG py-3 px-2 text-center md:px-4"
                                          value={getAddonQuantity(addon.itemId)}
                                          inputMode="none"
                                          onFocus={(e: any) => e.target.blur()}
                                        />
                                      )}
                                      {getAddonQuantity(addon.itemId) > 0 && (
                                        <button
                                          className="btn-cta ml-2 h-10 w-10 disabled:cursor-not-allowed disabled:grayscale disabled:filter md:ml-4"
                                          type="button"
                                          disabled={disableAddonAddButton(addon.itemId, outOfStock)}
                                          onClick={() => handleAddAddon(addon.itemId, addon.price, outOfStock)}
                                        >
                                          +
                                        </button>
                                      )}
                                      {getAddonQuantity(addon.itemId) === 0 && (
                                        <div className="flex min-h-[50px] flex-col justify-center">
                                          <button
                                            data-testid="treatsAddBtn"
                                            type="button"
                                            className={`btn-cta !px-8 !text-base !text-white disabled:cursor-not-allowed disabled:grayscale disabled:filter`}
                                            disabled={disableAddonAddButton(addon.itemId, outOfStock)}
                                            onClick={() => handleAddAddon(addon.itemId, addon.price, outOfStock)}
                                          >
                                            {outOfStock ? 'Out of stock' : 'Add'}
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                    <div className="my-2 text-center pb-2 md:pb-4">
                                      <button
                                        className="btn-secondary mx-auto mb-2 text-xs sm:text-sm md:mb-0"
                                        onClick={() => {
                                          setModalShow(true)
                                          setModalActiveRecipe(addon)
                                          if (typeof window.gtag !== 'undefined') {
                                            window.gtag('event', 'view_item', {
                                              currency: 'GBP',
                                              items: [
                                                {
                                                  item_id: addon.itemId,
                                                  item_name: addon.name,
                                                  coupon: lastValidatedCoupon.code,
                                                  item_list_id: addon.analytics?.item_list_id,
                                                  item_list_name: addon.analytics?.item_list_name
                                                }
                                              ]
                                            })
                                          }
                                        }}
                                      >
                                        Additional information
                                      </button>
                                    </div>
                                  </div>
                                </li>
                              )
                            }
                          )}

                        {/* 1 and 3 column span info box */}
                        <li
                          key={'infoBoxSpan1-2-3'}
                          className="relative flex mx-auto ml-[2%] -mr-[2%] w-[96%] flex-row flex-nowrap overflow-hidden rounded-3xl bg-[#D7EAD2] md:mr-0 md:ml-0 md:flex-col col-span-1 xl:w-[99%] lg:col-span-3 xl:col-span-1"
                        >
                          {renderInfoBox()}
                        </li>
                      </ul>
                    </div>
                    <p className="text-base text-center font-medium font-serif text-3xl">
                      More treats, supplements and extras
                    </p>
                    <div className="mb-8 mx-auto py-10">
                      <ul className="grid grid-cols-2 gap-y-6 gap-x-2 sm:gap-x-8 lg:grid-cols-3 xl:grid-cols-4 2xl:mx-auto 2xl:max-w-[75%]">
                        {supplementDetail &&
                          supplementDetail.map(
                            (
                              addon: {
                                id: string
                                name: string
                                imageUrlNormal: string
                                price: number
                                itemId: string
                                shortDescription: string
                                status: string
                              },
                              _i: any
                            ) => {
                              const outOfStock = addon.status === 'OUTOFSTOCK'

                              return (
                                <li
                                  className={`relative mx-auto ml-[2%] -mr-[2%] w-[96%] flex-row flex-nowrap overflow-hidden rounded-3xl bg-background md:mr-0 md:ml-0 md:flex-col ${
                                    getAddonQuantity(addon?.itemId) > 0
                                      ? 'shadow-[0_0_0_4px_rgba(0,102,133,1)]'
                                      : 'shadow-[0_0_0_4px_rgba(209,213,218,0)]'
                                  }`}
                                  key={addon.itemId}
                                >
                                  {addon?.isNew && (
                                    <span
                                      style={{ lineHeight: '1rem', right: '0.5rem' }}
                                      className="index-1 absolute -top-8 left-0 inline-flex h-24 w-24 -rotate-12 md:top-0 md:h-20 md:w-20 lg:h-20 lg:w-20 xl:inline-flex"
                                    >
                                      <img
                                        className="w-1/2 max-w-[75px] -rotate-12 object-contain md:w-full"
                                        src={NewSticker}
                                        alt="New"
                                      />
                                    </span>
                                  )}
                                  <div className="flex h-full flex-1 flex-col justify-between text-center mt-0">
                                    <div className="flex h-full flex-col">
                                      <div className="block grid-cols-3 md:grid md:grid-cols-1">
                                        <div className="aspect-h-1 relative mx-auto w-full overflow-hidden md:rounded-t-3xl mt-0 2xl:max-h-[485px]">
                                          <CdnImage
                                            url={addon?.images?.secondary}
                                            alt={addon.name}
                                            steps={[450]}
                                            className={`w-full scale-100 transform xs:min-h-[150px] object-cover md:max-h-full lg:block bg-[#F6E9DB]`}
                                          />
                                          {addon.limitedEdition && !addon?.isNew && (
                                            <img
                                              style={{ lineHeight: '1rem', right: '0.5rem' }}
                                              className="index-1 absolute -top-4 left-0 inline-flex h-24 w-24 -rotate-12 md:top-0 md:h-20 md:w-20 lg:h-20 lg:w-20 xl:inline-flex"
                                              src={limitedEditionSticker}
                                              alt="Limited Edition"
                                            />
                                          )}
                                        </div>
                                        {outOfStock && (
                                          <span
                                            style={{ lineHeight: '1rem', right: '0.5rem' }}
                                            className="absolute top-[15%] inline-flex h-11 w-11 -rotate-12 items-center justify-center rounded-full bg-[#C6DABB] text-center text-[0.65rem] drop-shadow md:h-20 md:w-20 md:text-[1rem] xl:inline-flex"
                                          >
                                            Out of stock
                                          </span>
                                        )}
                                        <div className="col-span-2 flex h-auto flex-grow flex-col p-4">
                                          <h4 className="text-center text-sm text-primary sm:text-xl md:mb-4 font-sans font-semibold">
                                            {addon?.name}
                                          </h4>
                                          <p className="mb-4 flex w-full items-center text-left text-xs text-black md:text-sm">
                                            {getPackSize(addon?.shortDescription)?.[0] ?? ''}
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="flex flex-col md:flex-row mx-auto justify-center items-center md:items-start w-full ml-4 md:ml-0 md:mb-4">
                                      {addon?.bullets?.length > 0 && (
                                        <div className="flex flex-row md:flex-col mx-auto justify-start items-center w-full md:w-1/2">
                                          <img width={20} src={BulletPointImage} alt={'Logo'} />
                                          <span className="ml-2 mt-[0.3rem] inline-flex justify-center text-left lg:text-center text-xs md:text-sm">
                                            {addon.bullets[0]}
                                          </span>
                                        </div>
                                      )}
                                      {addon?.highlights?.length > 0 && (
                                        <div className="my-4 md:my-0 flex flex-row md:flex-col mx-auto justify-start items-center w-full md:w-1/2">
                                          {addon.highlights[0].includes('Supplement') && (
                                            <img width={20} src={BulletPointImage} alt={'Logo'} />
                                          )}
                                          {!addon.highlights[0].includes('Supplement') && (
                                            <img width={20} src={BritishFlag} alt={'British Flag'} />
                                          )}
                                          <span
                                            className={`${
                                              addon.highlights[0].includes('Supplement') ? 'mt-[0.3rem]' : 'mt-0'
                                            } ml-2 inline-flex justify-center text-left lg:text-center text-xs md:text-sm`}
                                          >
                                            {getTreatHighlight(addon.highlights)}
                                          </span>
                                        </div>
                                      )}
                                    </div>

                                    <div className="mx-auto flex flex-col items-center justify-center text-center">
                                      <p className={`text-xl font-bold lg:text-2xl`}>
                                        {getDiscountedAddonPrice(addon?.price, addon.itemId) !== addon.price && (
                                          <span className="strikethrough mr-2 text-[#5C5C5C]">
                                            £{(addon?.price / 100)?.toFixed(2)}
                                          </span>
                                        )}
                                        <span
                                          className={`${
                                            getDiscountedAddonPrice(addon.price, addon.itemId) > 0 ? 'ml-2' : ''
                                          } text-primary`}
                                        >
                                          {getDiscountedAddonPrice(addon.price, addon.itemId) > 0
                                            ? `£${getDiscountedAddonPrice(addon.price / 100, addon.itemId)?.toFixed(2)}`
                                            : `FREE`}
                                        </span>
                                      </p>
                                    </div>

                                    <div className="flex items-center justify-center">
                                      {getAddonQuantity(addon.itemId) > 0 && (
                                        <button
                                          className="btn-cta mr-2 h-10 w-10 md:mr-4"
                                          type="button"
                                          onClick={() => handleRemoveAddon(addon.itemId)}
                                        >
                                          -
                                        </button>
                                      )}
                                      {getAddonQuantity(addon.itemId) > 0 && (
                                        <Field
                                          name="treats"
                                          type="text"
                                          placeholder="0"
                                          className="w-1/3 rounded-3xl border-[#D1D5DA] bg-checkoutBG py-3 px-2 text-center md:px-4"
                                          value={getAddonQuantity(addon.itemId)}
                                          inputMode="none"
                                          onFocus={(e: any) => e.target.blur()}
                                        />
                                      )}
                                      {getAddonQuantity(addon.itemId) > 0 && (
                                        <button
                                          className="btn-cta ml-2 h-10 w-10 disabled:cursor-not-allowed disabled:grayscale disabled:filter md:ml-4"
                                          type="button"
                                          disabled={disableAddonAddButton(addon.itemId, outOfStock)}
                                          onClick={() => handleAddAddon(addon.itemId, addon.price, outOfStock)}
                                        >
                                          +
                                        </button>
                                      )}
                                      {getAddonQuantity(addon.itemId) === 0 && (
                                        <div className="flex min-h-[50px] flex-col justify-center">
                                          <button
                                            data-testid="treatsAddBtn"
                                            type="button"
                                            className={`btn-cta !px-8 !text-base !text-white disabled:cursor-not-allowed disabled:grayscale disabled:filter`}
                                            disabled={disableAddonAddButton(addon.itemId, outOfStock)}
                                            onClick={() => handleAddAddon(addon.itemId, addon.price, outOfStock)}
                                          >
                                            {outOfStock ? 'Out of stock' : 'Add'}
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                    <div className="my-2 text-center pb-2 md:pb-4">
                                      <button
                                        className="btn-secondary mx-auto mb-2 text-xs sm:text-sm md:mb-0"
                                        onClick={() => {
                                          setModalShow(true)
                                          setModalActiveRecipe(addon)
                                          if (typeof window.gtag !== 'undefined') {
                                            window.gtag('event', 'view_item', {
                                              currency: 'GBP',
                                              items: [
                                                {
                                                  item_id: addon.itemId,
                                                  item_name: addon.name,
                                                  coupon: lastValidatedCoupon.code,
                                                  item_list_id: addon.analytics?.item_list_id,
                                                  item_list_name: addon.analytics?.item_list_name
                                                }
                                              ]
                                            })
                                          }
                                        }}
                                      >
                                        Additional information
                                      </button>
                                    </div>
                                  </div>
                                </li>
                              )
                            }
                          )}
                        {/* 1 and 3 column span info box */}
                        <li
                          key={'infoBoxSpan1-3'}
                          className="relative flex mx-auto ml-[2%] -mr-[2%] w-[96%] flex-row flex-nowrap overflow-hidden rounded-3xl bg-[#D7EAD2] md:mr-0 md:ml-0 md:flex-col col-span-1 xl:w-[99%] xl:col-span-3"
                        >
                          {renderInfoBox()}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="footer-wave-bottom-signup relative -mb-32 p-4 md:p-8">
                  <div className="mb-4 w-full text-center font-serif">
                    <div className="mx-auto mt-8 text-base !leading-[1.3] md:text-xl">
                      <p>Still got questions?</p>
                      <p>Get in touch with our team - they could talk dogs all day long</p>
                    </div>
                    <p className="mt-8 text-base md:text-xl">
                      Call us on{' '}
                      <a className="font-bold" href="tel:+441743384562">
                        01743 384 562
                      </a>{' '}
                      or email{' '}
                      <a className="font-bold" href="mailto:feedme@differentdog.com">
                        feedme@differentdog.com
                      </a>{' '}
                    </p>
                  </div>
                  <div className="mt-10 hidden sm:block">
                    <TrustPilotMicroCombo backgroundColor="transparent" />
                  </div>
                  <div className="mt-5 -mb-8 block sm:hidden">
                    <TrustPilotMini backgroundColor="transparent" />
                  </div>
                  <div className="mx-auto mb-16 w-full md:w-4/5">
                    <TrustBoxAlternate />
                  </div>
                </div>
                <StickyFooter className="fixed bottom-0 left-0 right-0 z-[3] lg:max-h-20 bg-[#F8F2EC] bg-opacity-60 md:bg-opacity-75 backdrop-blur-sm w-full lg:w-11/12 mx-auto lg:rounded-t-3xl shadow-[0_-5px_15px_rgba(0,0,0,0.25)]">
                  <div className="w-full lg:w-full flex flex-col lg:flex-row relative items-center justify-between mx-auto pb-2">
                    <button
                      data-testid="treatsBack"
                      type="button"
                      onClick={backClick}
                      className="z-2 rounded-full border border-primary bg-white px-12 py-3 hover:underline focus:underline hidden lg:flex justify-center items-center"
                    >
                      <FontAwesomeIcon icon={faChevronLeft} className="h-4 w-4 mr-2" aria-hidden="true" />
                      Back
                    </button>
                    <div className="z-1 mb-2 lg:mb-0 text-center lg:absolute lg:left-1/2 lg:transform lg:-translate-x-1/2">
                      <p>
                        Treats selected:{' '}
                        <span data-testid="treatsCount" className="font-bold">
                          {addonCount}
                        </span>
                      </p>
                    </div>
                    <div className="flex flex-row justify-center items-center w-11/12 lg:w-auto lg:justify-center">
                      <a
                        className={`mr-2 flex h-[3.5rem] w-[3.5rem] items-center text-primary lg:hidden`}
                        onClick={backClick}
                      >
                        <StaticImage src="../../../../images/back-arrow-mobile.png" alt="Back" />
                      </a>
                      <button
                        data-testid="treatsNext"
                        disabled={loading}
                        onClick={() => props.handleSubmit()}
                        type="button"
                        className={`btn-cta scale-animation !py-3 lg:!py-4 !text-xl !font-bold !flex justify-center items-center w-11/12 lg:w-auto lg:!px-24 z-2`}
                      >
                        {loading ? (
                          <div className="align-center flex justify-center">
                            <div className="border-white-900 mr-2 h-5 w-5 animate-spin rounded-full border-b-2"></div>
                            <div>Saving</div>
                          </div>
                        ) : (
                          'Next'
                        )}
                        {!loading && (
                          <FontAwesomeIcon icon={faChevronRight} className="h-4 w-4 ml-2" aria-hidden="true" />
                        )}
                      </button>
                    </div>
                  </div>
                </StickyFooter>
              </div>
            )
          }}
        </Formik>
      </div>
      <RecipeOrTreatAlterModal show={modalShow} setShow={setModalShow} activeRecipe={modalActiveRecipe} />
    </>
  )
}
export default Treats
