import { faCalendarDays, faEye, faEyeSlash, faQuestionCircle, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { AddressFinder } from '@ideal-postcodes/address-finder'
import { navigate } from '@reach/router'
import { useLocation } from '@reach/router'
import {
  CardNumberElement,
  Elements,
  PaymentRequestButtonElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Auth } from 'aws-amplify'
import { PassValidAlternate } from 'components/page/account/sign-in'
import TrustPilotMicroCombo from 'components/ui-builder/trustpilot/micro-combo'
import TrustPilotMini from 'components/ui-builder/trustpilot/mini'
import CountdownTimer from 'components/ui/countdown-timer/CountdownTimer'
import Coupon from 'components/ui/coupon/Coupon'
import FaqAccordion from 'components/ui/faq-accordion'
import FeedbackForm from 'components/ui/feedback-form/FeedbackForm'
import ErrorModal from 'components/ui/modal/ErrorModal'
import TrustBoxAlternate from 'components/ui/trustpilot/TrustBoxAlternate'
import dayjs from 'dayjs'
import { HeadingClass, HeadingType } from 'enums'
import { Field, Formik, useFormikContext } from 'formik'
import FormikErrorFocus from 'formik-error-focus'
import { StaticImage } from 'gatsby-plugin-image'
import { parse, stringify } from 'query-string'
import React, { createContext, useCallback, useState, useEffect, useRef } from 'react'
import { CalendarContainer } from 'react-datepicker'
// import { ElementState } from '../../util/stripe-custom/index'
import { DatePicker } from 'react-formik-ui'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { RootState } from 'state'
import { updateCurrCouponCode, updateCustomerEmail } from 'state/actions/dogAction'
import { setHashLoaded } from 'state/actions/hashAction'
import { updateKey } from 'state/actions/keyAction'
import { updateParentEmail } from 'state/actions/parentAction'
import { updateCouponCode } from 'state/actions/parentAction'
import { getProducts } from 'state/actions/productsAction'
import {
  createShippingConfirm,
  resetErrorMessageForShipping,
  resetLoadingStatus,
  saveShippingConfirmForm,
  validatePostcode
} from 'state/actions/shippingAction'
import { PlanType } from 'state/types/planType'
import { createTemporaryPassword } from 'utils/temporary-password'
import { ShippingSchema } from 'utils/yup-validation-schema'
import * as yup from 'yup'
import loadingGIF from '../../../images/loadingspinner.gif'
import Api from '../../../state/apis/api'
import CardCheckout from './CardCheckout'
import { Faqs } from './Faqs'

export const CardElementCtx = createContext<any>({})

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PK || '')

/**
 * Wrap component with Stripe Elements component
 */
const ShippingConfirm = () => {
  return (
    <Elements stripe={stripePromise}>
      <ShippingConfirmStripe />
    </Elements>
  )
}

const ShippingConfirmStripe = () => {
  const {
    lastValidatedCoupons
  }: {
    lastValidatedCoupons: CouponsType[]
  } = useSelector((state: RootState) => state.dog)
  const {
    coupon,
    isValidCoupon,
    currCouponCode,
    lastValidatedCoupon,
    loading: isCouponValidationLoading,
    pack: packs
  } = useSelector((state: RootState) => state.dog)
  const { parentDetails } = useSelector((state: RootState) => state.parent)
  const [isBillingAddressSame, setIsBillingAddressSame] = useState(true)
  const {
    recipesSelected,
    addonsSelected,
    plan,
    noOfPacks,
    weeks,
    packSize,
    fixedOrCustomPlan,
    packType
  }: PlanType | any = useSelector((state: RootState) => state.plan)
  const { dogInfo, summary, defaultPack, pack } = useSelector((state: RootState) => state.dog)
  const {
    customer: { marketingOptIn }
  } = dogInfo
  const products = useSelector((state: RootState) => state.products)
  // Get recipe list from product store, else use old code of plan.recipes
  // TODO: Can be updated once product store in place
  const { recipeList }: { recipeList?: RecipeAddonType[] } = products || { recipeList: plan.recipes }
  const { orderSummary, shipping, errorMessage, loading, setupIntent, changed, error } = useSelector(
    (state: RootState) => state.shippingConfirm
  )
  const { key } = useSelector((state: RootState) => state.key)
  const dispatch = useDispatch()
  const [localErrorMessage, setErrorMessage] = useState<string | undefined>()
  const [couponCode, setCouponCode] = useState<string>('')
  const [openAddressInputs, setOpenAddressInputs] = useState(false)
  const [openBillingAddressInputs, setOpenBillingAddressInputs] = useState(false)
  const [showAddressFinder, setShowAddressFinder] = useState(true)
  const [showBillingAddressFinder, setShowBillingAddressFinder] = useState(true)
  const [showPopup, setShowPopup] = useState(false)
  const [totalAddonPriceDiscount, setTotalAddonPriceDiscount] = useState(0)
  const [planPriceDiscount, setPlanPriceDiscount] = useState(0)
  const [ongoingWeeks, setOngoingWeeks] = useState(0)
  const [ongoingPackSize, setOngoingPackSize] = useState(0)
  const [ongoingNoOfPacks, setOngoingNoOfPacks] = useState(0)
  const [intent, setIntent] = useState(null)
  const stripe = useStripe()
  const elements = useElements()
  const location = useLocation()
  const [sendConsoleErrorToCloudWatch, setSendConsoleErrorToCloudWatch] = useState(false)
  const [shippingDates, setShippingDates] = useState([])
  const [paymentRequest, setPaymentRequest] = useState<any>(null)
  const [payEvent, setPayEvent] = useState(null)
  const [awaiting3ds, setAwaiting3ds] = useState(false)
  const [pricesLoading, setPricesLoading] = useState(false)

  const [zendeskScript, setZendeskScript] = useState(null)
  const { spoofMode, spoofedUserId } = useSelector((state: RootState) => state.ui)

  const { hashLoaded } = useSelector((state: RootState) => state.hash)

  const [currentPrices, setCurrentPrices] = useState(null)
  const [ongoingPrices, setOngoingPrices] = useState(null)
  const [pricesError, setPricesError] = useState(false)
  const [pricingData, setPricingData] = useState<any>(null)
  const [activeDeliveryPrice, setActiveDeliveryPrice] = useState(0)
  const [containsHealthPlusRecipes, setContainsHealthPlusRecipes] = useState('for')
  const [submitting, setSubmitting] = useState(false)
  const [submitErrors, setSubmitErrors] = useState(false)
  const [formErrors, setFormErrors] = useState<string[]>([])
  const [cardError, setCardError] = useState<any>()
  const [confirmBtnText, setConfirmBtnText] = useState('Start My Subscription')

  const gbScarcityTimer = useFeatureIsOn('scarcity-timer')
  const [displayTimer, setDisplayTimer] = useState(gbScarcityTimer)
  const [faqs, setFaqs] = useState(Faqs)
  const [showFirstBox, setShowFirstBox] = useState(0)
  const [billingDateToday, setBillingDateToday] = useState(false)
  const [total3DS, setTotal3DS] = useState(0) // 3ds amount shown on in popup
  const [total3DSTemp, setTotal3DSTemp] = useState(0) // temporary 3ds amount state

  var formikRef = useRef<any>()

  const treatPageBeforeCheckout = useFeatureIsOn('treat-page-before-checkout')

  // Navigate to new checkout page page for old sessions
  useEffect(() => {
    dispatch(updateKey('checkout'))
    navigate(`/signup/checkout/${location.search && '?' + stringify(parse(location.search))}`)
  }, [])

  useEffect(() => {
    setDisplayTimer(gbScarcityTimer ? true : false)
  }, [gbScarcityTimer])

  useEffect(() => {
    // if (!products)
    dispatch(getProducts())
  }, [])

  useEffect(() => {
    if (formikRef.current && formikRef.current.values.deliveryDate) {
      const date = dayjs(formikRef.current.values.deliveryDate).subtract(redZoneDays, 'day')
      date.isBefore(dayjs()) ? setBillingDateToday(true) : setBillingDateToday(false)
    }
  }, [formikRef?.current?.values?.deliveryDate])

  // AB test for 3ds £0.00
  useEffect(() => {
    if (currentPrices && currentPrices?.total > 0) {
      setTotal3DSTemp(currentPrices.total)
    }
  }, [currentPrices])

  useEffect(() => {
    // GB logic
    if (billingDateToday) {
      setTotal3DS(total3DSTemp)
      // Include in experiment if they have today billing,
      // This will be activation metric
      if (typeof window.gtag !== 'undefined') {
        window.gtag('event', 'payment_request_today', {
          page: 'sign_up_checkout',
          amount: total3DS
        })
      }
    } else {
      // Set 3DS amount back to zero if billing date not today
      setTotal3DS(0)
    }
  }, [total3DSTemp, billingDateToday])

  useEffect(() => {
    // Mobile
    //  - One dog: Complete Dog's Purchase
    //  - One dog char length > 9 || !One dog: Complete Purchase
    // Desktop
    //  - One dog: Start Dog's Subscription
    //  - !One dog: Start My Subscription

    const windowWidth = window.innerWidth
    const validDogs = dogInfo.dogs.filter(dog => dog.name && dog.name.trim() !== '')
    let dogName = ''

    if (validDogs.length === 1) {
      const singleDogName = validDogs[0].name
      dogName = singleDogName.endsWith('s') ? `${singleDogName}' ` : `${singleDogName}'s `
    }

    let buttonText = `Start ${dogName}Subscription`

    if (windowWidth < 400) {
      if (dogName.length >= 9) dogName = ''
      buttonText = `Complete ${dogName}Purchase`
    }
    setConfirmBtnText(buttonText)
  }, [dogInfo?.dogs])

  useEffect(() => {
    const handleClick = (event: any) => {
      const errorModule = document.getElementById('errorModule')
      // Scroll to first error
      errorModule?.addEventListener('click', function () {
        if (Object.keys(formikRef?.current?.errors)[0].startsWith('shipping')) {
          const firstErrorElement = document.getElementById('shippingAddressSearch')
          firstErrorElement?.scrollIntoView({ block: 'center' })
        } else {
          const firstErrorElement = document.getElementById(Object.keys(formikRef?.current?.errors)[0])
          firstErrorElement?.scrollIntoView({ block: 'center' })
        }
      })
      if (event.target.id !== 'confirmOrder') {
        setSubmitErrors(false)
      }
    }
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])

  useEffect(() => {
    if (
      formikRef?.current?.errors?.shippingAddress1 ||
      formikRef?.current?.errors?.shippingCity ||
      formikRef?.current?.errors?.shippingCounty ||
      formikRef?.current?.errors.shippingPostcode
    ) {
      setOpenAddressInputs(false)
      formikRef?.current?.setFieldError('shippingAddressSearch', 'Please enter your address')
    } else {
      delete formikRef?.current?.errors['shippingAddressSearch']
    }
  }, [formikRef?.current?.isSubmitting])

  useEffect(() => {
    recipesSelected.forEach((s: any) => {
      const recipeData = plan.recipes?.filter((r: any) => r.id === s.id)
      const newRecipeData = recipeList?.filter((r: any) => r.itemId === s.id)
      if (recipeData[0]?.range === 'health' || newRecipeData[0]?.range === 'health') {
        setContainsHealthPlusRecipes('from')
      }
    })
  }, [])

  useEffect(() => {
    if (formikRef?.current?.values?.deliveryDate) {
      const selectedDate: any[] = shippingDates?.filter(
        (dateFromShippingDates: any) =>
          dayjs(dateFromShippingDates.date).format('DD/MM/YYYY') ===
          dayjs(formikRef?.current?.values?.deliveryDate).format('DD/MM/YYYY')
      )

      if (selectedDate.length === 1) {
        setActiveDeliveryPrice(selectedDate[0].price)
      }
    }
  }, [formikRef?.current?.values?.deliveryDate])

  useEffect(() => {
    // Add ongoing prices and feeding guide to FAQS
    if (
      ongoingNoOfPacks &&
      ongoingPackSize &&
      ongoingWeeks &&
      ongoingPrices &&
      packType &&
      packs &&
      noOfPacks &&
      packSize
    ) {
      const faqList = Faqs.filter(
        q => q.question !== 'How much will my ongoing box cost?' && q.question !== 'How much food should I feed my dog?'
      )
      const ongoingPriceQuestion = {
        question: 'How much will my ongoing box cost?',
        answer: `After your trial box, we'll automatically update your plan from ${noOfPacks} x ${packSize}g to ${ongoingNoOfPacks} x ${ongoingPackSize}g.
            We will deliver your meals every
            ${ongoingWeeks} weeks costing just £${
              packType === 'FULL_TRIAL' && packs?.full?.costPerGrams
                ? (packs?.full?.costPerGrams / 100)?.toFixed(2)
                : (ongoingPrices?.costPerDay / 100)?.toFixed(2)
            } per day ${
              ongoingPrices?.delivery / 100 > 0 &&
              (dayjs(formikRef.current?.values['deliveryDate'])?.day() === 6
                ? ' + £4.99 delivery'
                : ' + £3.99 delivery')
            }`
      }
      let planType = ''
      // TODO: update this plan section when product Ids added
      if (packType === 'FULL_TRIAL' || packType === 'FULL') {
        planType = 'full plan'
      }
      if (packType === 'PARTIAL_TRIAL' || packType === 'PARTIAL') {
        planType = 'partial plan'
      }
      const numberOfDogs = dogInfo.dogs.filter(
        d => d['name'] !== '' && d['name'] !== undefined && d['name'] !== null
      ).length
      const feedingGuideQuestion = {
        question: `How much food should I feed my ${numberOfDogs > 1 ? 'dogs' : 'dog'}?`,
        answer: `On a ${planType} of Different Dog we recommend ${
          numberOfDogs > 1 ? `${getMutlipleDogsGramsPerDay()}` : `${packs.gramsPerDay}g a day`
        } which is ${getPercentageOfPack(packs, packSize)}.
        Once opened, packs stay fresh for 5 days. One pack should last you about ${getPacksPerDay(packs, packSize)}.`
      }
      faqList.unshift(ongoingPriceQuestion, feedingGuideQuestion)
      setFaqs(faqList)
    }
  }, [ongoingNoOfPacks, ongoingPackSize, ongoingWeeks, ongoingPrices, packType, packs, noOfPacks, packSize])

  useEffect(() => {
    if (sendConsoleErrorToCloudWatch && Object.keys(formikRef.current.errors).length > 0) {
      console.error(JSON.stringify(formikRef.current.errors))
    }
    setSendConsoleErrorToCloudWatch(false)
  }, [sendConsoleErrorToCloudWatch])

  const getMutlipleDogsGramsPerDay = () => {
    let multipleDogsString: any = []
    {
      summary?.map((dog: any) => {
        multipleDogsString.push(`${dog.gramsPerDay}g a day for ${dog.name}`)
      })
    }
    return multipleDogsString.join(' and ')
  }

  const getPercentageOfPack = (packs: any, packSize: number) => {
    const percentage = Math.round(((packs.gramsPerDay / packSize) * 100) / 5) * 5
    if (percentage === 100) {
      return 'a whole pack'
    }
    return `${Math.round(((packs.gramsPerDay / packSize) * 100) / 5) * 5}% of a pack`
  }

  const getPacksPerDay = (packs: any, packSize: number) => {
    let percentageOfPack = 0
    percentageOfPack = Math.round(((packs.gramsPerDay / packSize) * 100) / 5) * 5
    return `${Math.floor(100 / percentageOfPack / 0.5) * 0.5} ${
      Math.floor(100 / percentageOfPack / 0.5) * 0.5 <= 1 ? 'day' : 'days'
    }`
  }

  const getOngoingWeeks = () => {
    let ongoingWeeks: any = 0

    if (
      packType === 'PARTIAL_TRIAL' ||
      packType === 'PARTIAL' ||
      packType === 'partialTrial' ||
      packType === 'partial'
    ) {
      ongoingWeeks = pack?.partial.weeks
    } else if (packType === 'FULL_TRIAL' || packType === 'FULL' || packType === 'fullTrial' || packType === 'full') {
      ongoingWeeks = pack?.full.weeks
    }

    return ongoingWeeks
  }

  const getOngoingPackSize = () => {
    let ongoingPackSize: any = 0

    if (
      packType === 'PARTIAL_TRIAL' ||
      packType === 'PARTIAL' ||
      packType === 'partialTrial' ||
      packType === 'partial'
    ) {
      ongoingPackSize = pack?.partial.packSize
    } else if (packType === 'FULL_TRIAL' || packType === 'FULL' || packType === 'fullTrial' || packType === 'full') {
      ongoingPackSize = pack?.full.packSize
    }

    return ongoingPackSize
  }

  const getOngoingNoOfPacks = () => {
    let ongoingNoOfPacks: any = 0

    if (
      packType === 'PARTIAL_TRIAL' ||
      packType === 'PARTIAL' ||
      packType === 'partialTrial' ||
      packType === 'partial'
    ) {
      ongoingNoOfPacks = pack?.partial.numberOfPacks
    } else if (packType === 'FULL_TRIAL' || packType === 'FULL' || packType === 'fullTrial' || packType === 'full') {
      ongoingNoOfPacks = pack?.full.numberOfPacks
    }

    return ongoingNoOfPacks
  }

  const handlePaymentMethodReceived = async (event: any) => {
    // console.log('handlePaymentMethodReceived', {event})
    let data = handleFieldData(formikRef.current.values, event)
    // Update data with latest coupon code
    data.coupon = lastValidatedCoupon.code
    // Submit to check for other validation errors, validateForm doesn't seem to work here
    formikRef.current.handleSubmit()
    // getFormErrors(formikRef.current)

    // console.log({ data, formikRef })

    if (Object.keys(formikRef.current.errors).length === 0) {
      const deliveryDate = dayjs(formikRef.current.values.deliveryDate).format('YYYY-MM-DD')
      const planObj = {
        id: packSize + 'g-' + weeks + 'w',
        type: fixedOrCustomPlan,
        grams: packSize
      }

      if (packType !== 'CUSTOM') {
        planObj.level = pack.planLevel

        if (packType !== 'CUSTOM') {
          planObj.size = packType
        }
      }

      data = { ...data, plan: planObj, deliveryDate, visitorId: dogInfo.visitorId }
      data = { ...data, payment: event.paymentMethod }
      setPayEvent(event)
    } else {
      // Will trigger for mobile payments with validation issues, we hold on to the token if it exists
      if (event?.paymentMethod) {
        // setPayEvent(null)
        setPayEvent(event)
        // setIntent(event.paymentMethod)
      } else {
        setPayEvent(null) // Clear pay event due to errors
        // setIntent(null) // Clear pay event due to errors
      }
      // Return success to close the popup, even if failed state
      event.complete('success')
    }
  }

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'

    window.scrollTo(0, 0)
  }, [key])

  /**
   * Load the Apple Pay/Gpay buttons if available
   */
  useEffect(() => {
    if (stripe) {
      console.log('Total shown on 3DS popup is', total3DS)
      createPaymentRequest()
    }
  }, [stripe, total3DS])

  const createPaymentRequest = () => {
    setPaymentRequest(null)

    const pr = stripe.paymentRequest({
      country: 'GB',
      currency: 'gbp',
      total: {
        label: 'Different Dog',
        amount: total3DS
      },
      requestPayerName: false,
      requestPayerEmail: false
    })

    pr.canMakePayment().then(res => {
      if (res) {
        pr.on('paymentmethod', handlePaymentMethodReceived)
        setPaymentRequest(pr)
      }
    })
  }

  useEffect(() => {
    Api.get('wwwREST', '/v1/public/signup/calendar', {})
      .then(({ shippingDates }) => {
        setShippingDates(shippingDates)
        if (!formikRef?.current?.values?.deliveryDate) {
          const defaultDate = shippingDates.find(d => d.price === 0).date
          formikRef.current.setFieldValue('deliveryDate', defaultDate)
        }
      })
      .catch((err: any) => {
        console.error(JSON.stringify(err, null, 2))
      })
  }, [])

  useEffect(() => {
    dispatch(resetLoadingStatus())
    dispatch(resetErrorMessageForShipping())
  }, [localErrorMessage])

  useEffect(() => {
    setOngoingWeeks(getOngoingWeeks())
    setOngoingPackSize(getOngoingPackSize())
    setOngoingNoOfPacks(getOngoingNoOfPacks())
  }, [lastValidatedCoupon])

  useEffect(() => {
    if (key !== 'checkout') {
      navigate(`/signup/${key}/${location.search && '?' + stringify(parse(location.search))}`)
    }
  }, [key])

  useEffect(() => {
    if (currCouponCode) setCouponCode(currCouponCode)
    else setCouponCode(coupon.code)
  }, [coupon, currCouponCode])

  useEffect(() => {
    if (changed) {
      if (typeof window.gtag !== 'undefined') {
        window.gtag('event', 'checkout_submitting', {
          page: 'sign_up_checkout'
        })
      }
      if (orderSummary && orderSummary.subscriptionId) {
        if (typeof window.gtag !== 'undefined') {
          window.gtag('event', 'purchase', {
            transaction_id: orderSummary?.subscriptionId,
            value: parseFloat((currentPrices?.total / 100)?.toFixed(2)),
            planLevel: pack?.planLevel,
            shipping: currentPrices?.delivery,
            currency: 'GBP',
            coupon: lastValidatedCoupon?.code,
            items: recipesSelected.concat(addonsSelected)
          })
          if (process.env.GATSBY_USER_BRANCH === 'production')
            window.gtag('event', 'conversion', {
              send_to: 'AW-627026958/3d7WCPrb_K4YEI7Y_qoC',
              value: parseFloat((currentPrices?.total / 100)?.toFixed(2)),
              currency: 'GBP',
              transaction_id: orderSummary?.subscriptionId
            })
        }
      }

      if (typeof fbq !== 'undefined') {
        if (orderSummary && orderSummary.subscriptionId) {
          function getFbcFbp() {
            let fbc = '',
              fbp = ''
            try {
              const cookies = document.cookie.split(';')
              for (const cookie of cookies) {
                const [name, value] = cookie.trim().split('=')
                if (name === '_fbc') fbc = value
                if (name === '_fbp') fbp = value
              }
              return { fbc, fbp }
            } catch (_err) {
              return { fbc, fbp }
            }
          }
          const { fbc, fbp } = getFbcFbp()

          fbq('init', '274341553658613', {
            external_id: orderSummary?.customerId,
            subscription_id: orderSummary?.subscriptionId,
            fbc,
            fbp
          })
          fbq(
            'track',
            'Purchase',
            { currency: 'GBP', value: parseFloat((currentPrices?.total / 100)?.toFixed(2)) },
            { eventID: orderSummary.subscriptionId }
          )
        }
      }

      if (typeof ttq !== 'undefined') {
        if (orderSummary && orderSummary.subscriptionId) {
          ttq.identify({
            email: shipping.customer.email,
            phone_number: shipping.customer.telephone
          })
          ttq.track(
            'CompletePayment',
            {
              content_id: orderSummary.subscriptionId,
              currency: 'GBP',
              value: parseFloat((currentPrices?.total / 100)?.toFixed(2))
            },
            {
              event_id: orderSummary?.subscriptionId
            }
          )
        }
      }

      if (typeof window?.uetq !== 'undefined') {
        window.uetq = window.uetq || []
        window.uetq.push('set', {
          pid: {
            em: shipping.customer.email,
            ph: shipping.customer.telephone
          }
        })
        window.uetq.push('event', 'subscribe', {
          revenue_value: parseFloat((currentPrices?.total / 100)?.toFixed(2)),
          currency: 'GBP'
        })
      }

      const signInAndRedirect = async () => {
        // If  Apple Pay/Gpay completed
        // close the modal and redirect
        if (typeof payEvent !== 'undefined' && payEvent) {
          payEvent.complete('success')
        }

        try {
          const _signin = await Auth.signIn(shipping.customer.email, shipping.customer.password)
          if (typeof window.gtag !== 'undefined') {
            window.gtag('event', 'checkoutComplete', {
              page: 'sign_up_checkout'
            })
          }
          if (treatPageBeforeCheckout) {
            dispatch(updateKey('thankyou', pricingData))
          } else {
            dispatch(updateKey('add-treats', pricingData))
          }
        } catch (err) {
          console.error('error signing in', JSON.stringify(err, null, 2))
        }
      }
      signInAndRedirect()
    }
  }, [changed])

  useEffect(() => {
    if (typeof window.gtag !== 'undefined' && currentPrices) {
      const recipeItems = plan?.recipes?.map(r => {
        return {
          item_id: r.id,
          item_name: r.name,
          quantity: r.quantity,
          item_list_id: 'recipes',
          item_list_name: 'Recipes'
        }
      })

      const addonItems = plan?.addons?.map(a => {
        return {
          item_id: a.id,
          item_name: a.name,
          price: (a.price / 100).toFixed(2),
          quantity: a.quantity,
          item_list_id: 'addons',
          item_list_name: 'Addons'
        }
      })

      window.gtag('event', 'begin_checkout', {
        currency: 'GBP',
        value: (currentPrices?.total / 100).toFixed(2),
        coupon: lastValidatedCoupon?.code,
        items: recipeItems.concat(addonItems)
      })
    }
  }, [currentPrices])

  useEffect(() => {
    if (errorMessage || errorMessage?.popup) {
      setErrorMessage(errorMessage?.popup?.message)
      setShowPopup(true)

      if (typeof window.gtag !== 'undefined') {
        window.gtag('event', 'confirm_order_error', {
          page: 'sign_up_checkout'
        })
      }

      // If theres an error with validation
      // close the apple pay/gpay modal (has to be done with success)
      if (typeof payEvent !== 'undefined' && payEvent) {
        setPayEvent(null) // Clear pay event due to errors
        payEvent.complete('success')
      }

      setAwaiting3ds(false)

      console.error(JSON.stringify(errorMessage, null, 2))
    }
  }, [errorMessage])

  useEffect(() => {
    if (error && !errorMessage) {
      setAwaiting3ds(false)
    }
  }, [error])

  useEffect(() => {
    if (errorMessage?.message === 'Generic error') {
      setErrorMessage('Please try again, or contact Customer Delight on 01743 384 562.')
      setAwaiting3ds(false)
      setShowPopup(true)
    }
  }, [errorMessage])

  useEffect(() => {
    if (typeof AddressFinder.setup === 'undefined') {
      setOpenAddressInputs(true)
    }

    AddressFinder.setup({
      inputField: document.getElementById('shippingAddressSearch'),
      apiKey: process.env.GATSBY_IDEAL_POSTCODES,
      injectStyle: true,
      onAddressRetrieved: ({ line_1, line_2, line_3, post_town, county, postcode }) => {
        formikRef.current.setFieldValue([`shippingAddress1`], line_1)
        formikRef.current.setFieldValue([`shippingAddress2`], line_2)
        formikRef.current.setFieldValue([`shippingCity`], post_town)
        formikRef.current.setFieldValue([`shippingPostcode`], postcode)
        formikRef.current.setFieldValue([`shippingCounty`], county)
        setOpenAddressInputs(true)
        setShowAddressFinder(false)
        dispatch(validatePostcode(postcode))
      },
      onFailedCheck: () => {
        setOpenAddressInputs(true)
      },
      onSearchError: () => {
        setOpenAddressInputs(true)
      }
    })

    AddressFinder.setup({
      inputField: document.getElementById('billingAddressSearch'),
      apiKey: process.env.GATSBY_IDEAL_POSTCODES,
      injectStyle: true,
      onAddressRetrieved: ({ line_1, line_2, line_3, post_town, county, postcode }) => {
        formikRef.current.setFieldValue([`billingAddress1`], line_1)
        formikRef.current.setFieldValue([`billingAddress2`], line_2)
        formikRef.current.setFieldValue([`billingCity`], post_town)
        formikRef.current.setFieldValue([`billingPostcode`], postcode)
        formikRef.current.setFieldValue([`billingCounty`], county)
        setShowBillingAddressFinder(false)
        setOpenBillingAddressInputs(true)
      },
      onFailedCheck: () => {
        setOpenBillingAddressInputs(true)
      },
      onSearchError: () => {
        setOpenBillingAddressInputs(true)
      }
    })
  }, [])

  useEffect(() => {
    const confirmCardSetup = async () => {
      const response = await stripe?.confirmCardSetup(setupIntent.client_secret)
      // console.log('confirmCardSetup', {response})

      if (typeof window.gtag !== 'undefined') {
        window.gtag('event', 'checkout3DSpopupShow', {
          page: 'sign_up_checkout'
        })
      }
      if (response?.error) {
        setErrorMessage(
          'There has been a problem adding your card. Please try again, or contact Customer Delight on 01743 384 562.'
        )
        setAwaiting3ds(false)
        setShowPopup(true)
        if (typeof window.gtag !== 'undefined') {
          window.gtag('event', 'checkout_3DS_fail', {
            page: 'sign_up_checkout'
          })
        }
        // This isn't an error, but the Logger component only catches errors
        // https://www.npmjs.com/package/cloudwatch-front-logger
        console.error(`Problem adding card - ${JSON.stringify(response)}`)
      }

      if (response?.setupIntent) {
        if (typeof window.gtag !== 'undefined') {
          window.gtag('event', 'checkout_3DS_succeed', {
            page: 'sign_up_checkout'
          })
        }
        setIntent(response?.setupIntent)
        // if (!payEvent) {
        console.error(`3DS confirmed`)
        formikRef.current.handleSubmit()
        // }
      }
    }

    if (typeof setupIntent !== 'undefined') {
      confirmCardSetup()
    }
  }, [setupIntent])

  useEffect(() => {
    if (lastValidatedCoupon || formikRef?.current?.values?.deliveryDate) {
      dispatch(setHashLoaded(true))
    }
  }, [lastValidatedCoupon, formikRef?.current?.values?.deliveryDate])

  useEffect(() => {
    if (!packSize || !weeks || !fixedOrCustomPlan) return
    const planObj = {
      id: packSize + 'g-' + weeks + 'w',
      type: fixedOrCustomPlan,
      grams: packSize
    }

    if (packType !== 'CUSTOM') {
      planObj.level = pack.planLevel

      if (packType !== 'CUSTOM') {
        planObj.size = packType
      }
    }

    // Call estimates API and populate state for checkout totals
    if (lastValidatedCoupon && recipesSelected && addonsSelected && hashLoaded) {
      const url = spoofMode ? '/v1/public/signup/estimate?userId=' + spoofedUserId : '/v1/public/signup/estimate'
      setPricesLoading(true)

      Api.post('wwwREST', url, {
        body: {
          coupon: lastValidatedCoupon.code,
          recipes: recipesSelected,
          addons: addonsSelected,
          plan: planObj,
          deliveryDate: dayjs(formikRef?.current?.values?.deliveryDate).format('YYYY-MM-DD'),
          visitorId: dogInfo.visitorId
        }
      })
        .then(async ({ current, ongoing }) => {
          setPricingData({ current: current, ongoing: ongoing })
          setCurrentPrices(current)
          setOngoingPrices(ongoing)
          setTotalAddonPriceDiscount(calculateTotalAddonPriceDiscount(current.addons))
          setPlanPriceDiscount(calculatePlanDiscount(current.plan + current.recipes))
          setPricesError(false)
          await dispatch(setHashLoaded(false))
          setPricesLoading(false)
        })
        .catch(async (err: any) => {
          console.error(JSON.stringify(err, null, 2))
          setPricesError(true)
          await dispatch(setHashLoaded(false))
          setPricesLoading(false)
        })
    }
  }, [lastValidatedCoupon, hashLoaded, formikRef?.current?.values?.deliveryDate])

  const calculateTotalAddonPriceDiscount = (totalAddonPrice: number) => {
    let total = 0

    if (totalAddonPrice > 0 && lastValidatedCoupon.code) {
      if (lastValidatedCoupon.type === 'percentage') {
        total = totalAddonPrice - (lastValidatedCoupon.amount / 100) * totalAddonPrice
      } else {
        total = totalAddonPrice
      }
    }

    return total
  }

  const calculatePlanDiscount = (planPrice: number) => {
    let total = 0

    if (lastValidatedCoupon.code) {
      if (lastValidatedCoupon.type === 'percentage') {
        total = planPrice - (lastValidatedCoupon.amount / 100) * planPrice
      } else {
        total = planPrice - lastValidatedCoupon.amount
      }
    }

    return total
  }

  useEffect(() => {
    const zendeskScript = (
      <script type="text/javascript">{`
        if (typeof window.zE !== 'undefined') {
          window.zE('webWidget', 'helpCenter:setSuggestions', { search: 'delivery' })
        }
    `}</script>
    )
    // This is a hack because zendesk takes a long time to load onto the page
    window.setTimeout(() => {
      setZendeskScript(zendeskScript)
    }, 1000)
  }, [])

  useEffect(() => {
    if (typeof ttq !== 'undefined') {
      ttq.track('InitiateCheckout')
    }
  }, [])

  // const recipesDescription = plan.recipes.map((r: any) => {
  //   const matched = recipesSelected.map((rs: any) => {
  //     if (rs.id === r.id) {
  //       return (
  //         <p>
  //           <span className="font-bold">{rs.quantity}x </span> {r.name}
  //         </p>
  //       )
  //     }
  //   })
  //   return matched
  // })

  // let addonsDescription = plan.addons.map((a: any) => {
  //   const matched = addonsSelected.map((as: any) => {
  //     if (as.id === a.id) {
  //       return as.quantity + ' x ' + a.name
  //     }
  //   })
  //   return matched
  // })

  // addonsDescription = addonsDescription
  //   .flat()
  //   .filter((a: any) => a)
  //   .join(', ')

  const backClick = async () => {
    if (formikRef.current) {
      dispatch(saveShippingConfirmForm(formikRef.current.values, fixedOrCustomPlan))
    }
    if (treatPageBeforeCheckout) {
      dispatch(updateKey('treats'))
    } else {
      await dispatch(updateKey('recipes'))
    }
  }

  const nextClick = (call: any) => {
    call()
  }

  const redZoneDays = 4

  const getBillingDate = () => {
    if (formikRef.current && formikRef.current.values.deliveryDate) {
      const date = dayjs(formikRef.current.values.deliveryDate).subtract(redZoneDays, 'day')
      date.isBefore(dayjs()) ? setBillingDateToday(true) : setBillingDateToday(false)
      return date.isBefore(dayjs()) ? '' : date.format('DD-MM-YYYY')
    }

    return ''
  }

  const handleFieldData = (values: any, event?: any) => {
    const selectedAddOn = addonsSelected.map((item: any) => {
      return { id: item.id, quantity: item.quantity }
    })

    const selectedRecipes = recipesSelected.map((item: any) => {
      return { id: item.id, quantity: item.quantity }
    })

    function getFbcFbp() {
      let fbc = '',
        fbp = ''
      try {
        const cookies = document.cookie.split(';')
        for (const cookie of cookies) {
          const [name, value] = cookie.trim().split('=')
          if (name === '_fbc') fbc = value
          if (name === '_fbp') fbp = value
        }
        return { fbc, fbp }
      } catch (_err) {
        return { fbc, fbp }
      }
    }
    const { fbc, fbp } = getFbcFbp()

    const data: any = {
      customer: {
        name: values.firstName,
        surname: values.lastName,
        email: values.email.trim(),
        marketingOptIn: parentDetails.customer.marketingOptIn || marketingOptIn,
        telephone: values.phoneNumber,
        smsOptIn: values.smsOptIn,
        mailConsentFirstParty: values.mailConsentFirstParty,
        mailConsentThirdParty: values.mailConsentThirdParty,
        password: createTemporaryPassword(12),
        total: currentPrices?.total
      },
      address: {
        shipping: {
          address1: values.shippingAddress1,
          address2: values.shippingAddress2,
          city: values.shippingCity,
          county: values.shippingCounty,
          postcode: values.shippingPostcode
        },
        billing: {
          name: isBillingAddressSame ? values.firstName.replace(/( ?)PHONE PAYMENT/g, '') : values.billingFirstName,
          surname: isBillingAddressSame ? values.lastName : values.billingLastName,
          address1: isBillingAddressSame ? values.shippingAddress1 : values.billingAddress1,
          address2: isBillingAddressSame ? values.shippingAddress2 : values.billingAddress2,
          city: isBillingAddressSame ? values.shippingCity : values.billingCity,
          county: isBillingAddressSame ? values.shippingCounty : values.billingCounty,
          postcode: isBillingAddressSame ? values.shippingPostcode : values.billingPostcode
        }
      },
      coupon: lastValidatedCoupon.code,
      notes: values.deliveryInstructions,
      recipes: selectedRecipes,
      addons: selectedAddOn,
      dogs: dogInfo.dogs.filter((d: any) => d.name),
      fb: {
        fbc,
        fbp
      }
    }

    // console.log('handleFieldData', { payEvent, intent, data })
    if (!event) {
      if (!payEvent) {
        dispatch(createShippingConfirm(data, false))
        // debugging for double submit
        console.error('createShippingConfirm - handle field data')
      }
    }
    return data
  }

  const sendPhoneNumberAndEmail = (values: any, errors: any, smsOptIn = false) => {
    if (!errors.phoneNumber && values.email && values.phoneNumber) {
      Api.post('wwwREST', '/v1/public/signup/phone', {
        body: {
          email: values.email,
          telephone: values.phoneNumber,
          smsOptIn,
          marketingCookieConsent: window?.Cookiebot?.consent?.marketing,
          visitorId: dogInfo.visitorId
        }
      }).catch((err: any) => {
        console.error(JSON.stringify(err, null, 2))
      })
    }
  }

  const getDiscountedCostPerDay = () => {
    if (plan.costPerGrams) {
      return getDiscountedDeliveryPrice(plan.costPerGrams)
    }
    if (planPriceDiscount < 0) {
      return 0
    }
    if (planPriceDiscount && weeks) {
      return planPriceDiscount / (weeks * 7)
    }

    return currentPrices?.costPerDay
  }

  const getDiscountedDeliveryPrice = (price: number) => {
    let total = 0

    if (price > 0 && lastValidatedCoupon.code) {
      if (lastValidatedCoupon.type === 'percentage') {
        total = price - (lastValidatedCoupon.amount / 100) * price
      } else {
        total = price
      }
    }

    return total
  }

  const calcDogAge = (dob: string) => {
    const birthdate = new Date(dob)
    const today = new Date()
    let year = today.getFullYear() - birthdate.getFullYear() - (today.getMonth() < birthdate.getMonth())
    let month = today.getMonth() - birthdate.getMonth()
    if (year < 0) year = ''
    if (month < 0) month = month + 12
    return `${year === 1 ? year + ' year ' : ''}${year > 1 ? year + ' years ' : ''} ${
      month > 0 && year ? ' and ' : ''
    }${month === 1 ? month + ' month' : ''}${month > 1 ? month + ' months' : ''}`
  }

  const DogList: Array<string> = []
  dogInfo.dogs.forEach((data: any) => {
    if (data.name) {
      DogList.push(data.name)
    }
  })

  const DogNames = DogList.join(', ').replace(/,(?!.*,)/gim, ' and')

  const setDisplayDeal = (timerEnded: boolean) => {
    if (typeof window.gtag !== 'undefined' && timerEnded === false) {
      window.gtag('event', 'checkout_timer_end', {
        page: 'sign_up_checkout'
      })
    }
    setDisplayTimer(timerEnded)
  }

  const getFormErrors = (props: any) => {
    setFormErrors([])
    let displayErrors: string[] = []
    // if (cardError) {
    //   displayErrors.push(cardError)
    // }
    if (props.errors && Object.keys(props.errors).length > 0) {
      setSubmitErrors(true)
      for (const error of Object.keys(props.errors)) {
        if (error === 'phoneNumber') {
          displayErrors.push('Phone number')
        } else if (error === 'firstName') {
          displayErrors.push('First name')
        } else if (error === 'lastName') {
          displayErrors.push('Last name')
        } else if (error === 'deliveryDate') {
          displayErrors.push('Delivery date')
        } else {
          displayErrors.push(error.charAt(0).toUpperCase() + error.slice(1))
        }
      }
      // handle all address errors to show one address error
      const addressFields = [
        'ShippingAddressSearch',
        'ShippingAddress1',
        'ShippingCity',
        'ShippingCounty',
        'ShippingPostcode'
      ]
      const containsAddressField = addressFields.some(field => displayErrors.includes(field))
      if (containsAddressField) {
        const filteredErrors = displayErrors.filter(error => !addressFields.includes(error))
        filteredErrors.push('Address')
        displayErrors = filteredErrors
      }
      // handle all billing address errors to show one address error
      const billingAddressFields = [
        'BillingFirstName',
        'BillingLastName',
        'BillingAddress1',
        'BillingCity',
        'BillingCounty',
        'BillingPostcode'
      ]

      const containsBillingAddressField = billingAddressFields.some(field => displayErrors.includes(field))
      if (containsBillingAddressField) {
        const filteredErrors = displayErrors.filter(error => !billingAddressFields.includes(error))
        if (!isBillingAddressSame) {
          filteredErrors.push('Billing details')
        }
        displayErrors = filteredErrors
      }
    }
    setFormErrors(displayErrors)
  }

  const renderPackText = () => {
    if (
      packType === 'FULL_TRIAL' ||
      packType === 'PARTIAL_TRIAL' ||
      packType === 'fullTrial' ||
      packType === 'partialTrial'
    ) {
      return (
        <>
          <span className="font-bold">Starter Box </span>
          {window.innerWidth < 400 && <br />}
          <span>
            ({noOfPacks} x {packSize}g pots)
          </span>
        </>
      )
    } else {
      return (
        <>
          <span className="font-bold">{packType.charAt(0).toUpperCase() + packType.slice(1).toLowerCase()} Plan </span>
          {window.innerWidth < 400 && <br />}
          <span>
            ({noOfPacks} x {packSize}g pots)
          </span>
        </>
      )
    }
  }

  const ExtendedShippingSchema = ShippingSchema.shape({
    billingFirstName: yup.string().when([], {
      is: () => !isBillingAddressSame,
      then: yup.string().required('Please enter your billing name'),
      otherwise: yup.string()
    }),
    billingLastName: yup.string().when([], {
      is: () => !isBillingAddressSame,
      then: yup.string().required('Please enter your billing surname'),
      otherwise: yup.string()
    }),
    billingAddress1: yup.string().when([], {
      is: () => !isBillingAddressSame,
      then: yup.string().required('Please enter the first line of your billing address'),
      otherwise: yup.string()
    }),
    billingAddress2: yup.string(),
    billingCity: yup.string().when([], {
      is: () => !isBillingAddressSame,
      then: yup.string().required('Please enter your billing city'),
      otherwise: yup.string()
    }),
    billingCounty: yup.string().when([], {
      is: () => !isBillingAddressSame,
      then: yup.string().required('Please enter your billing county'),
      otherwise: yup.string()
    }),
    billingPostcode: yup.string().when([], {
      is: () => !isBillingAddressSame,
      then: yup.string().required('Please enter your billing postcode'),
      otherwise: yup.string()
    })
  })

  return (
    <>
      <div className="relative">
        <div className="header-wave-new h-[460px]" />
        <div>
          <Formik
            innerRef={formikRef}
            initialValues={{
              deliveryDate: shipping.deliveryDate,
              shippingAddressSearch: '',
              shippingAddress1: shipping.address.shipping.address1,
              shippingAddress2: shipping.address.shipping.address2,
              shippingCity: shipping.address.shipping.city,
              shippingCounty: shipping.address.shipping.county,
              shippingPostcode: shipping.address.shipping.postcode,
              phoneNumber: shipping.customer.telephone,
              firstName: parentDetails.customer.name ? parentDetails.customer.name : shipping.customer.name,
              lastName: parentDetails.customer.surname ? parentDetails.customer.surname : shipping.customer.surname,
              email: parentDetails.customer.email ?? dogInfo.customer.email,
              deliveryInstructions: shipping.notes,
              billingFirstName: '',
              billingLastName: '',
              billingAddressSearch: '',
              billingAddress1: '',
              billingAddress2: '',
              billingCity: '',
              billingCounty: '',
              billingPostcode: '',
              coupon: parentDetails.coupon,
              cardNumberElement: '',
              // card: new ElementState(),
              payment: shipping.payment
            }}
            validationSchema={ExtendedShippingSchema}
            onSubmit={async (values: any) => {
              // console.log('onSubmit', { values, payEvent, intent })
              if (localErrorMessage) {
                setErrorMessage('')
              }
              setSubmitting(true)

              if (typeof window.gtag !== 'undefined') {
                window.gtag('event', 'confirm_order', {
                  page: 'sign_up_checkout'
                })
              }

              // if (payEvent) return

              //Confirm_Order
              if (!values.firstName.includes('PHONE PAYMENT') && (!stripe || !elements)) return
              console.error(`Clicked confirm order`)

              let payment = null
              // if (!values.firstName.includes('PHONE PAYMENT')) {
              if (!payEvent && !values.firstName.includes('PHONE PAYMENT')) {
                // @ts-ignore
                const cardElement = elements.getElement(CardNumberElement)!
                payment = await stripe.createPaymentMethod({
                  type: 'card',
                  card: cardElement,
                  billing_details: {
                    name: isBillingAddressSame
                      ? `${values.firstName} ${values.lastName}`
                      : `${values.billingFirstName} ${values.billingLastName}`,
                    address: {
                      line1: isBillingAddressSame ? values.shippingAddress1 : values.billingAddress1,
                      line2: isBillingAddressSame ? values.shippingAddress2 : values.billingAddress2,
                      city: isBillingAddressSame ? values.shippingCity : values.billingCity,
                      state: isBillingAddressSame ? values.shippingCounty : values.billingCounty,
                      postal_code: isBillingAddressSame ? values.shippingPostcode : values.billingPostcode
                    },
                    email: values.email.trim(),
                    phone: values.phoneNumber
                  }
                })
              }
              if (payEvent) payment = payEvent
              // if(payEvent) setIntent(payEvent)
              // console.log({payEvent, intent})

              const deliveryDate = dayjs(values.deliveryDate).format('YYYY-MM-DD')

              if (payment?.error) {
                //failure
                console.error(JSON.stringify(payment.error, null, 2))
                setErrorMessage(payment.error.message)
                setShowPopup(true)
                setSubmitting(false)
                // setFormErrors(['Card details'])
              } else {
                const planObj = {
                  id: packSize + 'g-' + weeks + 'w',
                  type: fixedOrCustomPlan,
                  grams: packSize
                }

                if (packType !== 'CUSTOM') {
                  planObj.level = pack.planLevel

                  if (packType !== 'CUSTOM') {
                    planObj.size = packType
                  }
                }

                // TODO: Updating packType on submit to handle old code through account.
                // When moving to new code update this if statement!
                if (packType.includes('Trial')) {
                  planObj.size = packType.toUpperCase().replace('TRIAL', '_TRIAL')
                }

                let data = handleFieldData(values)

                /**
                 * 3DS step by step
                 * 1. Create payment method on form submission (above)
                 * 2. Send payment method to BE (payment?.method)
                 * 3. BE returns intent (setupIntent in redux)
                 * 4. User is asked to confirm and confirms through banking app
                 * 5. Confirmed card setup returns setupIntent (further above)
                 * 6. Form gets resubmitted when intent state is populated
                 * 7. If validation passes, checkout is completed
                 */

                data = { ...data, plan: planObj, deliveryDate, visitorId: dogInfo.visitorId }

                if (intent) {
                  data = { ...data, intent }
                  // This isn't an error, but the Logger component only catches errors
                  // https://www.npmjs.com/package/cloudwatch-front-logger
                  console.error(`Intent - ${JSON.stringify(intent)}`)
                } else {
                  data = { ...data, payment: payment?.paymentMethod || null }
                  setAwaiting3ds(true)
                  // This isn't an error, but the Logger component only catches errors
                  // https://www.npmjs.com/package/cloudwatch-front-logger
                  console.error(`Awaiting 3DS confirmation`)
                  console.error(`Payment method - ${JSON.stringify(payment?.paymentMethod)}`)
                  if (typeof window.gtag !== 'undefined') {
                    window.gtag('event', 'checkoutPaymentBegin', {
                      page: 'sign_up_checkout'
                    })
                  }
                }

                // console.log('before createShippingConfirm', { data, intent })
                dispatch(createShippingConfirm(data, true))
                dispatch(updateCurrCouponCode(lastValidatedCoupon.code as string))
                dispatch(updateCouponCode(lastValidatedCoupon.code as string))
                dispatch(updateCustomerEmail(values.email))
                dispatch(updateParentEmail(values.email))
                setSubmitting(false)
                // debugging for double submit
                console.error('createShippingConfirm - formik submit')
              }
            }}
            validateOnMount={false}
          >
            {props => {
              function checkInputState(fieldName: string) {
                if (!props.errors[fieldName] && props.values[fieldName]) {
                  if (fieldName === 'shippingPostcode') {
                    if (errorMessage === 'Postcode is not valid!') {
                      return 'field-error'
                    }
                  }
                  return 'field-success'
                } else if (props.errors?.[fieldName] && props.touched?.[fieldName]) {
                  return 'field-error'
                }
                return 'field-focus'
              }

              return (
                <div id="checkout" className="relative">
                  {displayTimer && (
                    <div className="discount-bg flex flex-row text-center justify-center items-center pt-4">
                      <h3 className="p-7 pr-2 text-center text-base text-black sm:text-lg">
                        {lastValidatedCoupon.type === 'percentage'
                          ? lastValidatedCoupon.amount + '% off'
                          : '£' + (lastValidatedCoupon.amount / 100).toFixed(2) + ' off'}{' '}
                        ends in
                      </h3>
                      <CountdownTimer setDisplayDeal={setDisplayDeal} />
                    </div>
                  )}
                  <div className="pt-6 mb-2">
                    <h1
                      data-testid="reviewPlanAndCheckout"
                      className="p-4 text-center text-2xl tracking-tight sm:text-5xl"
                    >
                      Review Plan &amp; Checkout
                    </h1>
                  </div>
                  <p className="font-sans relative text-center text-sm sm:text-lg mb-4 px-8 md:px-0">
                    92% of owners see a positive change since feeding our recipes
                  </p>
                  {/* {coupon?.newCustomerTreatOffer && (
                    <div className="m-5 grid grid-cols-1 rounded-3xl bg-fourth px-0 sm:grid-cols-1 sm:gap-x-3 md:mx-auto md:w-3/4 lg:w-1/2 relative mb-4">
                      <div className="py-2 md:p-4 flex flex-row items-center justify-center">
                        <StaticImage
                          objectFit={'contain'}
                          className="mr-2 flex-shrink-0"
                          alt="1"
                          width={40}
                          src="../../../images/dog-details/bone.svg"
                        />
                        <p className="text-center text-xs md:text-base font-sans">
                          Choose your
                          <span className="font-bold"> FREE Treat </span>
                          on the next page
                        </p>
                      </div>
                    </div>
                  )} */}
                  <div className="m-5 grid grid-cols-1 rounded-2xl bg-checkoutBG px-0 sm:grid-cols-1 sm:gap-x-3 sm:py-8 md:mx-auto md:w-3/4 md:px-20 lg:w-1/2 -mb-4 relative">
                    <div className="grid grid-cols-1 p-4 sm:grid-cols-1">
                      <div className="mb-4 flex flex-row items-center">
                        <StaticImage
                          objectFit={'contain'}
                          className="mr-2 flex-shrink-0"
                          alt="1"
                          width={50}
                          src="../../../images/dog-details/signup-checkout-1.svg"
                        />
                        <h2 className="text-xl">Our chefs will be cooking for:</h2>
                      </div>

                      <div className="grid grid-cols-3 items-baseline gap-0 pb-4">
                        <div className="col-span-2">
                          <h3 className="text-lg font-bold">Dog details</h3>
                          {dogInfo.dogs.map(dog => {
                            return (
                              dog.name && (
                                <p className="text-base" key={dog.name}>
                                  {dog.name}, {calcDogAge(dog.dob)}
                                </p>
                              )
                            )
                          })}
                        </div>
                        <div className="col-span-1 flex items-center justify-self-end">
                          <div>
                            {/* <button
                              type="button"
                              onClick={async () => {
                                if (typeof window.gtag !== 'undefined') {
                                  window.gtag('event', 'clicked_edit_dog', {
                                    page: 'sign_up_checkout'
                                  })
                                }

                                await dispatch(updateKey(`${dogDetailsPage}`))
                              }}
                              className="rounded-full border-2 border-base py-2 !px-8 hover:underline focus:underline"
                            >
                              Edit
                            </button> */}
                          </div>
                        </div>
                      </div>

                      <div className="mb-8 grid grid-cols-3 items-baseline gap-0 border-b border-primary pb-8">
                        <div className="col-span-3 flex items-center">
                          <h3 className="my-4 flex-grow text-lg font-bold">This is their plan</h3>
                          <div className="justify-end">
                            {/* <button
                              type="button"
                              onClick={async () => {
                                if (typeof window.gtag !== 'undefined') {
                                  window.gtag('event', 'clicked_edit_plan', {
                                    page: 'sign_up_checkout'
                                  })
                                }

                                await dispatch(updateKey('plan'))
                              }}
                              className="rounded-full border-2 border-base py-2 !px-8 hover:underline focus:underline"
                            >
                              Edit
                            </button> */}
                          </div>
                        </div>

                        <div className="col-span-3">
                          {packType === 'FULL_TRIAL' ||
                          packType === 'PARTIAL_TRIAL' ||
                          packType === 'fullTrial' ||
                          packType === 'partialTrial' ? (
                            <div className="leading-7 sm:leading-normal">
                              <p>
                                <span className="font-bold">Starter Box:</span> {noOfPacks} x {packSize}g packs
                              </p>
                              <p>
                                <span className="font-bold">Ongoing Box:</span> {ongoingNoOfPacks} x {ongoingPackSize}g
                                packs every {ongoingWeeks} weeks
                              </p>
                              {/* {addonsSelected?.length > 0 && (
                                <p>
                                  <span className="font-bold">Treats &amp; Extras:</span> {addonsDescription}
                                </p>
                              )} */}
                            </div>
                          ) : (
                            <div>
                              {noOfPacks} x {packSize}g every {weeks} weeks
                              {/* {addonsSelected?.length > 0 && (
                                <p>
                                  <span className="font-bold">Treats &amp; Extras</span> {addonsDescription}
                                </p>
                              )} */}
                            </div>
                          )}
                        </div>
                      </div>

                      {/* Your Delivery Details */}
                      <div className="mb-8 border-b border-primary pb-8">
                        <div className="mb-4 flex flex-row">
                          <StaticImage
                            className="mr-2 flex-shrink-0"
                            alt="2"
                            width={50}
                            objectFit="contain"
                            src="../../../images/dog-details/signup-checkout-2.svg"
                          />
                          <h2 className="mb-4 mt-4 text-xl">Next, let us know your details</h2>
                        </div>
                        <div className="mb-7 inline-flex w-full sm:w-1/2">
                          <div className="flex w-full flex-col">
                            <div className="relative flex w-full flex-shrink-0 flex-row items-center">
                              <Field
                                data-testid="firstName"
                                type="text"
                                name="firstName"
                                id="firstName"
                                className={`${checkInputState(
                                  'firstName'
                                )} peer block w-full rounded-md border border-gray-300 bg-white pt-6 text-left focus:border-[#B0CDD5] focus:ring-0 sm:mr-2`}
                                onBlur={() => {
                                  if (typeof window.gtag !== 'undefined') {
                                    window.gtag('event', 'entered_first_name', {
                                      page: 'sign_up_checkout',
                                      firstName: props.values['firstName']
                                    })
                                  }
                                }}
                              />
                              <label
                                className={`absolute transform cursor-text appearance-none duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                  props.values.firstName ? 'ml-0 -translate-y-3 scale-75' : 'ml-4'
                                }`}
                                htmlFor="firstName"
                              >
                                First name<span className="ml-1">*</span>
                              </label>
                            </div>
                            {props.errors.firstName && props.touched.firstName ? (
                              <div className="p-2 text-sm text-[#FF220C] sm:mr-2">{props.errors.firstName}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="mb-7 inline-flex w-full sm:w-1/2">
                          <div className="flex w-full flex-col">
                            <div className="relative flex w-full flex-shrink-0 flex-row items-center">
                              <Field
                                type="text"
                                name="lastName"
                                id="lastName"
                                className={`${checkInputState(
                                  'lastName'
                                )} peer block w-full rounded-md border border-gray-300 bg-white pt-6 text-left focus:border-[#B0CDD5] focus:ring-0 sm:ml-2`}
                                onBlur={() => {
                                  if (typeof window.gtag !== 'undefined') {
                                    window.gtag('event', 'entered_last_name', {
                                      page: 'sign_up_checkout',
                                      lastName: props.values['lastName']
                                    })
                                  }
                                }}
                              />
                              <label
                                className={`absolute transform cursor-text appearance-none duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 md:peer-focus:ml-2 ${
                                  props.values.lastName ? 'ml-0 -translate-y-3 scale-75 md:ml-2' : 'ml-4'
                                }`}
                                htmlFor="lastName"
                              >
                                Last name<span className="ml-1">*</span>
                              </label>
                            </div>
                            {props.errors.lastName && props.touched.lastName ? (
                              <div className="p-2 text-sm text-[#FF220C] sm:ml-2">{props.errors.lastName}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="mb-2">
                          <div>
                            <div className="relative flex flex-row items-center">
                              <Field
                                data-testid="phoneNbr"
                                id="phoneNumber"
                                type="tel"
                                autoComplete="on"
                                name="phoneNumber"
                                className={`${checkInputState(
                                  'phoneNumber'
                                )} peer block w-full rounded-md border border-gray-300 bg-white pt-6 text-left focus:border-[#B0CDD5] focus:ring-0`}
                                onBlur={() => {
                                  sendPhoneNumberAndEmail(props.values, props.errors)
                                  if (typeof window.gtag !== 'undefined') {
                                    window.gtag('event', 'entered_phone_number', {
                                      page: 'sign_up_checkout',
                                      phone_number: props.values['phoneNumber']
                                    })
                                  }

                                  handleFieldData(props.values)
                                  props.setFieldTouched('phoneNumber')
                                }}
                              />
                              {checkInputState('phoneNumber') === 'field-focus' && (
                                <span>
                                  <FontAwesomeIcon
                                    data-tip
                                    data-for="phoneNumberTooltip"
                                    icon={faQuestionCircle}
                                    className={`${
                                      checkInputState('phoneNumber') === 'field-focus' ? 'display' : 'hidden'
                                    } absolute right-2 top-1/2 -translate-y-1/2 cursor-pointer h-4 pt-1`}
                                  />
                                  <ReactTooltip
                                    id="phoneNumberTooltip"
                                    effect="solid"
                                    type="light"
                                    place="top"
                                    border={true}
                                    borderColor="#6BA2B3"
                                    className="phone-number-tooltip opacity-100 w-1/2 lg:w-1/4"
                                  >
                                    <p className="font-sans-serif mb-2 text-start text-base">
                                      We use your phone number to send delivery updates, and our Customer Delight team
                                      may call to see how you and your dog are enjoying our recipes
                                    </p>
                                  </ReactTooltip>
                                </span>
                              )}
                              <label
                                className={`absolute transform cursor-text appearance-none duration-300 peer-focus:-ml-1 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                  props.values.phoneNumber ? '-ml-1 -translate-y-3 scale-75' : 'ml-4'
                                }`}
                                htmlFor="phoneNumber"
                              >
                                Phone number<span className="ml-1">*</span>
                              </label>
                            </div>

                            {props.errors.phoneNumber && props.touched.phoneNumber ? (
                              <div className="p-2 text-sm text-[#FF220C]">{props.errors.phoneNumber}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="mb-8 grid items-center">
                          <label className="inline-flex">
                            <Field
                              id="offers2"
                              type="checkbox"
                              name="smsOptIn"
                              className="text-fourth-checkbox mt-1 h-4 w-4 rounded-[4px]"
                              onClick={(e: any) => {
                                sendPhoneNumberAndEmail(props.values, props.errors, e.target.checked)
                              }}
                            />
                            <span className="flex-wrap pl-2 text-sm font-sans">
                              I want to receive tips from your vet and tasty discounts via SMS and Calls
                            </span>
                          </label>
                        </div>
                        <div className="mb-7">
                          <div>
                            <div className="relative flex flex-row items-center">
                              <Field
                                type="email"
                                name="email"
                                id="email"
                                className={`${checkInputState(
                                  'email'
                                )} peer block w-full rounded-md border border-gray-300 bg-white pt-6 text-left focus:border-[#B0CDD5] focus:ring-0`}
                                onBlur={() => {
                                  if (typeof window.gtag !== 'undefined') {
                                    window.gtag('event', 'entered_email', {
                                      page: 'sign_up_checkout',
                                      email: props.values['email']
                                    })
                                  }
                                }}
                              />
                              <label
                                className={`absolute transform cursor-text appearance-none duration-300 peer-focus:ml-1 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                  props.values.email ? 'ml-1 -translate-y-3 scale-75' : 'ml-4'
                                }`}
                                htmlFor="email"
                              >
                                Email<span className="ml-1">*</span>
                              </label>
                            </div>

                            {props.errors.email && props.touched.email ? (
                              <div className="p-2 text-sm text-[#FF220C]">{props.errors.email}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="border-b border-primary pb-8">
                        <div className="flex flex-row">
                          <StaticImage
                            className="mr-2 flex-shrink-0"
                            alt="3"
                            width={50}
                            objectFit="contain"
                            src="../../../images/dog-details/signup-checkout-3.svg"
                          />
                          <h2 className="mb-4 mt-4 text-xl">Where the food should be delivered to?</h2>
                        </div>

                        {!openAddressInputs && showAddressFinder && (
                          <div className="mt-8 flex w-full flex-col items-start">
                            <div className="relative w-full">
                              <Field
                                type="text"
                                id="shippingAddressSearch"
                                name="shippingAddressSearch"
                                placeholder="Address *"
                                className={`${checkInputState(
                                  'shippingAddressSearch'
                                )} block w-full rounded-md border border-gray-300 bg-white p-4 text-left placeholder-base focus:border-[#B0CDD5] focus:ring-0`}
                                onBlur={() => handleFieldData(props.values)}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  props.setFieldValue('shippingAddressSearch', e.target.value)
                                  if (typeof window.gtag !== 'undefined') {
                                    const recipeItems = plan?.recipes?.map(r => {
                                      return {
                                        item_id: r.id,
                                        item_name: r.name,
                                        quantity: r.quantity,
                                        item_list_id: 'recipes',
                                        item_list_name: 'Recipes'
                                      }
                                    })

                                    const addonItems = plan?.addons?.map(a => {
                                      return {
                                        item_id: a.id,
                                        item_name: a.name,
                                        price: (a.price / 100).toFixed(2),
                                        quantity: a.quantity,
                                        item_list_id: 'addons',
                                        item_list_name: 'Addons'
                                      }
                                    })

                                    window.gtag('event', 'add_shipping_info', {
                                      currency: 'GBP',
                                      value: (currentPrices?.total / 100).toFixed(2),
                                      coupon: lastValidatedCoupon?.code,
                                      items: recipeItems.concat(addonItems)
                                    })
                                    window.gtag('event', 'entered_shipping_address_search', {
                                      page: 'sign_up_checkout'
                                    })
                                  }
                                }}
                              />
                              <FontAwesomeIcon
                                icon={faSearch}
                                className={`${
                                  props.errors.shippingAddressSearch ? 'right-8 top-[38%]' : 'right-4 top-1/2'
                                } absolute -translate-y-1/2 cursor-pointer`}
                              />
                              {props.errors.shippingAddressSearch && props.touched.shippingAddressSearch ? (
                                <div className="text-sm text-red-500">{props.errors.shippingAddressSearch}</div>
                              ) : null}
                            </div>
                            <button
                              className="inline-flex w-fit pl-px text-right text-sm hover:underline focus:underline"
                              onClick={() => setOpenAddressInputs(true)}
                            >
                              My address isn't shown
                            </button>
                          </div>
                        )}

                        <div
                          className={`${
                            formikRef.current?.values['shippingAddress1'] || openAddressInputs ? 'block' : 'hidden'
                          }`}
                        >
                          <div className="my-7">
                            <div>
                              <div className="relative flex w-full flex-row items-center">
                                <Field
                                  type="text"
                                  id="shippingAddress1"
                                  name="shippingAddress1"
                                  className={`${checkInputState(
                                    'shippingAddress1'
                                  )} peer block w-full rounded-md border border-gray-300 bg-white pt-6 text-left focus:border-[#B0CDD5] focus:ring-0`}
                                  onBlur={() => {
                                    if (typeof window.gtag !== 'undefined') {
                                      window.gtag('event', 'entered_shipping_address_1', {
                                        page: 'sign_up_checkout',
                                        shippingAddress1: props.values['shippingAddress1']
                                      })
                                    }
                                  }}
                                />
                                <label
                                  className={`absolute top-0 ml-4 inline-flex h-full transform cursor-text appearance-none items-center duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                    props.values.shippingAddress1 ? 'ml-0 -translate-y-3 scale-75' : ''
                                  }`}
                                  htmlFor="shippingAddress1"
                                >
                                  Address line 1<span className="ml-1">*</span>
                                </label>
                              </div>
                              {props.errors.shippingAddress1 && props.touched.shippingAddress1 ? (
                                <div className="p-2 text-sm text-[#FF220C]">{props.errors.shippingAddress1}</div>
                              ) : null}
                            </div>
                          </div>

                          <div className="relative mb-7">
                            <div>
                              <Field
                                type="text"
                                name="shippingAddress2"
                                id="shippingAddress2"
                                className={`peer block w-full rounded-md border border-gray-300 bg-white pt-6 text-left focus:border-[#B0CDD5] focus:ring-0 ${
                                  (props.touched.shippingAddress2 || props.values.shippingAddress2) && 'field-success'
                                }`}
                                onBlur={() => {
                                  if (typeof window.gtag !== 'undefined') {
                                    window.gtag('event', 'entered_shipping_address_2', {
                                      page: 'sign_up_checkout',
                                      shippingAddress2: props.values['shippingAddress2']
                                    })
                                  }
                                }}
                              />
                              <label
                                className={`absolute top-0 ml-4 inline-flex h-full transform cursor-text appearance-none items-center duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                  props.values.shippingAddress2 ? 'ml-0 -translate-y-3 scale-75' : ''
                                }`}
                                htmlFor="shippingAddress2"
                              >
                                Address line 2
                              </label>
                              {props.errors.shippingAddress2 && props.touched.shippingAddress2 ? (
                                <div className="p-2 text-sm text-[#FF220C]">{props.errors.shippingAddress2}</div>
                              ) : null}
                            </div>
                          </div>

                          <div className="mb-7">
                            <div>
                              <div className="relative flex w-full flex-row items-center">
                                <Field
                                  type="text"
                                  name="shippingCity"
                                  id="shippingCity"
                                  className={`${checkInputState(
                                    'shippingCity'
                                  )} peer block w-full rounded-md border border-gray-300 bg-white pt-6 text-left focus:border-[#B0CDD5] focus:ring-0`}
                                  onBlur={() => {
                                    if (typeof window.gtag !== 'undefined') {
                                      window.gtag('event', 'entered_shipping_city', {
                                        page: 'sign_up_checkout',
                                        shippingCity: props.values['shippingCity']
                                      })
                                    }
                                  }}
                                />
                                <label
                                  className={`absolute top-0 inline-flex h-full transform cursor-text appearance-none items-center duration-300 peer-focus:ml-2 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                    props.values.shippingCity ? 'ml-2 -translate-y-3 scale-75' : 'ml-4'
                                  }`}
                                  htmlFor="shippingCity"
                                >
                                  City<span className="ml-1">*</span>
                                </label>
                              </div>
                              {props.errors.shippingCity && props.touched.shippingCity ? (
                                <div className="p-2 text-sm text-[#FF220C]">{props.errors.shippingCity}</div>
                              ) : null}
                            </div>
                          </div>

                          <div className="mb-7">
                            <div>
                              <div className="relative flex w-full flex-row items-center">
                                <Field
                                  type="text"
                                  name="shippingCounty"
                                  id="shippingCounty"
                                  className={`${checkInputState(
                                    'shippingCounty'
                                  )} peer block w-full rounded-md border border-gray-300 bg-white pt-6 text-left focus:border-[#B0CDD5] focus:ring-0`}
                                  onBlur={() => {
                                    if (typeof window.gtag !== 'undefined') {
                                      window.gtag('event', 'entered_shipping_county', {
                                        page: 'sign_up_checkout',
                                        shippingCounty: props.values['shippingCounty']
                                      })
                                    }
                                  }}
                                />
                                <label
                                  className={`absolute top-0 inline-flex h-full transform cursor-text appearance-none items-center duration-300 peer-focus:ml-1 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                    props.values.shippingCounty ? 'ml-1 -translate-y-3 scale-75' : 'ml-4'
                                  }`}
                                  htmlFor="shippingCounty"
                                >
                                  County<span className="ml-1">*</span>
                                </label>
                              </div>

                              {props.errors.shippingCounty && props.touched.shippingCounty ? (
                                <div className="p-2 text-sm text-[#FF220C]">{props.errors.shippingCounty}</div>
                              ) : null}
                            </div>
                          </div>

                          <div className="mb-7">
                            <div>
                              <div className="relative flex w-full flex-row items-center">
                                <Field
                                  type="text"
                                  name="shippingPostcode"
                                  id="shippingPostcode"
                                  onBlur={(e: any) => {
                                    if (e.target.value) {
                                      dispatch(validatePostcode(e.target.value))
                                      if (typeof window.gtag !== 'undefined') {
                                        window.gtag('event', 'entered_shipping_postcode', {
                                          page: 'sign_up_checkout',
                                          shippingPostcode: e.target.value
                                        })
                                      }
                                    }
                                    props.setFieldTouched('shippingPostcode')
                                  }}
                                  className={`${checkInputState(
                                    'shippingPostcode'
                                  )} peer block w-full rounded-md border border-gray-300 bg-white pt-6 text-left focus:border-[#B0CDD5] focus:ring-0`}
                                />
                                <label
                                  className={`absolute top-0 inline-flex h-full transform cursor-text appearance-none items-center duration-300 peer-focus:ml-0.5 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                    props.values.shippingPostcode ? 'ml-0.5 -translate-y-3 scale-75' : 'ml-4'
                                  }`}
                                  htmlFor="shippingPostcode"
                                >
                                  Postcode<span className="ml-1">*</span>
                                </label>
                              </div>
                              {props.errors.shippingPostcode && props.touched.shippingPostcode ? (
                                <div className="p-2 text-sm text-[#FF220C]">{props.errors.shippingPostcode}</div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="mt-6 grid items-center">
                          <label className="inline-flex mb-3">
                            <Field
                              id="offers2"
                              type="checkbox"
                              name="mailConsentFirstParty"
                              className="text-fourth-checkbox mt-1 h-4 w-4 rounded-[4px]"
                              onClick={(e: any) => {
                                sendPhoneNumberAndEmail(props.values, props.errors, e.target.checked)
                              }}
                            />
                            <span className="flex-wrap pl-2 text-sm font-sans">
                              I'm happy to receive tips and tasty discounts from Different Dog via post
                            </span>
                          </label>
                          <label className="inline-flex mb-2">
                            <Field
                              id="offers2"
                              type="checkbox"
                              name="mailConsentThirdParty"
                              className="text-fourth-checkbox mt-1 h-4 w-4 rounded-[4px]"
                              onClick={(e: any) => {
                                sendPhoneNumberAndEmail(props.values, props.errors, e.target.checked)
                              }}
                            />
                            <span className="flex-wrap pl-2 text-sm font-sans">
                              I'm happy to receive post from other trusted brands I might like,{' '}
                              <a className="underline" target="_blank" href="/privacy-policy" rel="noreferrer">
                                learn more about our partners here
                              </a>
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="border-b border-primary py-8">
                        <div className="mb-4 flex flex-row">
                          <StaticImage
                            className="mr-2 flex-shrink-0"
                            alt="4"
                            width={50}
                            objectFit="contain"
                            src="../../../images/dog-details/signup-checkout-4.svg"
                          />
                          <h2 className="mb-4 mt-4 text-xl">When are we delivering {DogNames}'s food?</h2>
                        </div>

                        <div>
                          <div className="relative flex w-full flex-row items-center">
                            {/* @ts-ignore This library has some type conflicts with one of its dependency */}
                            <DatePicker
                              name="deliveryDate"
                              disabled={shippingDates?.length === 0}
                              placeholder="Delivery date *"
                              calendarStartDay={1}
                              dateFormat="dd/MM/yyyy"
                              includeDates={shippingDates?.map(date => {
                                return new Date(date.date)
                              })}
                              className={`${checkInputState(
                                'deliveryDate'
                              )} block w-full rounded-md border border-gray-300 bg-white p-4 text-left placeholder-base focus:border-[#B0CDD5] focus:ring-0`}
                              inputMode="none"
                              onFocus={(e: any) => {
                                if (typeof window.gtag !== 'undefined') {
                                  window.gtag('event', 'clicked_delivery_date', {
                                    page: 'sign_up_checkout'
                                  })
                                }

                                e.target.blur()
                              }}
                              dayClassName={(date: any) => {
                                const dateObj = new Date(date)
                                const validDate = shippingDates?.filter(
                                  dateFromShippingDates =>
                                    dayjs(dateFromShippingDates.date).format('DD/MM/YYYY') ===
                                    dayjs(date).format('DD/MM/YYYY')
                                )

                                if (validDate.length === 1 && dateObj.getDay() === 6) {
                                  return '!text-red-500'
                                } else if (validDate.length === 0 && dateObj.getDay() === 6) {
                                  return '!text-red-300'
                                } else {
                                  return undefined
                                }
                              }}
                              calendarContainer={({ className, children }) => (
                                <div>
                                  <CalendarContainer className={className}>
                                    <div style={{ position: 'relative' }}>{children}</div>
                                    <div className="p-2 pt-0">
                                      <span className="flex w-full font-bold">
                                        {activeDeliveryPrice === 0 && 'Free weekday delivery on first order'}
                                        {activeDeliveryPrice === 3.99 && 'Weekday delivery'}
                                      </span>
                                      <span className="flex w-full font-bold text-red-400">
                                        £4.99 - Saturday delivery
                                      </span>
                                    </div>
                                  </CalendarContainer>
                                </div>
                              )}
                              onBlur={() => {
                                handleFieldData(props.values)
                                props.setFieldTouched('deliveryDate')
                              }}
                              onSelect={(date: Date) => {
                                props.setFieldValue('deliveryDate', date)
                                if (typeof window.gtag !== 'undefined') {
                                  window.gtag('event', 'selected_delivery_date', {
                                    page: 'sign_up_checkout',
                                    date: date.toString()
                                  })
                                }
                              }}
                            />
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              className="absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer"
                            />
                          </div>
                          {props.errors.deliveryDate && props.touched.deliveryDate ? (
                            <div className="p-2 text-xs text-red-500">{props.errors.deliveryDate}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="p-4">
                      <div className="border-b border-primary">
                        <div className="mb-2 flex flex-row">
                          <StaticImage
                            className="mr-2 flex-shrink-0"
                            alt="6"
                            objectFit="contain"
                            width={50}
                            src="../../../images/dog-details/signup-discount.svg"
                          />
                          <h2 className="mb-4 mt-4 text-xl">Your tasty discount</h2>
                        </div>

                        <div className="mb-4 flex items-center justify-start">
                          <div className={'w-full'}>
                            <Coupon
                              defaultCouponCode={coupon.code}
                              couponCode={couponCode}
                              setCouponCode={setCouponCode}
                              isValid={isValidCoupon}
                              labelClass="hidden"
                              isCouponValidationLoading={isCouponValidationLoading}
                              CouponChangeGA={{
                                event: 'Click_Discount',
                                page: 'sign_up_checkout'
                              }}
                              validateFailGA={{
                                event: 'invalid_discount',
                                page: 'sign_up_checkout'
                              }}
                            />
                          </div>
                          <Helmet>{zendeskScript && zendeskScript}</Helmet>
                        </div>
                      </div>

                      {currentPrices?.maxDiscount && <p className="mb-4">Maximum discount £80. See T&amp;Cs</p>}
                      <div className="mt-8 mb-4 flex flex-row">
                        <StaticImage
                          objectFit={'contain'}
                          className="mr-2 flex-shrink-0"
                          alt="5"
                          width={50}
                          src="../../../images/dog-details/signup-checkout-5.svg"
                        />
                        <h2 className="mb-4 mt-4 text-xl">Please check your order</h2>
                      </div>
                      {((!currentPrices && !ongoingPrices) || pricesLoading || isCouponValidationLoading) && (
                        <div>
                          <div className="m-auto w-full text-center" style={{ marginTop: '5vh' }}>
                            <img className="m-auto" width="200px" src={loadingGIF} alt="Loading, please wait..." />
                          </div>
                        </div>
                      )}
                      {pricesError && (
                        <div>
                          <p>There has been an error retrieving the plan price totals.</p>
                        </div>
                      )}
                      {currentPrices &&
                        ongoingPrices &&
                        !pricesError &&
                        !isCouponValidationLoading &&
                        !pricesLoading && (
                          <>
                            <div className="mb-2 bg-white rounded-xl p-4">
                              <div className="mx-auto w-full text-center">
                                <button
                                  onClick={() => {
                                    setShowFirstBox(0)

                                    if (typeof window.gtag !== 'undefined') {
                                      window.gtag('event', 'selected_first_box', {
                                        page: 'sign_up_checkout'
                                      })
                                    }
                                  }}
                                  className={`pr-2 text-sm text-primary hover:underline focus:underline ${
                                    showFirstBox === 0 ? 'font-bold underline' : ''
                                  }`}
                                >
                                  First Box
                                </button>

                                <button
                                  data-testid="ongoing"
                                  onClick={() => {
                                    setShowFirstBox(1)

                                    if (typeof window.gtag !== 'undefined') {
                                      window.gtag('event', 'selected_ongoing_box', {
                                        page: 'sign_up_checkout'
                                      })
                                    }
                                  }}
                                  className={`pl-2 text-sm text-primary hover:underline focus:underline ${
                                    showFirstBox === 1 ? 'font-bold underline' : ''
                                  }`}
                                >
                                  Ongoing
                                </button>
                              </div>

                              {showFirstBox === 0 && (
                                <>
                                  <div className="mt-2">
                                    <div className="flex flex-col gap-2">
                                      <div className="flex justify-between text-sm">
                                        <div>{renderPackText()}</div>
                                        <div className="flex min-w-max justify-items-end">
                                          {coupon.code && (
                                            <span className="strikethrough">
                                              £{((currentPrices?.plan + currentPrices?.recipes) / 100).toFixed(2)}
                                            </span>
                                          )}
                                          <span className="pl-1 font-bold">
                                            £{planPriceDiscount > 0 ? (planPriceDiscount / 100)?.toFixed(2) : '0.00'}
                                          </span>
                                        </div>
                                      </div>
                                      {treatPageBeforeCheckout && addonsSelected.length > 0 && (
                                        <div className="flex justify-between text-sm">
                                          <div>Treats &amp; Extras</div>
                                          <div>
                                            {coupon.code && currentPrices?.addons !== totalAddonPriceDiscount && (
                                              <span className="strikethrough">
                                                £{(currentPrices?.addons / 100)?.toFixed(2)}
                                              </span>
                                            )}{' '}
                                            <span className="font-bold">
                                              {totalAddonPriceDiscount === 0
                                                ? 'FREE'
                                                : `£${(totalAddonPriceDiscount / 100)?.toFixed(2)}`}
                                            </span>
                                          </div>
                                        </div>
                                      )}

                                      <div className="flex justify-between text-sm">
                                        <div>
                                          Delivery{' '}
                                          {dayjs(formikRef.current?.values['deliveryDate'])?.day() === 6
                                            ? '(Saturday)'
                                            : ''}
                                        </div>
                                        <div>
                                          {currentPrices?.delivery > 0 && (
                                            <div>
                                              <span data-testid="saturdayDelivery" className="strikethrough">
                                                {dayjs(formikRef.current?.values['deliveryDate'])?.day() === 6
                                                  ? '£4.99'
                                                  : '£3.99'}
                                              </span>
                                              <span className="pl-1 uppercase">
                                                £{getDiscountedDeliveryPrice(currentPrices?.delivery / 100).toFixed(2)}
                                              </span>
                                            </div>
                                          )}
                                          {currentPrices?.delivery === 0 && (
                                            <div>
                                              <span className="strikethrough">
                                                {dayjs(formikRef.current?.values['deliveryDate'])?.day() === 6
                                                  ? '£4.99'
                                                  : '£3.99'}
                                              </span>
                                              <span className="pl-1 font-bold uppercase">Free</span>
                                            </div>
                                          )}
                                        </div>
                                      </div>

                                      <div
                                        className={`flex justify-center text-sm ${
                                          treatPageBeforeCheckout && addonsSelected?.length > 0 ? 'pt-1' : 'py-1'
                                        }`}
                                      >
                                        <div className="text-[#628F5B] font-bold">
                                          You are saving £{(currentPrices?.discount / 100)?.toFixed(2)} today
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <div data-testid="total" className="flex justify-between py-2 text-sm font-bold">
                                      <div className="font-bold">Total</div>
                                      <div>
                                        <span>£{(currentPrices?.total / 100)?.toFixed(2)}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="border-t border-[#D1D5DA] mt-2 md:mt-4 mb-2 font-light">
                                    <p className="text-center text-sm mt-6 mb-2">
                                      Just{' '}
                                      <span className="font-bold">
                                        £{(getDiscountedCostPerDay() / 100)?.toFixed(2)}
                                      </span>{' '}
                                      a day to change your dog's life
                                    </p>
                                  </div>
                                  {currentPrices?.maxDiscount && (
                                    <p className="my-4">Maximum discount £80. See T&amp;Cs</p>
                                  )}
                                </>
                              )}

                              {showFirstBox === 1 && (
                                <div className="mt-2">
                                  <div className="text-center">
                                    {packType === 'FULL_TRIAL' ||
                                    packType === 'PARTIAL_TRIAL' ||
                                    packType === 'fullTrial' ||
                                    packType === 'partialTrial' ? (
                                      <div>
                                        <p className="mb-2 text-xs">
                                          After your starter box, we'll automatically update your plan
                                        </p>
                                        <p className="mb-2 text-xs">
                                          from{' '}
                                          <span className="font-bold">
                                            {noOfPacks} x {packSize}g
                                          </span>{' '}
                                          to{' '}
                                          <span className="font-bold">
                                            {ongoingNoOfPacks} x {ongoingPackSize}g
                                          </span>
                                          .
                                        </p>
                                        <p className="text-xs">
                                          They will be delivered {ongoingPrices?.delivery === 0 && 'free'} every{' '}
                                          {ongoingWeeks} weeks {containsHealthPlusRecipes}{' '}
                                          <span className="font-bold">
                                            £
                                            {packType === 'FULL_TRIAL' && packs?.full?.costPerGrams
                                              ? (packs?.full?.costPerGrams / 100)?.toFixed(2)
                                              : (ongoingPrices?.costPerDay / 100)?.toFixed(2)}
                                          </span>{' '}
                                          per day
                                          {ongoingPrices?.delivery / 100 > 0 &&
                                            (dayjs(formikRef.current?.values['deliveryDate'])?.day() === 6
                                              ? ' + £4.99 delivery'
                                              : ' + £3.99 delivery')}
                                          .
                                        </p>
                                      </div>
                                    ) : (
                                      <div>
                                        <p className="text-xs">
                                          Your boxes will be delivered {ongoingPrices?.delivery === 0 && 'free'} every{' '}
                                          {ongoingWeeks} weeks {containsHealthPlusRecipes}{' '}
                                          <span className="font-bold">
                                            £
                                            {plan.costPerGrams
                                              ? (plan?.costPerGrams / 100)?.toFixed(2)
                                              : (ongoingPrices?.costPerDay / 100)?.toFixed(2)}
                                          </span>{' '}
                                          per day
                                          {ongoingPrices?.delivery / 100 > 0 &&
                                            (dayjs(formikRef.current?.values['deliveryDate'])?.day() === 6
                                              ? ' + £4.99 delivery'
                                              : ' + £3.99 delivery')}
                                          .
                                        </p>
                                      </div>
                                    )}
                                    <div className="mt-2 border-t border-gray-300 pt-2">
                                      <p className="text-xs">
                                        You can amend, pause or cancel your subscription anytime.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        )}
                      <div>
                        <div className="mb-4 flex flex-row">
                          <StaticImage
                            className="mr-2 flex-shrink-0"
                            alt="6"
                            objectFit="contain"
                            width={50}
                            src="../../../images/dog-details/signup-checkout-6.svg"
                          />
                          <h2 className="mb-4 mt-4 text-xl">Finally, your payment details</h2>
                        </div>
                        {!payEvent && (
                          <div>
                            <div>
                              {!formikRef.current?.values.firstName.includes('PHONE PAYMENT') && (
                                <CardElementCtx.Provider value={{ cardError, setCardError }}>
                                  <CardCheckout
                                    errorMessage={localErrorMessage}
                                    onFocus={() => {
                                      if (typeof ttq !== 'undefined') {
                                        ttq.track('AddPaymentInfo')
                                      }

                                      if (typeof window.gtag !== 'undefined') {
                                        const recipeItems = plan?.recipes?.map(r => {
                                          return {
                                            item_id: r.id,
                                            item_name: r.name,
                                            quantity: r.quantity,
                                            item_list_id: 'recipes',
                                            item_list_name: 'Recipes'
                                          }
                                        })

                                        const addonItems = plan?.addons?.map(a => {
                                          return {
                                            item_id: a.id,
                                            item_name: a.name,
                                            price: (a.price / 100).toFixed(2),
                                            quantity: a.quantity,
                                            item_list_id: 'addons',
                                            item_list_name: 'Addons'
                                          }
                                        })

                                        window.gtag('event', 'add_payment_info', {
                                          currency: 'GBP',
                                          value: (currentPrices?.total / 100).toFixed(2),
                                          coupon: lastValidatedCoupon.code,
                                          payment_type: 'Debit/Credit Card',
                                          items: recipeItems.concat(addonItems)
                                        })
                                      }
                                    }}
                                  />
                                </CardElementCtx.Provider>
                              )}
                            </div>

                            {paymentRequest && <div className="mt-4 text-center font-bold">OR</div>}

                            <div className="mt-4">
                              {paymentRequest && (
                                <PaymentRequestButtonElement
                                  onClick={() => {
                                    if (typeof window.gtag !== 'undefined') {
                                      const recipeItems = plan?.recipes?.map(r => {
                                        return {
                                          item_id: r.id,
                                          item_name: r.name,
                                          quantity: r.quantity,
                                          item_list_id: 'recipes',
                                          item_list_name: 'Recipes'
                                        }
                                      })

                                      const addonItems = plan?.addons?.map(a => {
                                        return {
                                          item_id: a.id,
                                          item_name: a.name,
                                          price: (a.price / 100).toFixed(2),
                                          quantity: a.quantity,
                                          item_list_id: 'addons',
                                          item_list_name: 'Addons'
                                        }
                                      })

                                      window.gtag('event', 'add_payment_info', {
                                        currency: 'GBP',
                                        value: (currentPrices?.total / 100).toFixed(2),
                                        coupon: lastValidatedCoupon.code,
                                        payment_type: paymentRequest._activeBackingLibraryName,
                                        items: recipeItems.concat(addonItems)
                                      })
                                    }

                                    // This isn't an error, but the Logger component only catches errors
                                    // https://www.npmjs.com/package/cloudwatch-front-logger
                                    console.error(`Clicked_${paymentRequest._activeBackingLibraryName} button`)
                                  }}
                                  options={{ paymentRequest }}
                                />
                              )}
                            </div>
                          </div>
                        )}

                        {payEvent && (
                          <div className="mt-4 text-center font-bold mb-4">Your mobile payment has been verified</div>
                        )}

                        <div className="flex w-full flex-col items-center justify-between sm:flex-row">
                          <div className="my-4 grid items-center">
                            <div>
                              <input
                                data-testid="isBillingAddressSame"
                                id="isBillingAddressSame"
                                name="isBillingAddressSame"
                                type="checkbox"
                                checked={isBillingAddressSame}
                                onChange={() => {
                                  setIsBillingAddressSame(prev => !prev)

                                  if (!isBillingAddressSame) {
                                    if (typeof window.gtag !== 'undefined') {
                                      window.gtag('event', 'entered_billing_address', {
                                        page: 'sign_up_checkout'
                                      })
                                    }
                                  }
                                }}
                                className="text-fourth-checkbox"
                              />
                              <span className="pl-2 text-sm">Billing and delivery address are the same</span>
                            </div>
                          </div>

                          <StaticImage
                            className="mb-4"
                            src="../../../images/poweredbystripe.svg"
                            alt="Powered by Stripe"
                            width={75}
                          />
                        </div>
                        <div className={`${!isBillingAddressSame ? 'block' : 'hidden'}`}>
                          {!openBillingAddressInputs && showBillingAddressFinder && (
                            <div className="mb-7">
                              <div>
                                <Field
                                  type="text"
                                  id="billingAddressSearch"
                                  name="billingAddressSearch"
                                  placeholder="Billing address *"
                                  className="block w-full rounded-md border border-gray-300 bg-white py-3 px-4 text-left placeholder-base focus:border-[#B0CDD5] focus:ring-0"
                                />
                                {props.errors.billingAddressSearch && props.touched.billingAddressSearch ? (
                                  <div className="text-xs text-red-500">{props.errors.billingAddressSearch}</div>
                                ) : null}
                              </div>
                              <button
                                className="w-full text-right text-sm hover:underline focus:underline"
                                onClick={() => setOpenBillingAddressInputs(true)}
                              >
                                My address isn't shown
                              </button>
                            </div>
                          )}

                          <div
                            className={`${
                              formikRef.current?.values['billingAddress1'] || openBillingAddressInputs
                                ? 'block'
                                : 'hidden'
                            }`}
                          >
                            <div className="mt-7">
                              <div className="relative inline-flex w-1/2">
                                <div className="w-full">
                                  <div className="relative flex w-full flex-shrink-0 flex-row items-center">
                                    <Field
                                      data-testid="billingFirstName"
                                      type="text"
                                      name="billingFirstName"
                                      id="billingFirstName"
                                      className="peer mr-2 block w-full rounded-md border border-gray-300 bg-white pt-6 text-left focus:border-[#B0CDD5] focus:ring-0"
                                    />
                                    <label
                                      className={`absolute transform cursor-text appearance-none duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 md:peer-focus:ml-2 ${
                                        props.values.billingFirstName ? 'ml-0 -translate-y-3 scale-75 md:ml-2' : 'ml-4'
                                      }`}
                                      htmlFor="billingFirstName"
                                    >
                                      Billing name<span className="ml-1">*</span>
                                    </label>
                                  </div>
                                  {props.errors.billingFirstName && props.touched.billingFirstName ? (
                                    <div className="p-2 text-sm text-[#FF220C] sm:mr-2">
                                      {props.errors.billingFirstName}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="relative inline-flex" style={{ width: 'calc(50% - 8px)' }}>
                                <div className="w-full">
                                  <div className="relative flex w-full flex-shrink-0 flex-row items-center">
                                    <Field
                                      data-testid="billingLastName"
                                      type="text"
                                      name="billingLastName"
                                      id="billingLastName"
                                      className="peer ml-2 block w-full rounded-md border border-gray-300 bg-white pt-6 text-left focus:border-[#B0CDD5] focus:ring-0"
                                    />
                                    <label
                                      className={`absolute top-0 inline-flex h-full transform cursor-text appearance-none items-center duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                        props.values.billingLastName ? 'ml-0 -translate-y-3 scale-75' : 'ml-4'
                                      }`}
                                      htmlFor="billingLastName"
                                    >
                                      Billing surname<span className="ml-1">*</span>
                                    </label>
                                  </div>
                                  {props.errors.billingLastName && props.touched.billingLastName ? (
                                    <div className="p-2 text-sm text-[#FF220C] sm:ml-2">
                                      {props.errors.billingLastName}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>

                            <div className="my-7">
                              <div>
                                <div className="relative flex w-full flex-row items-center">
                                  <Field
                                    data-testid="billingAddress1"
                                    type="text"
                                    id="billingAddress1"
                                    name="billingAddress1"
                                    className={`${checkInputState(
                                      'billingAddress1'
                                    )} peer block w-full rounded-md border border-gray-300 bg-white pt-6 text-left focus:border-[#B0CDD5] focus:ring-0`}
                                  />
                                  <label
                                    className={`absolute top-0 ml-4 inline-flex h-full transform cursor-text appearance-none items-center duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                      props.values.billingAddress1 ? 'ml-0 -translate-y-3 scale-75' : ''
                                    }`}
                                    htmlFor="billingAddress1"
                                  >
                                    Billing address 1<span className="ml-1">*</span>
                                  </label>
                                </div>
                                {props.errors.billingAddress1 && props.touched.billingAddress1 ? (
                                  <div className="p-2 text-sm text-[#FF220C]">{props.errors.billingAddress1}</div>
                                ) : null}
                              </div>
                            </div>

                            <div className="relative mb-7">
                              <div>
                                <Field
                                  data-testid="billingAddress2"
                                  type="text"
                                  name="billingAddress2"
                                  id="billingAddress2"
                                  className="peer block w-full rounded-md border border-gray-300 bg-white pt-6 text-left focus:border-[#B0CDD5] focus:ring-0"
                                />
                                <label
                                  className={`absolute top-0 inline-flex h-full transform cursor-text appearance-none items-center duration-300 peer-focus:-ml-1 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                    props.values.billingAddress2 ? '-ml-1 -translate-y-3 scale-75' : 'ml-4'
                                  }`}
                                  htmlFor="billingAddress2"
                                >
                                  Billing address 2
                                </label>
                                {props.errors.billingAddress2 && props.touched.billingAddress2 ? (
                                  <div className="p-2 text-sm text-[#FF220C]">{props.errors.billingAddress2}</div>
                                ) : null}
                              </div>
                            </div>

                            <div className="my-7">
                              <div>
                                <div className="relative flex w-full flex-row items-center">
                                  <Field
                                    data-testid="billingCity"
                                    type="text"
                                    id="billingCity"
                                    name="billingCity"
                                    className={`${checkInputState(
                                      'billingCity'
                                    )} peer block w-full rounded-md border border-gray-300 bg-white pt-6 text-left focus:border-[#B0CDD5] focus:ring-0`}
                                  />
                                  <label
                                    className={`absolute top-0 ml-4 inline-flex h-full transform cursor-text appearance-none items-center duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                      props.values.billingCity ? 'ml-0 -translate-y-3 scale-75' : ''
                                    }`}
                                    htmlFor="billingCity"
                                  >
                                    City<span className="ml-1">*</span>
                                  </label>
                                </div>
                                {props.errors.billingCity && props.touched.billingCity ? (
                                  <div className="p-2 text-sm text-[#FF220C]">{props.errors.billingCity}</div>
                                ) : null}
                              </div>
                            </div>

                            <div className="my-7">
                              <div>
                                <div className="relative flex w-full flex-row items-center">
                                  <Field
                                    data-testid="billingCounty"
                                    type="text"
                                    id="billingCounty"
                                    name="billingCounty"
                                    className={`${checkInputState(
                                      'billingCounty'
                                    )} peer block w-full rounded-md border border-gray-300 bg-white pt-6 text-left focus:border-[#B0CDD5] focus:ring-0`}
                                  />
                                  <label
                                    className={`absolute top-0 ml-4 inline-flex h-full transform cursor-text appearance-none items-center duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                      props.values.billingCounty ? 'ml-0 -translate-y-3 scale-75' : ''
                                    }`}
                                    htmlFor="billingCounty"
                                  >
                                    County<span className="ml-1">*</span>
                                  </label>
                                </div>
                                {props.errors.billingCounty && props.touched.billingCounty ? (
                                  <div className="p-2 text-sm text-[#FF220C]">{props.errors.billingCounty}</div>
                                ) : null}
                              </div>
                            </div>

                            <div className="my-7">
                              <div>
                                <div className="relative flex w-full flex-row items-center">
                                  <Field
                                    data-testid="billingPostcode"
                                    type="text"
                                    id="billingPostcode"
                                    name="billingPostcode"
                                    className={`${checkInputState(
                                      'billingPostcode'
                                    )} peer block w-full rounded-md border border-gray-300 bg-white pt-6 text-left focus:border-[#B0CDD5] focus:ring-0`}
                                  />
                                  <label
                                    className={`absolute top-0 ml-4 inline-flex h-full transform cursor-text appearance-none items-center duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                      props.values.billingPostcode ? 'ml-0 -translate-y-3 scale-75' : ''
                                    }`}
                                    htmlFor="billingPostcode"
                                  >
                                    Postcode<span className="ml-1">*</span>
                                  </label>
                                </div>
                                {props.errors.billingPostcode && props.touched.billingPostcode ? (
                                  <div className="p-2 text-sm text-[#FF220C]">{props.errors.billingPostcode}</div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          {/* <div className="my-4 text-center text-sm">
                              <p>You can amend, pause or cancel your subscription anytime</p>
                            </div> */}
                          {getBillingDate() && (
                            <p className="mb-4 text-center text-sm">
                              You will not be charged until <span data-testid="billingDate">{getBillingDate()}</span>
                            </p>
                          )}
                          <p className="mb-4 text-center text-sm font-bold hover:underline focus:underline">
                            <a href="/terms-and-conditions" className="text-xs" target={'_blank'} rel="noreferrer">
                              Terms and conditions
                            </a>
                          </p>
                        </div>
                      </div>

                      {/* <div className="flex flex-col items-center"> */}
                      <div className={`flex flex-col md:flex-row-reverse items-center justify-between`}>
                        {submitErrors && formErrors?.length > 0 && (
                          <div
                            id="errorModule"
                            className="absolute bg-white z-20 bottom-[6rem] sm:bottom-[6rem] md:bottom-[6rem] w-[80%] md:w-[60%] lg:w-[60%] p-4 rounded-xl border border-[#6BA2B3] flex flex-col"
                          >
                            <p className="font-sans-serif pb-2">Some of the details need your attention:</p>
                            <ul className="justify-center items-center">
                              {formErrors?.length > 0 &&
                                formErrors?.map((error: string, i: number) => (
                                  <li key={i} className="flex items-left">
                                    <StaticImage
                                      className="flex-shrink-0"
                                      alt="Cross"
                                      objectFit="contain"
                                      width={10}
                                      src="../../../images/crossBlue.svg"
                                    />
                                    <p className="justify-center items-center text-left ml-1 font-sans-serif text-sm font-bold">
                                      {error}
                                    </p>
                                  </li>
                                ))}
                            </ul>
                            <p className="font-sans-serif pt-2">
                              Please update these fields so you can confirm your order.
                            </p>
                          </div>
                        )}
                        <button
                          id="confirmOrder"
                          data-testid="confirmOrder"
                          onClick={() => {
                            getFormErrors(props)
                            setSendConsoleErrorToCloudWatch(true)
                            // Log to GA, confirm order attempt with errors
                            if (Object.keys(formikRef.current.errors).length > 0) {
                              if (typeof window.gtag !== 'undefined') {
                                window.gtag('event', 'invalid_confirm_order', {
                                  page: 'sign_up_checkout',
                                  errors: JSON.stringify(formikRef.current.errors)
                                })
                              }
                            }
                            nextClick(props.handleSubmit)
                          }}
                          disabled={loading || submitting || awaiting3ds}
                          type="submit"
                          className={`${
                            loading || submitting || awaiting3ds ? 'opacity-50' : ''
                          } btn-cta scale-animation !px-8 items-center justify-between`}
                        >
                          {loading || submitting || props.isSubmitting || awaiting3ds ? (
                            <div className="align-center flex justify-center">
                              <div className="border-white-900 mr-2 h-5 w-5 animate-spin rounded-full border-b-2"></div>
                              <div>Please wait</div>
                            </div>
                          ) : (
                            confirmBtnText
                          )}
                        </button>
                        {coupon?.newCustomerTreatOffer && !treatPageBeforeCheckout && (
                          <div className="grid grid-cols-1 rounded-3xl px-0 sm:grid-cols-1 sm:gap-x-3 md:mx-auto w-full relative my-2 md:w-3/4 md:px-8 lg:w-1/2 md:hidden">
                            <div className="md:p-4 flex flex-row items-center justify-center">
                              <StaticImage
                                objectFit={'contain'}
                                className="mr-2 flex-shrink-0"
                                alt="1"
                                width={40}
                                src="../../../images/dog-details/bone.svg"
                              />
                              <p className="text-center text-xs md:text-base font-sans">
                                Choose your
                                <span className="font-bold"> FREE Treat </span>
                                on the next page
                              </p>
                            </div>
                          </div>
                        )}
                        {treatPageBeforeCheckout && addonsSelected?.length > 0 && coupon?.newCustomerTreatOffer && (
                          <div className="grid grid-cols-1 rounded-3xl px-0 sm:grid-cols-1 sm:gap-x-3 md:mx-auto w-full relative my-2 md:w-3/4 md:px-8 lg:w-1/2 md:hidden">
                            <div className="md:p-4 flex flex-row items-center justify-center">
                              <StaticImage
                                objectFit={'contain'}
                                className="mr-2 flex-shrink-0"
                                alt="1"
                                width={40}
                                src="../../../images/dog-details/bone.svg"
                              />
                              <p className="text-center text-xs md:text-base font-sans">
                                This includes your
                                <span className="font-bold"> FREE Treat </span>
                              </p>
                            </div>
                          </div>
                        )}
                        <button
                          data-testid="back"
                          onClick={backClick}
                          type="button"
                          className={`btn-secondary pt-4 items-center justify-between`}
                        >
                          Back
                        </button>
                      </div>
                    </div>
                    {coupon?.newCustomerTreatOffer && !treatPageBeforeCheckout && (
                      <div className="md:grid grid-cols-1 rounded-3xl px-0 sm:grid-cols-1 sm:gap-x-3 mx-auto w-full relative md:w-full hidden">
                        <div className="md:p-2 flex flex-row items-center justify-center">
                          <StaticImage
                            objectFit={'contain'}
                            className="mr-2 flex-shrink-0"
                            alt="1"
                            width={40}
                            src="../../../images/dog-details/bone.svg"
                          />
                          <p className="text-center text-xs md:text-base font-sans">
                            Choose your
                            <span className="font-bold"> FREE Treat </span>
                            on the next page
                          </p>
                        </div>
                      </div>
                    )}
                    {treatPageBeforeCheckout && addonsSelected?.length > 0 && coupon?.newCustomerTreatOffer && (
                      <div className="md:grid grid-cols-1 rounded-3xl px-0 sm:grid-cols-1 sm:gap-x-3 mx-auto w-full relative md:w-full hidden">
                        <div className="md:p-2 flex flex-row items-center justify-center">
                          <StaticImage
                            objectFit={'contain'}
                            className="mr-2 flex-shrink-0"
                            alt="1"
                            width={40}
                            src="../../../images/dog-details/bone.svg"
                          />
                          <p className="text-center text-xs md:text-base font-sans">
                            This includes your
                            <span className="font-bold"> FREE Treat </span>
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="m-5 grid grid-cols-1 rounded-2xl bg-checkoutBG px-2 sm:grid-cols-1 sm:pt-4 md:mx-auto md:w-3/4 md:px-8 lg:w-1/2">
                    <div className="grid grid-cols-2 items-center justify-center gap-2 md:grid-cols-4">
                      <div className="grid grid-flow-col grid-rows-2 gap-4 pt-4 md:pt-0">
                        <StaticImage
                          className="flex-shrink-0"
                          alt="Fussy dog approved"
                          objectFit="contain"
                          width={50}
                          src="../../../images/dog-details/fussy-dog.svg"
                        />
                        <p className="mx-auto w-1/2 text-center text-xs md:w-2/3">Fussy dog approved</p>
                      </div>
                      <div className="grid grid-flow-col grid-rows-2 gap-4 pt-4 md:pt-0">
                        <StaticImage
                          className="flex-shrink-0"
                          alt="Amend, pause or cancel anytime"
                          objectFit="contain"
                          width={50}
                          src="../../../images/dog-details/pause.svg"
                        />
                        <p className="text-center text-xs">Amend, pause or cancel anytime</p>
                      </div>
                      <div className="grid grid-flow-col grid-rows-2 gap-4 pb-2 md:pb-0">
                        <StaticImage
                          className="flex-shrink-0"
                          alt="Vet support available"
                          objectFit="contain"
                          width={50}
                          src="../../../images/dog-details/vet-support.svg"
                        />
                        <p className="mx-auto w-1/2 text-center text-xs md:w-2/3">Vet support available</p>
                      </div>
                      <div className="grid grid-flow-col grid-rows-2 gap-4 pb-2 md:pb-0">
                        <StaticImage
                          className="flex-shrink-0 "
                          alt="Hand cooked in our Shropshire kitchen"
                          objectFit="contain"
                          width={50}
                          src="../../../images/dog-details/cook.svg"
                        />
                        <p className="text-center text-xs">Hand cooked in our Shropshire kitchen</p>
                      </div>
                    </div>
                  </div>
                  <FaqAccordion
                    faqs={faqs}
                    backgroundColor={'#A6C7D1'}
                    headingText={'FAQs'}
                    headingTextColor={'primary'}
                    faqQuestionColor={'primary'}
                    faqAnswerColor={'primary'}
                    headingType={HeadingType.P}
                    headingClass={HeadingClass.H3}
                    iconClass={'text-white'}
                  />
                  <div className="footer-wave-bottom-signup relative -mb-32 p-4 md:p-8">
                    <div className="mb-4 w-full text-center font-serif">
                      <div className="mx-auto mb-8 mt-8 text-base !leading-[1.3] md:text-xl">
                        <p>Still got questions?</p>
                        <p>Get in touch with our team - they could talk dogs all day long</p>
                      </div>
                      <p className="mt-8 text-base md:text-xl">
                        Call us on{' '}
                        <a className="font-bold" href="tel:+441743384562">
                          01743 384 562
                        </a>{' '}
                        or email{' '}
                        <a className="font-bold" href="mailto:feedme@differentdog.com">
                          feedme@differentdog.com
                        </a>{' '}
                      </p>
                    </div>
                    <div className="mt-10 hidden sm:block">
                      <TrustPilotMicroCombo backgroundColor="transparent" />
                    </div>
                    <div className="mt-5 -mb-8 block sm:hidden">
                      <TrustPilotMini backgroundColor="transparent" />
                    </div>
                    <div className="mx-auto mb-16 w-full md:w-4/5">
                      <TrustBoxAlternate />
                    </div>
                  </div>

                  <div className="-mb-28 flex items-center justify-center">
                    <StaticImage
                      className="my-5 flex"
                      src="../../../../images/builder/differentDog-logo.png"
                      alt="Different Dog logo"
                    />
                  </div>
                </div>
              )
            }}
          </Formik>
        </div>
      </div>
      {errorMessage && errorMessage.message !== 'Generic error' && !localErrorMessage && (
        <ErrorModal
          show={showPopup}
          setShow={setShowPopup}
          errorMessage={errorMessage?.popup?.message ?? errorMessage?.message}
          url={errorMessage?.popup?.url}
          action={errorMessage?.popup?.action}
          actionText={errorMessage?.popup?.actionText}
        />
      )}
      {localErrorMessage && (
        <ErrorModal
          show={showPopup}
          setShow={setShowPopup}
          errorMessage={localErrorMessage}
          url={errorMessage?.popup?.url}
          action={errorMessage?.popup?.action}
          actionText={errorMessage?.popup?.actionText}
        />
      )}
      {/* <FeedbackForm source={'signup'} /> */}
    </>
  )
}

export default ShippingConfirm
