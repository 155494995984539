import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

const topBreeds: { [key: string]: string }[] = [
  { '2391d364-bccd-4a4e-816c-51a3b6d4578a': 'cavapoo' },
  { '82ce1c77-d52d-4cf9-8cc1-88039e4d69a0': 'cockapoo' },
  { '7d6bbe72-7652-4fd4-89d4-6e2ce9df386c': 'cockerSpaniel' },
  { '6c6eef75-25b5-46ff-92cf-10e16c6dbbcb': 'dachshund' },
  { 'f91a51bb-34b7-4456-ae00-ecfbaf9acdf5': 'frenchBulldog' },
  { '20481671-e591-474b-85e5-daf441f7ea99': 'labrador' },
  { '94bde5a5-2ddb-47ed-ac3d-28f95f189aa5': 'stafforshireBullTerrier' }
]

const getIsTopBreed = (breed: string) => {
  const matchedBreed = topBreeds.find(breedObj => Object.keys(breedObj).includes(breed))
  return matchedBreed ? matchedBreed[breed] : null
}

// Info box images
// Displays breed specific images for top breeds on questions:
// breed, neutered, DOB and weight questions
export const renderInfoImage = (image: string, imageAlt: string, breed: any) => {
  const isTopBreed = getIsTopBreed(breed)

  switch (image) {
    case 'Name': {
      return (
        <StaticImage
          objectFit="contain"
          className="info-box-mobile-image w-full max-w-[110px] md:max-w-[118px] -mr-7"
          src={'../../../../images/dog-details/info-section/info-section-name.png'}
          alt={imageAlt}
        />
      )
    }
    case 'Gender':
      return (
        <StaticImage
          objectFit="contain"
          className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
          src={'../../../../images/dog-details/info-section/info-section-gender.png'}
          alt={imageAlt}
        />
      )
    case 'Breed': {
      switch (isTopBreed) {
        case 'cavapoo': {
          return (
            <StaticImage
              objectFit="contain"
              className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
              src={'../../../../images/dog-details/info-section/info-section-cavapoo1.png'}
              alt={imageAlt}
            />
          )
        }
        case 'cockapoo': {
          return (
            <StaticImage
              objectFit="contain"
              className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
              src={'../../../../images/dog-details/info-section/info-section-cockapoo1.png'}
              alt={imageAlt}
            />
          )
        }
        case 'cockerSpaniel': {
          return (
            <StaticImage
              objectFit="contain"
              className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
              src={'../../../../images/dog-details/info-section/info-section-cockerspaniel1.png'}
              alt={imageAlt}
            />
          )
        }
        case 'dachshund': {
          return (
            <StaticImage
              objectFit="contain"
              className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
              src={'../../../../images/dog-details/info-section/info-section-dachshund1.png'}
              alt={imageAlt}
            />
          )
        }
        case 'frenchBulldog': {
          return (
            <StaticImage
              objectFit="contain"
              className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
              src={'../../../../images/dog-details/info-section/info-section-frenchbulldog1.png'}
              alt={imageAlt}
            />
          )
        }
        case 'labrador': {
          return (
            <StaticImage
              objectFit="contain"
              className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
              src={'../../../../images/dog-details/info-section/info-section-labrador1.png'}
              alt={imageAlt}
            />
          )
        }
        case 'stafforshireBullTerrier': {
          return (
            <StaticImage
              objectFit="contain"
              className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
              src={'../../../../images/dog-details/info-section/info-section-staffordshirebullterrier1.png'}
              alt={imageAlt}
            />
          )
        }
        default:
          return (
            <StaticImage
              objectFit="contain"
              className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
              src={'../../../../images/dog-details/info-section/info-section-breed.png'}
              alt={imageAlt}
            />
          )
      }
    }
    case 'Neutered':
      switch (isTopBreed) {
        case 'cavapoo': {
          return (
            <StaticImage
              objectFit="contain"
              className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
              src={'../../../../images/dog-details/info-section/info-section-cavapoo2.png'}
              alt={imageAlt}
            />
          )
        }
        case 'cockapoo': {
          return (
            <StaticImage
              objectFit="contain"
              className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
              src={'../../../../images/dog-details/info-section/info-section-cockapoo2.png'}
              alt={imageAlt}
            />
          )
        }
        case 'cockerSpaniel': {
          return (
            <StaticImage
              objectFit="contain"
              className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
              src={'../../../../images/dog-details/info-section/info-section-cockerspaniel2.png'}
              alt={imageAlt}
            />
          )
        }
        case 'dachshund': {
          return (
            <StaticImage
              objectFit="contain"
              className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
              src={'../../../../images/dog-details/info-section/info-section-dachshund2.png'}
              alt={imageAlt}
            />
          )
        }
        case 'frenchBulldog': {
          return (
            <StaticImage
              objectFit="contain"
              className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
              src={'../../../../images/dog-details/info-section/info-section-frenchbulldog2.png'}
              alt={imageAlt}
            />
          )
        }
        case 'labrador': {
          return (
            <StaticImage
              objectFit="contain"
              className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
              src={'../../../../images/dog-details/info-section/info-section-labrador2.png'}
              alt={imageAlt}
            />
          )
        }
        case 'stafforshireBullTerrier': {
          return (
            <StaticImage
              objectFit="contain"
              className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
              src={'../../../../images/dog-details/info-section/info-section-staffordshirebullterrier2.png'}
              alt={imageAlt}
            />
          )
        }
        default:
          return (
            <StaticImage
              objectFit="contain"
              className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
              src={'../../../../images/dog-details/info-section/info-section-neutered.png'}
              alt={imageAlt}
            />
          )
      }

    case 'DOB':
      switch (isTopBreed) {
        case 'cavapoo': {
          return (
            <StaticImage
              objectFit="contain"
              className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
              src={'../../../../images/dog-details/info-section/info-section-cavapoo3.png'}
              alt={imageAlt}
            />
          )
        }
        case 'cockapoo': {
          return (
            <StaticImage
              objectFit="contain"
              className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
              src={'../../../../images/dog-details/info-section/info-section-cockapoo3.png'}
              alt={imageAlt}
            />
          )
        }
        case 'cockerSpaniel': {
          return (
            <StaticImage
              objectFit="contain"
              className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
              src={'../../../../images/dog-details/info-section/info-section-cockerspaniel3.png'}
              alt={imageAlt}
            />
          )
        }
        case 'dachshund': {
          return (
            <StaticImage
              objectFit="contain"
              className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
              src={'../../../../images/dog-details/info-section/info-section-dachshund3.png'}
              alt={imageAlt}
            />
          )
        }
        case 'frenchBulldog': {
          return (
            <StaticImage
              objectFit="contain"
              className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
              src={'../../../../images/dog-details/info-section/info-section-frenchbulldog3.png'}
              alt={imageAlt}
            />
          )
        }
        case 'labrador': {
          return (
            <StaticImage
              objectFit="contain"
              className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
              src={'../../../../images/dog-details/info-section/info-section-labrador3.png'}
              alt={imageAlt}
            />
          )
        }
        case 'stafforshireBullTerrier': {
          return (
            <StaticImage
              objectFit="contain"
              className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
              src={'../../../../images/dog-details/info-section/info-section-staffordshirebullterrier3.png'}
              alt={imageAlt}
            />
          )
        }
        default:
          return (
            <StaticImage
              objectFit="contain"
              className="w-full max-w-[110px] md:max-w-[118px] -mr-7 "
              src={'../../../../images/dog-details/info-section/info-section-dob.png'}
              alt={imageAlt}
            />
          )
      }

    case 'Weight':
      switch (isTopBreed) {
        case 'cavapoo': {
          return (
            <StaticImage
              objectFit="contain"
              className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
              src={'../../../../images/dog-details/info-section/info-section-cavapoo4.png'}
              alt={imageAlt}
            />
          )
        }
        case 'cockapoo': {
          return (
            <StaticImage
              objectFit="contain"
              className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
              src={'../../../../images/dog-details/info-section/info-section-cockapoo4.png'}
              alt={imageAlt}
            />
          )
        }
        case 'cockerSpaniel': {
          return (
            <StaticImage
              objectFit="contain"
              className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
              src={'../../../../images/dog-details/info-section/info-section-cockerspaniel4.png'}
              alt={imageAlt}
            />
          )
        }
        case 'dachshund': {
          return (
            <StaticImage
              objectFit="contain"
              className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
              src={'../../../../images/dog-details/info-section/info-section-dachshund4.png'}
              alt={imageAlt}
            />
          )
        }
        case 'frenchBulldog': {
          return (
            <StaticImage
              objectFit="contain"
              className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
              src={'../../../../images/dog-details/info-section/info-section-frenchbulldog4.png'}
              alt={imageAlt}
            />
          )
        }
        case 'labrador': {
          return (
            <StaticImage
              objectFit="contain"
              className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
              src={'../../../../images/dog-details/info-section/info-section-labrador4.png'}
              alt={imageAlt}
            />
          )
        }
        case 'stafforshireBullTerrier': {
          return (
            <StaticImage
              objectFit="contain"
              className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
              src={'../../../../images/dog-details/info-section/info-section-staffordshirebullterrier4.png'}
              alt={imageAlt}
            />
          )
        }
        default:
          return (
            <StaticImage
              objectFit="contain"
              className="w-full max-w-[110px] md:max-w-[118px] -mr-7 "
              src={'../../../../images/dog-details/info-section/info-section-weight.png'}
              alt={imageAlt}
            />
          )
      }

    case 'Physique':
      return (
        <StaticImage
          objectFit="contain"
          className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
          src={'../../../../images/dog-details/info-section/info-section-physique.png'}
          alt={imageAlt}
        />
      )
    case 'Exercise':
      return (
        <StaticImage
          objectFit="contain"
          className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
          src={'../../../../images/dog-details/info-section/info-section-exercise.png'}
          alt={imageAlt}
        />
      )
    case 'MedicalConditionOrAllergies':
      return (
        <StaticImage
          objectFit="contain"
          className="w-full max-w-[110px] md:max-w-[118px] -mr-7"
          src={'../../../../images/dog-details/info-section/info-section-medical.png'}
          alt={imageAlt}
        />
      )
  }
}
