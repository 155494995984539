import { navigate, useLocation } from '@reach/router'
import React, { createContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Auth } from 'aws-amplify'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { DatePicker } from 'react-formik-ui'

import { Field, Formik } from 'formik'
import * as yup from 'yup'

import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react'
import { AddressFinder } from '@ideal-postcodes/address-finder'
import { StaticImage } from 'gatsby-plugin-image'
import { parse, stringify } from 'query-string'
import { CalendarContainer } from 'react-datepicker'

import {
  CardNumberElement,
  Elements,
  PaymentRequestButtonElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import { HeadingClass, HeadingType } from 'enums'
import CardCheckoutAlternate from './CardCheckoutAlternative'
import { Faqs } from './Faqs'

import TrustPilotMicroCombo from 'components/ui-builder/trustpilot/micro-combo'
import TrustPilotMini from 'components/ui-builder/trustpilot/mini'
import { getRemainingTimeUntilMsTimestamp } from 'components/ui/countdown-timer/CountdownTimerUtil'
import Coupon from 'components/ui/coupon/Coupon'
import CouponAlternative from 'components/ui/coupon/CouponAlternative'
import FaqAccordion from 'components/ui/faq-accordion'
import ErrorModal from 'components/ui/modal/ErrorModal'
import StickyFooter from 'components/ui/sticky-footer/StickyFooter'
import TrustBoxAlternate from 'components/ui/trustpilot/TrustBoxAlternate'
import { isAppleDevice, isMobileDevice } from 'utils/get-browser-device'

// import { stringFormatter } from 'utils/string-formatter'
import { createTemporaryPassword } from 'utils/temporary-password'
import { ShippingSchema } from 'utils/yup-validation-schema'

import { RootState } from 'state'
import { updateCurrCouponCode, updateCustomerEmail } from 'state/actions/dogAction'
import { resetSingleHashes, setHashChanged, setHashLoaded } from 'state/actions/hashAction'
import { updateKey } from 'state/actions/keyAction'
import { updateCouponCode, updateParentEmail } from 'state/actions/parentAction'
import { getProducts } from 'state/actions/productsAction'
import {
  createShippingConfirm,
  resetErrorMessageForShipping,
  resetLoadingStatus,
  validatePostcode
} from 'state/actions/shippingAction'
import { checkHash } from 'state/apis/hashApi'
import { PlanType } from 'state/types/planType'
import Api from '../../../state/apis/api'

import { topTenBreeds } from 'components/ui/reviews/ReviewsData'
import { Reviews } from 'components/ui/reviews/review'
import BulletPointImage from '../../../images/BulletPoint.svg'
import Clock from '../../../images/checkout/Clock.svg'
import Celebrate from '../../../images/checkout/celebrate.svg'
import Lock from '../../../images/checkout/lock.svg'
import DDIcon from '../../../images/dd-icon.png'
import EmptyBowl from '../../../images/dog-details/empty-bowl.svg'
import loadingGIF from '../../../images/loadingspinner.gif'

// import SelectDeliveryDateModal from 'components/ui/modal/SelectDeliveryDateModal'

dayjs.extend(advancedFormat)

export const CardElementCtxAlternative = createContext<any>({})

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PK || '')

const ShippingConfirmAlternative = () => {
  /**
   * Wrap component with Stripe Elements component
   */
  return (
    <Elements stripe={stripePromise}>
      <ShippingConfirmAlternativeStripe />
    </Elements>
  )
}
const ShippingConfirmAlternativeStripe = () => {
  const {
    dogInfo,
    summary,
    coupon,
    isValidCoupon,
    currCouponCode,
    lastValidatedCoupon,
    lastValidatedCoupons,
    loading: isCouponValidationLoading,
    pack
  }: {
    dogInfo: DogType
    summary: any
    coupon: CouponType
    isValidCoupon: boolean
    currCouponCode: string
    lastValidatedCoupon: CouponType
    lastValidatedCoupons: CouponsType[]
    loading: boolean
    pack: PackType | undefined
  } = useSelector((state: RootState) => state.dog)
  const { pack: packs, packNew } = useSelector((state: RootState) => state.dog)
  const { planHash, couponHash, productListHash, hashLoaded } = useSelector((state: RootState) => state.hash)
  const { spoofMode, spoofedUserId } = useSelector((state: RootState) => state.ui)
  const { key } = useSelector((state: RootState) => state.key)
  const { parentDetails } = useSelector((state: RootState) => state.parent)
  const {
    recipesSelected,
    addonsSelected,
    plan,
    noOfPacks,
    weeks,
    packSize,
    fixedOrCustomPlan,
    packType
  }: PlanType | any = useSelector((state: RootState) => state.plan)
  const products = useSelector((state: RootState) => state.products)
  // Get recipe list from product store, else use old code of plan.recipes
  // TODO: Can be updated once product store in place
  const { recipeList }: { recipeList?: RecipeAddonType[] } = products || { recipeList: plan.recipes }
  const { addonList }: { addonList?: RecipeAddonType[] } = products || { addonList: plan.addons }
  const { orderSummary, shipping, errorMessage, loading, setupIntent, changed, error } = useSelector(
    (state: RootState) => state.shippingConfirm
  )
  const [showPopup, setShowPopup] = useState(false)
  const [localErrorMessage, setErrorMessage] = useState<string | undefined>()
  const [couponCode, setCouponCode] = useState<string>('')
  const [openAddressInputs, setOpenAddressInputs] = useState(false)
  const [openBillingAddressInputs, setOpenBillingAddressInputs] = useState(false)
  const [showAddressFinder, setShowAddressFinder] = useState(true)
  const [showBillingAddressFinder, setShowBillingAddressFinder] = useState(true)
  const [shippingDates, setShippingDates] = useState([])
  const [isBillingAddressSame, setIsBillingAddressSame] = useState(true)
  const [activeDeliveryPrice, _setActiveDeliveryPrice] = useState(0)
  // const [showSelectDeliveryDateModal, setShowSelectDeliveryDateModal] = useState(false)
  const [deliveryDateSelected, setDeliveryDateSelected] = useState()
  const [deliveryDateText, setDeliveryDateText] = useState('')
  const [nextDayDeliveryAvailable, setNextDayDeliveryAvailable] = useState(true)
  const [showNextDayDeliveryBanner, setShowNextDayDeliveryBanner] = useState(false)

  const [totalAddonPriceDiscount, setTotalAddonPriceDiscount] = useState(0)
  const [planPriceDiscount, setPlanPriceDiscount] = useState(0)
  const [ongoingWeeks, setOngoingWeeks] = useState(0)
  const [ongoingPackSize, setOngoingPackSize] = useState(0)
  const [ongoingNoOfPacks, setOngoingNoOfPacks] = useState(0)

  const [sendConsoleErrorToCloudWatch, setSendConsoleErrorToCloudWatch] = useState(false)
  const [intent, setIntent] = useState(null)
  const [paymentRequest, setPaymentRequest] = useState<any>(null)
  const [payEvent, setPayEvent] = useState(null)
  const [awaiting3ds, setAwaiting3ds] = useState(false)
  const [pricesLoading, setPricesLoading] = useState(false)
  const [currentPrices, setCurrentPrices] = useState(null)
  const [ongoingPrices, setOngoingPrices] = useState(null)
  const [pricesError, setPricesError] = useState(false)
  const [pricingData, setPricingData] = useState<any>(null)

  const [submitting, setSubmitting] = useState(false)
  const [cardError, setCardError] = useState<any>()
  const [confirmBtnText, setConfirmBtnText] = useState('Start My Subscription')
  const [containsHealthPlusRecipes, setContainsHealthPlusRecipes] = useState('for')
  const [browser, setBrowser] = useState('')

  const [faqs, setFaqs] = useState(Faqs)
  const [showFirstBox, setShowFirstBox] = useState(0)

  const [packTypeForCheckout, setPackTypeForCheckout] = useState('')
  const [submitErrors, setSubmitErrors] = useState(false)
  const [formErrors, setFormErrors] = useState<string[]>([])
  const [isMobileView, setIsMobileView] = useState(false)
  const [billingDateToday, setBillingDateToday] = useState(false)
  const [total3DS, setTotal3DS] = useState(0) // 3ds amount shown on in popup
  const [total3DSTemp, setTotal3DSTemp] = useState(0) // temporary 3ds amount state
  // states for express checkout
  const [requestPayerName, setRequestPayerName] = useState(false)
  const [requestPayerPhone, setRequestPayerPhone] = useState(false)
  const [requestPayerShipping, setRequestPayerShipping] = useState(false)
  const [expressButtonVisible, setExpressButtonVisible] = useState(true)
  // const [stopHashCheck, setStopHashCheck] = useState(false)

  // const timestampNovember2024 =
  //   process.env.GATSBY_USER_BRANCH === 'development'
  //     ? 1732030200
  //     : process.env.GATSBY_USER_BRANCH === 'staging'
  //       ? 1731601332
  //       : 1732098600 // ! TODO: Update to relevant timestamp

  // const preTimestampNovember2024 = timestampNovember2024 - 10 * 60 // 10 mins before timestamp
  // const postTimestampNovember2024 = timestampNovember2024 + 10 * 60 // 10 mins after timestamp

  const [showReviews, setShowReviews] = useState(false)
  const [reviewsType, setReviewsType] = useState('')

  const dispatch = useDispatch()
  const elements = useElements()
  const location = useLocation()
  var formikRef = useRef<any>()
  const stripe = useStripe()

  const treatPageBeforeCheckout = useFeatureIsOn('treat-page-before-checkout')
  const checkoutV3 = useFeatureIsOn('checkout-version-three')
  const expressCheckoutAB = useFeatureIsOn('express-checkout')
  const checkoutReviews = useFeatureIsOn('checkout-reviews')
  const emptyBowlVsMoneyBack = useFeatureValue('empty-bowl-vs-money-back', 0)

  const numberOfDogs = dogInfo?.dogs?.filter(
    (d: any) => d['name'] !== '' && d['name'] !== undefined && d['name'] !== null
  ).length

  // let currentTime = dayjs()
  // const preTime = dayjs.unix(preTimestampNovember2024)
  // const postTime = dayjs.unix(postTimestampNovember2024)

  // TODO: remove this once currentTime after timestampNovember2024
  // useEffect(() => {
  //   const checkTime = () => {
  //     currentTime = dayjs()
  //     if (currentTime.isAfter(preTime) && currentTime.isBefore(postTime)) {
  //       // Hash check at timestamp
  //       if (planHash && couponHash && productListHash) {
  //         postCheckHash()
  //       }
  //       dispatch(setHashLoaded(true))
  //     }
  //   }

  //   const interval = setInterval(() => {
  //     console.error('Start interval')
  //     if (!payEvent && !stopHashCheck) {
  //       console.error('Check time')
  //       checkTime()
  //     }
  //     if (currentTime.isAfter(postTime)) {
  //       clearInterval(interval)
  //     }
  //   }, 30000)

  //   return () => clearInterval(interval)
  // }, [payEvent, stopHashCheck])

  // const postCheckHash = async () => {
  //   if (!stopHashCheck) {
  //     try {
  //       dispatch(setHashLoaded(false))
  //       await checkHash({
  //         planLevel: pack.planLevel,
  //         hashValuePlan: planHash || packNew.hash,
  //         hashValueCoupon: couponHash,
  //         hashValueProducts: productListHash,
  //         visitorId: dogInfo?.visitorId
  //       })
  //       dispatch(setHashLoaded(true))
  //     } catch (e: any) {
  //       dispatch(setHashLoaded(false))
  //       // This only handles the plan hash at the moment
  //       // This can be extended out to handle other hash updates
  //       if (!e?.hashValuePlan) {
  //         // only reset the plan hash
  //         await dispatch(resetSingleHashes('planHash'))
  //         dispatch(updateKey(`plan`))
  //         navigate(`/signup/plan`)
  //       }
  //     }
  //   }
  // }

  // Check initial values of form
  useEffect(() => {
    console.error(`FormInitialValues - ${JSON.stringify(formikRef?.current?.values)}`)
    console.error(`FormInitialValuesParentAndShipping - ${JSON.stringify(parentDetails)}, ${JSON.stringify(shipping)}`)
  }, [])

  useEffect(() => {
    if (currentPrices && currentPrices?.total > 0) {
      setTotal3DSTemp(currentPrices.total)
    }
  }, [currentPrices])

  useEffect(() => {
    // GB logic
    if (billingDateToday) {
      setTotal3DS(total3DSTemp)
      // Include in experiment if they have today billing,
      // This will be activation metric
      if (typeof window.gtag !== 'undefined') {
        window.gtag('event', 'payment_request_today', {
          page: 'sign_up_checkout_new',
          amount: total3DS
        })
      }
    } else {
      // Set 3DS amount back to zero if billing date not today
      setTotal3DS(0)
    }
  }, [total3DSTemp, billingDateToday])

  const {
    customer: { marketingOptIn }
  } = dogInfo
  const redZoneDays = 4
  const DogList: Array<string> = []
  dogInfo.dogs.forEach((data: any) => {
    if (data.name) {
      DogList.push(data.name)
    }
  })
  const DogNames = DogList.join(', ').replace(/,(?!.*,)/gim, ' and')

  useEffect(() => {
    // if (!products)
    dispatch(getProducts())
  }, [])

  useEffect(() => {
    if (window.innerWidth < 1024) {
      setIsMobileView(true)
    }
    setBrowser(isAppleDevice())
  }, [])

  useEffect(() => {
    // Logic to decide which reviews to show
    // Order: fussy, top breed, allergy, generic
    if (dogInfo?.dogs) {
      const firstDog = dogInfo?.dogs[0]
      if (firstDog.fussy !== 'LOW') {
        setReviewsType('fussy')
      } else if (firstDog.breed && topTenBreeds[firstDog.breed]) {
        setReviewsType(topTenBreeds[firstDog.breed])
      } else if (firstDog.medicalConditionOrAllergies === true) {
        setReviewsType('allergy')
      } else {
        setReviewsType('generic')
      }
      setShowReviews(true)
    }
  }, [dogInfo])

  useEffect(() => {
    // Mobile
    //  - One dog: Complete Dog's Purchase
    //  - One dog char length > 9 || !One dog: Complete Purchase
    // Desktop
    //  - One dog: Start Dog's Subscription
    //  - !One dog: Start My Subscription

    const windowWidth = window.innerWidth
    const validDogs = dogInfo.dogs.filter(dog => dog.name && dog.name.trim() !== '')
    let dogName = ''

    if (validDogs.length === 1) {
      const singleDogName = validDogs[0].name
      dogName = singleDogName.endsWith('s') ? `${singleDogName}' ` : `${singleDogName}'s `
    }

    let buttonText = `Start ${dogName}Subscription`

    if (windowWidth < 400) {
      if (dogName.length >= 9) dogName = ''
      buttonText = `Complete ${dogName}Purchase`
    }
    setConfirmBtnText(buttonText)
  }, [dogInfo?.dogs])

  // Next day timer
  const timer = new Date()
  const userTimestampCutoff = dayjs(timer).hour(11).minute(25).second(0).millisecond(0)
  const getRemainingTime = (countdown: any) => {
    return getRemainingTimeUntilMsTimestamp(true, countdown)
  }

  const defaultRemainingTime = getRemainingTime(userTimestampCutoff) || {
    seconds: '00',
    minutes: '00'
  }
  const [remainingTime, setRemainingTime] = useState(defaultRemainingTime)

  useEffect(() => {
    if (nextDayDeliveryAvailable) {
      // Countdown
      const intervalId = setInterval(() => {
        updateRemainingTime(userTimestampCutoff)
      }, 2000)
      return () => clearInterval(intervalId)
    }
  }, [nextDayDeliveryAvailable])

  useEffect(() => {
    if (remainingTime && checkoutV3) {
      setNextDayDeliveryAvailable(
        remainingTime.seconds === null || remainingTime.minutes === null || remainingTime.hours === null ? false : true
      )
    }
  }, [remainingTime])

  const updateRemainingTime = (countdown: any) => {
    setRemainingTime(getRemainingTimeUntilMsTimestamp(true, countdown))
  }

  useEffect(() => {
    if (checkoutV3) {
      if (remainingTime.seconds) {
        setShowNextDayDeliveryBanner(true)
      } else {
        setShowNextDayDeliveryBanner(false)
      }
    }
  }, [remainingTime])

  useEffect(() => {
    const handleClick = (event: any) => {
      const errorModule = document.getElementById('errorModule')
      // Scroll to first error
      errorModule?.addEventListener('click', function () {
        if (Object.keys(formikRef?.current?.errors)[0].startsWith('shipping')) {
          const firstErrorElement = document.getElementById('shippingAddress1')
          firstErrorElement?.scrollIntoView({ block: 'center' })
        } else {
          const firstErrorElement = document.getElementById(Object.keys(formikRef?.current?.errors)[0])
          firstErrorElement?.scrollIntoView({ block: 'center' })
        }
      })
      if (event.target.id !== 'confirmOrder') {
        setSubmitErrors(false)
      }
    }
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])

  useEffect(() => {
    recipesSelected.forEach((s: any) => {
      const recipeData = plan.recipes?.filter((r: any) => r.id === s.id)
      const newRecipeData = recipeList?.filter((r: any) => r.itemId === s.id)
      if (recipeData[0]?.range === 'health' || newRecipeData[0]?.range === 'health') {
        setContainsHealthPlusRecipes('from')
      }
    })
  }, [])

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'

    window.scrollTo(0, 0)
  }, [key])

  useEffect(() => {
    if (
      packType === 'PARTIAL_TRIAL' ||
      packType === 'PARTIAL' ||
      packType === 'partialTrial' ||
      packType === 'partial'
    ) {
      setPackTypeForCheckout('partial')
    }
    if (packType === 'FULL_TRIAL' || packType === 'FULL' || packType === 'fullTrial' || packType === 'full') {
      setPackTypeForCheckout('full')
    }
  }, [])

  useEffect(() => {
    Api.get('wwwREST', '/v1/public/signup/calendar', {})
      .then(({ shippingDates }) => {
        setShippingDates(shippingDates)
        if (!formikRef?.current?.values?.deliveryDate) {
          const defaultDate = shippingDates.find((d: any) => d.price === 0).date
          formikRef.current.setFieldValue('deliveryDate', defaultDate)
          setDeliveryDateSelected(defaultDate)
        } else {
          setDeliveryDateSelected(formikRef?.current?.values?.deliveryDate)
        }
      })
      .catch((err: any) => {
        console.error(JSON.stringify(err, null, 2))
      })
  }, [])

  useEffect(() => {
    if (deliveryDateSelected) {
      setDeliveryDateText(dayjs(deliveryDateSelected).format('dddd, Do MMMM YYYY'))
      formikRef.current.setFieldValue([`deliveryDate`], deliveryDateSelected)
    }
  }, [deliveryDateSelected])

  useEffect(() => {
    if (
      (formikRef?.current?.values?.shippingAddress1,
      formikRef?.current?.values?.shippingCity,
      formikRef?.current?.values?.shippingCounty,
      formikRef?.current?.values?.shippingPostcode)
    ) {
      setOpenAddressInputs(false)
      setOpenAddressInputs(true)
    }
  }, [formikRef?.current?.values?.shippingAddress1, formikRef?.current?.values?.shippingPostcode])

  useEffect(() => {
    // Add ongoing prices and feeding guide to FAQS
    if (
      ongoingNoOfPacks &&
      ongoingPackSize &&
      ongoingWeeks &&
      ongoingPrices &&
      packType &&
      packs &&
      noOfPacks &&
      packSize
    ) {
      const faqList = Faqs.filter(
        q => q.question !== 'How much will my ongoing box cost?' && q.question !== 'How much food should I feed my dog?'
      )
      const ongoingPriceQuestion = {
        question: 'How much will my ongoing box cost?',
        answer: `After your trial box, we'll automatically update your plan from ${noOfPacks} x ${packSize}g to ${ongoingNoOfPacks} x ${ongoingPackSize}g.
            We will deliver your meals every
            ${ongoingWeeks} weeks costing just £${
              packType === 'FULL_TRIAL' && packs?.full?.costPerGrams
                ? (packs?.full?.costPerGrams / 100)?.toFixed(2)
                : (ongoingPrices?.costPerDay / 100)?.toFixed(2)
            } per day ${
              ongoingPrices?.delivery / 100 > 0 && dayjs(formikRef.current?.values['deliveryDate'])?.day() === 6
                ? ' + £4.99 delivery'
                : ' + £3.99 delivery'
            }`
      }
      let planType = ''
      // TODO: update this plan section when product Ids added
      if (packType === 'FULL_TRIAL' || packType === 'FULL') {
        planType = 'full plan'
      }
      if (packType === 'PARTIAL_TRIAL' || packType === 'PARTIAL') {
        planType = 'partial plan'
      }

      const feedingGuideQuestion = {
        question: `How much food should I feed my ${numberOfDogs > 1 ? 'dogs' : 'dog'}?`,
        answer: `On a ${planType} of Different Dog we recommend ${
          numberOfDogs > 1 ? `${getMutlipleDogsGramsPerDay()}` : `${packs.gramsPerDay}g a day`
        } which is ${getPercentageOfPack(packs, packSize)}.
        Once opened, packs stay fresh for 5 days. One pack should last you about ${getPacksPerDay(packs, packSize)}.`
      }
      faqList.unshift(ongoingPriceQuestion, feedingGuideQuestion)
      setFaqs(faqList)
    }
  }, [ongoingNoOfPacks, ongoingPackSize, ongoingWeeks, ongoingPrices, packType, packs, noOfPacks, packSize])

  useEffect(() => {
    if (typeof AddressFinder.setup === 'undefined') {
      setOpenAddressInputs(true)
    }
    try {
      AddressFinder.setup({
        inputField: document.getElementById('shippingAddressSearch'),
        apiKey: process.env.GATSBY_IDEAL_POSTCODES,
        injectStyle: true,
        onAddressRetrieved: ({ line_1, line_2, post_town, county, postcode }) => {
          console.error(
            `onAddressRetrievedShipping - ${JSON.stringify({ line_1, line_2, post_town, county, postcode })}`
          )
          formikRef.current.setFieldValue([`shippingAddress1`], line_1)
          formikRef.current.setFieldValue([`shippingAddress2`], line_2)
          formikRef.current.setFieldValue([`shippingCity`], post_town)
          formikRef.current.setFieldValue([`shippingPostcode`], postcode)
          formikRef.current.setFieldValue([`shippingCounty`], county)
          setOpenAddressInputs(true)
          setShowAddressFinder(false)
          dispatch(validatePostcode(postcode, dogInfo?.visitorId))
        },
        onFailedCheck: () => {
          setOpenAddressInputs(true)
        },
        onSearchError: () => {
          setOpenAddressInputs(true)
        }
      })

      AddressFinder.setup({
        inputField: document.getElementById('billingAddressSearch'),
        apiKey: process.env.GATSBY_IDEAL_POSTCODES,
        injectStyle: true,
        onAddressRetrieved: ({ line_1, line_2, post_town, county, postcode }) => {
          console.error(
            `onAddressRetrievedBilling - ${JSON.stringify({ line_1, line_2, post_town, county, postcode })}`
          )
          formikRef.current.setFieldValue([`billingAddress1`], line_1)
          formikRef.current.setFieldValue([`billingAddress2`], line_2)
          formikRef.current.setFieldValue([`billingCity`], post_town)
          formikRef.current.setFieldValue([`billingPostcode`], postcode)
          formikRef.current.setFieldValue([`billingCounty`], county)
          setShowBillingAddressFinder(false)
          setOpenBillingAddressInputs(true)
        },
        onFailedCheck: () => {
          setOpenBillingAddressInputs(true)
        },
        onSearchError: () => {
          setOpenBillingAddressInputs(true)
        }
      })
    } catch (e: any) {
      console.log(e, 'Setup error for Address Finder')
    }
  }, [])

  useEffect(() => {
    if (!packSize || !weeks || !fixedOrCustomPlan) {
      return
    }
    const planObj = {
      id: packSize + 'g-' + weeks + 'w',
      type: fixedOrCustomPlan,
      grams: packSize
    }

    if (packType !== 'CUSTOM') {
      planObj.level = packs.planLevel

      if (packType !== 'CUSTOM') {
        planObj.size = packType
      }
    }

    // Call estimates API and populate state for checkout totals
    if (
      lastValidatedCoupon &&
      recipesSelected &&
      addonsSelected &&
      hashLoaded &&
      formikRef?.current?.values?.deliveryDate
    ) {
      const url = spoofMode ? '/v1/public/signup/estimate?userId=' + spoofedUserId : '/v1/public/signup/estimate'
      setPricesLoading(true)
      Api.post('wwwREST', url, {
        body: {
          coupon: lastValidatedCoupon.code,
          recipes: recipesSelected,
          addons: addonsSelected,
          plan: planObj,
          deliveryDate: dayjs(formikRef?.current?.values?.deliveryDate).format('YYYY-MM-DD'),
          visitorId: dogInfo.visitorId
        }
      })
        .then(async ({ current, ongoing }) => {
          setPricingData({ current: current, ongoing: ongoing })
          setCurrentPrices(current)
          setOngoingPrices(ongoing)
          setTotalAddonPriceDiscount(calculateTotalAddonPriceDiscount(current.addons))
          setPlanPriceDiscount(calculatePlanDiscount(current.plan + current.recipes))
          setPricesError(false)
          await dispatch(setHashLoaded(false))
          setPricesLoading(false)
        })
        .catch(async (err: any) => {
          console.error(JSON.stringify(err, null, 2))
          setPricesError(true)
          await dispatch(setHashLoaded(false))
          setPricesLoading(false)
        })
    }
  }, [lastValidatedCoupon, hashLoaded, formikRef?.current?.values?.deliveryDate])

  useEffect(() => {
    if (sendConsoleErrorToCloudWatch && Object.keys(formikRef.current.errors).length > 0) {
      console.error(JSON.stringify(formikRef.current.errors))
    }
    setSendConsoleErrorToCloudWatch(false)
  }, [sendConsoleErrorToCloudWatch])

  useEffect(() => {
    const expressButton = document.getElementById('topExpressButton')
    if (expressButton) {
      const buttonRect = expressButton.getBoundingClientRect()
      const isFullyInView = buttonRect.top >= 0
      if (isFullyInView) {
        setExpressButtonVisible(true)
      } else {
        setExpressButtonVisible(false)
      }
    }
    const isButtonInView = () => {
      if (expressButton) {
        const buttonRect = expressButton.getBoundingClientRect()
        const isButtonInView = buttonRect.bottom > 120 && buttonRect.top < window.innerHeight
        if (isButtonInView) {
          setExpressButtonVisible(true)
        } else {
          setExpressButtonVisible(false)
        }
      }
    }
    document.addEventListener('scroll', isButtonInView)
    return () => {
      document.removeEventListener('scroll', isButtonInView)
    }
  }, [])

  const handleFieldsForPayment = event => {
    try {
      if (!event || typeof event !== 'object') return

      const { payerName, payerPhone, shippingAddress } = event
      console.error(
        `handleFieldsForPaymentParams - ${JSON.stringify(payerName)}, ${JSON.stringify(payerPhone)}, ${JSON.stringify(shippingAddress)}`
      )
      const { values, setFieldValue } = formikRef?.current || {}

      const sanitizeString = str => (str && typeof str === 'string' ? str.replace(/["'\/\\]+/g, '') : '')

      const setEmptyField = (field, value) => {
        if (!values?.[field] && value) setFieldValue(field, value)
      }

      // think the issue is here, its overridding the name here
      if (payerName && typeof payerName === 'string') {
        console.error(`handleNameFromPayment - ${JSON.stringify(payerName)}`)
        const nameParts = payerName.trim().split(/\s+/)
        if (nameParts.length > 1) {
          setEmptyField('firstName', nameParts[0])
          setEmptyField('lastName', nameParts.slice(1).join(' '))
        } else {
          setEmptyField('firstName', payerName)
        }
      }

      setEmptyField('phoneNumber', payerPhone?.replace(/\D+/g, ''))

      if (shippingAddress && typeof shippingAddress === 'object') {
        if (shippingAddress?.addressLine?.length)
          setEmptyField('shippingAddress1', sanitizeString(shippingAddress?.addressLine[0]))
        if (shippingAddress?.addressLine?.length > 1)
          setEmptyField('shippingAddress2', sanitizeString(shippingAddress?.addressLine[1]))
        setEmptyField('shippingCity', sanitizeString(shippingAddress?.city))
        setEmptyField('shippingCounty', sanitizeString(shippingAddress?.region || shippingAddress?.country))
        setEmptyField('shippingPostcode', sanitizeString(shippingAddress?.postalCode))
      }
    } catch (err) {
      console.error('handleFieldsForPayment', err)
    }
  }

  const handlePaymentMethodReceived = async (event: any) => {
    ////////////////////////////////////////////////////
    try {
      dispatch(setHashLoaded(false))
      await checkHash({
        planLevel: pack.planLevel,
        hashValuePlan: planHash || packNew.hash,
        hashValueCoupon: couponHash,
        hashValueProducts: productListHash,
        visitorId: dogInfo?.visitorId
      })
      dispatch(setHashLoaded(true))
    } catch (e: any) {
      dispatch(setHashLoaded(false))
      // This only handles the plan hash at the moment
      // This can be extended out to handle other hash updates
      if (!e?.hashValuePlan) {
        // only reset the plan hash
        await dispatch(resetSingleHashes('planHash'))
        await dispatch(setHashChanged(true))
        dispatch(updateKey(`plan`))
        navigate(`/signup/plan`)
        return
      }
    }
    ////////////////////////////////////////////////////

    console.error(`handlePaymentMethodReceived - ${JSON.stringify(event)}`)
    handleFieldsForPayment(event)
    let data = handleFieldData(formikRef.current.values, event)
    // Update data with latest coupon code
    data.coupon = lastValidatedCoupon.code
    // Submit to check for other validation errors, validateForm doesn't seem to work here
    formikRef.current.handleSubmit()
    // getFormErrors(formikRef.current)

    // console.log({ data, formikRef })

    if (Object.keys(formikRef.current.errors).length === 0) {
      console.error(`setPayEventFormikErrors: ${JSON.stringify(formikRef.current.errors)}`)
      const deliveryDate = dayjs(formikRef.current.values.deliveryDate).format('YYYY-MM-DD')
      const planObj = {
        id: packSize + 'g-' + weeks + 'w',
        type: fixedOrCustomPlan,
        grams: packSize
      }

      if (packType !== 'CUSTOM') {
        planObj.level = pack.planLevel

        if (packType !== 'CUSTOM') {
          planObj.size = packType
        }
      }

      data = { ...data, plan: planObj, deliveryDate, visitorId: dogInfo.visitorId }
      data = { ...data, payment: event.paymentMethod }
      console.error(`setPayEvent1: ${JSON.stringify(event)}`)
      setPayEvent(event)
      if (typeof window.gtag !== 'undefined') {
        window.gtag('event', 'pay_event_recived', {
          page: 'sign_up_checkout_new'
        })
      }
    } else {
      console.error(`setPayEventFormikRef: ${JSON.stringify(formikRef)}`)
      // Will trigger for mobile payments with validation issues, we hold on to the token if it exists
      if (event?.paymentMethod) {
        console.error(`setPayEvent2: ${JSON.stringify(event)}`)
        setPayEvent(event)
        // setIntent(event.paymentMethod)
      } else {
        console.error(`setPayEvent3: ${JSON.stringify(event)}`)
        setPayEvent(null) // Clear pay event due to errors
      }
      // Return success to close the popup, even if failed state
      event.complete('success')
    }
  }

  // Payment useEffects
  /**
   * Load the Apple Pay/Gpay buttons if available
   */
  useEffect(() => {
    if (stripe) {
      console.error(
        `createPaymentRequestTriggered - ${JSON.stringify(total3DS, null, 2)}, ${JSON.stringify(requestPayerName, null, 2)}, ${JSON.stringify(requestPayerPhone, null, 2)}, ${JSON.stringify(requestPayerShipping, null, 2)}`
      )
      createPaymentRequest()
    }
  }, [stripe, total3DS, requestPayerName, requestPayerPhone, requestPayerShipping])

  // Gtag for GPay/ApplePay express checkout load
  useEffect(() => {
    if (stripe) {
      if (typeof window.gtag !== 'undefined') {
        window.gtag('event', 'payment_request_button_enabled', {
          page: 'sign_up_checkout_new'
        })
      }
      if (typeof window.gtag !== 'undefined' && expressCheckoutAB) {
        window.gtag('event', 'express_checkout_load', {
          page: 'sign_up_checkout_new'
        })
      }
    }
  }, [stripe])

  useEffect(() => {
    console.error(
      `formikValuesUpdatedBegin - awaiting3ds - ${JSON.stringify(awaiting3ds, null, 2)}, submitting - ${JSON.stringify(submitting, null, 2)}, loading - ${JSON.stringify(loading, null, 2)}, formikRef current values - ${JSON.stringify(formikRef?.current?.values, null, 2)}`
    )
    if (awaiting3ds || submitting || loading) {
      console.error(
        `returnSubmitting - awaiting3ds - ${JSON.stringify(awaiting3ds, null, 2)}, submitting - ${JSON.stringify(submitting, null, 2)}, loading - ${JSON.stringify(loading, null, 2)}, formikRef current values - ${JSON.stringify(formikRef?.current?.values, null, 2)}`
      )
      return
    }
    console.error(
      `formikValuesUpdateContinued - awaiting3ds - ${JSON.stringify(awaiting3ds, null, 2)}, submitting - ${JSON.stringify(submitting, null, 2)}, loading - ${JSON.stringify(loading, null, 2)}, formikRef current values - ${JSON.stringify(formikRef?.current?.values, null, 2)}`
    )
    const requestName =
      formikRef?.current?.values?.firstName === '' || formikRef?.current?.values?.lastName === '' ? true : false
    setRequestPayerName(requestName)
    const requestPhone = !formikRef?.current?.values?.phoneNumber ? true : false
    setRequestPayerPhone(requestPhone)

    const requestShipping =
      !formikRef?.current?.values?.shippingAddress1 ||
      !formikRef?.current?.values?.shippingCity ||
      !formikRef?.current?.values?.shippingCounty ||
      !formikRef?.current?.values?.shippingPostcode
        ? true
        : false
    setRequestPayerShipping(requestShipping)
    console.error(
      `formikValuesUpdateEnded - awaiting3ds - ${JSON.stringify(awaiting3ds, null, 2)}, submitting - ${JSON.stringify(submitting, null, 2)}, loading - ${JSON.stringify(loading, null, 2)}, formikRef current values - ${JSON.stringify(formikRef?.current?.values, null, 2)}`
    )
  }, [formikRef?.current?.values])

  useEffect(() => {
    if (loading || submitting || awaiting3ds) {
      const confirmButton = document.getElementById('confirmOrder')
      confirmButton?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [loading, submitting, awaiting3ds])

  const createPaymentRequest = () => {
    setPaymentRequest(null)

    const params = {
      country: 'GB',
      currency: 'gbp',
      total: {
        label: 'Different Dog',
        amount: total3DS
      },
      requestPayerName,
      requestPayerEmail: false,
      requestPayerPhone,
      requestShipping: requestPayerShipping,
      shippingOptions: [
        {
          id: 'standard',
          label: 'Standard Shipping',
          detail: 'Shipped via DPD',
          amount: total3DS
        }
      ]
    }
    console.error(`createPaymentRequest - ${JSON.stringify(params, null, 2)}`)
    const pr = stripe.paymentRequest(params)

    pr.canMakePayment().then(res => {
      console.error(`pr.canMakePaymentRes - ${JSON.stringify(res, null, 2)}`)

      if (res?.applePay || res?.googlePay) {
        // Trigger Gtag if mobile wallet enabled
        console.error('Mobile Wallet Enabled')
        if (typeof window.gtag !== 'undefined') {
          window.gtag('event', 'express_checkout_payment_request_button_load_three', {
            page: 'sign_up_checkout_new'
          })
        }
      }
      if (res) {
        // TODO: Remove, looks like this is not working
        if (typeof window.gtag !== 'undefined') {
          window.gtag('event', 'express_checkout_payment_request_button_load_two', {
            page: 'sign_up_checkout_new'
          })
        }
        pr.on('paymentmethod', handlePaymentMethodReceived)
        setPaymentRequest(pr)
        console.error(`pr - ${JSON.stringify(pr, null, 2)}`)
        // TODO: Remove, looks like this is not working
        if (typeof window.gtag !== 'undefined') {
          window.gtag('event', 'express_checkout_payment_request_button_load', {
            page: 'sign_up_checkout_new'
          })
        }
      }
    })
  }

  useEffect(() => {
    if (changed) {
      setSubmitting(true)
      if (typeof window.gtag !== 'undefined') {
        window.gtag('event', 'checkout_submitting', {
          page: 'sign_up_checkout_new'
        })
      }

      if (orderSummary && orderSummary.subscriptionId) {
        if (typeof window.gtag !== 'undefined') {
          window.gtag('event', 'purchase', {
            transaction_id: orderSummary?.subscriptionId,
            value: parseFloat((currentPrices?.total / 100)?.toFixed(2)),
            planLevel: packs?.planLevel,
            shipping: currentPrices?.delivery,
            currency: 'GBP',
            coupon: lastValidatedCoupon?.code,
            items: recipesSelected.concat(addonsSelected)
          })
          if (process.env.GATSBY_USER_BRANCH === 'production')
            window.gtag('event', 'conversion', {
              send_to: 'AW-627026958/3d7WCPrb_K4YEI7Y_qoC',
              value: parseFloat((currentPrices?.total / 100)?.toFixed(2)),
              currency: 'GBP',
              transaction_id: orderSummary?.subscriptionId
            })
          console.error('gtag purchase')
        }

        if (typeof fbq !== 'undefined') {
          function getFbcFbp() {
            let fbc = '',
              fbp = ''
            try {
              const cookies = document.cookie.split(';')
              for (const cookie of cookies) {
                const [name, value] = cookie.trim().split('=')
                if (name === '_fbc') fbc = value
                if (name === '_fbp') fbp = value
              }
              return { fbc, fbp }
            } catch (_err) {
              return { fbc, fbp }
            }
          }
          const { fbc, fbp } = getFbcFbp()

          fbq('init', '274341553658613', {
            external_id: orderSummary?.customerId,
            subscription_id: orderSummary?.subscriptionId,
            fbc,
            fbp
          })
          fbq(
            'track',
            'Purchase',
            { currency: 'GBP', value: parseFloat((currentPrices?.total / 100)?.toFixed(2)) },
            { eventID: orderSummary.subscriptionId }
          )
          console.error('fbq purchase')
        }

        if (typeof ttq !== 'undefined') {
          ttq.identify({
            email: shipping.customer.email,
            phone_number: shipping.customer.telephone
          })
          ttq.track(
            'CompletePayment',
            {
              content_id: orderSummary.subscriptionId,
              currency: 'GBP',
              value: parseFloat((currentPrices?.total / 100)?.toFixed(2))
            },
            {
              event_id: orderSummary?.subscriptionId
            }
          )
        }
      }

      if (typeof window?.uetq !== 'undefined') {
        window.uetq = window.uetq || []
        window.uetq.push('set', {
          pid: {
            em: shipping.customer.email,
            ph: shipping.customer.telephone
          }
        })
        window.uetq.push('event', 'subscribe', {
          revenue_value: parseFloat((currentPrices?.total / 100)?.toFixed(2)),
          currency: 'GBP'
        })
      }

      const signInAndRedirect = async () => {
        // If  Apple Pay/Gpay completed
        // close the modal and redirect
        if (typeof payEvent !== 'undefined' && payEvent) {
          payEvent.complete('success')
        }

        try {
          const _signin = await Auth.signIn(shipping.customer.email, shipping.customer.password)
          if (typeof window.gtag !== 'undefined') {
            window.gtag('event', 'checkoutComplete', {
              page: 'sign_up_checkout_new'
            })
          }
          if (treatPageBeforeCheckout) {
            dispatch(updateKey('thankyou', pricingData))
          } else {
            dispatch(updateKey('add-treats', pricingData))
          }
          setSubmitting(false)
        } catch (err) {
          console.error('error signing in', JSON.stringify(err, null, 2))
          setSubmitting(false)
        }
      }
      signInAndRedirect()
    }
  }, [changed])

  useEffect(() => {
    if (typeof window.gtag !== 'undefined' && currentPrices) {
      const recipeItems = plan?.recipes?.map((r: any) => {
        return {
          item_id: r.id,
          item_name: r.name,
          quantity: r.quantity,
          item_list_id: 'recipes',
          item_list_name: 'Recipes'
        }
      })

      const addonItems = plan?.addons?.map((a: any) => {
        return {
          item_id: a.id,
          item_name: a.name,
          price: (a.price / 100).toFixed(2),
          quantity: a.quantity,
          item_list_id: 'addons',
          item_list_name: 'Addons'
        }
      })

      window.gtag('event', 'update_checkout', {
        currency: 'GBP',
        value: (currentPrices?.total / 100).toFixed(2),
        coupon: lastValidatedCoupon?.code,
        items: recipeItems.concat(addonItems)
      })
    }
  }, [currentPrices])

  useEffect(() => {
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'begin_checkout', {
        page: 'sign_up_checkout_new'
      })
    }
  }, [])

  useEffect(() => {
    console.error(`errorMessage: ${JSON.stringify(errorMessage)}`)
    if (errorMessage || errorMessage?.popup) {
      // setErrorMessage(errorMessage?.popup?.message)
      setShowPopup(true)
      if (typeof window.gtag !== 'undefined') {
        window.gtag('event', 'confirm_order_error', {
          page: 'sign_up_checkout_new',
          errors: errorMessage?.popup?.message
        })
      }

      // If theres an error with validation
      // close the apple pay/gpay modal (has to be done with success)
      if (typeof payEvent !== 'undefined' && payEvent) {
        console.error(`setPayEvent4: ${JSON.stringify(payEvent)}`)
        setPayEvent(null) // Clear pay event due to errors
        payEvent.complete('success')
      }

      setAwaiting3ds(false)
      console.error(`errorMessage: ${JSON.stringify(errorMessage)}`)
    }
  }, [errorMessage])

  useEffect(() => {
    if (error && !errorMessage) {
      setAwaiting3ds(false)
    }
  }, [error])

  useEffect(() => {
    if (errorMessage?.message === 'Generic error') {
      setErrorMessage('Please try again, or contact Customer Delight on 01743 384 562.')
      setAwaiting3ds(false)
      setShowPopup(true)
    }
  }, [errorMessage])

  useEffect(() => {
    console.error(
      `confirmCardSetupIntent - ${JSON.stringify(setupIntent)}, confirmCardSetupPayEvent - ${JSON.stringify(payEvent)}`
    )
    const confirmCardSetup = async () => {
      const response = await stripe?.confirmCardSetup(setupIntent.client_secret)
      console.error(`confirmCardSetupResponse - ${JSON.stringify(response)}`)
      if (typeof window.gtag !== 'undefined') {
        window.gtag('event', 'checkout3DSpopupShow', {
          page: 'sign_up_checkout_new'
        })
      }
      if (response?.error) {
        let error =
          'There has been a problem adding your card. Please try again, or contact Customer Delight on 01743 384 562.'
        if (response?.error?.setup_intent?.last_setup_error?.message)
          error = `${response.error.setup_intent.last_setup_error.message} Please try again, or contact Customer Delight on 01743 384 562.`
        setErrorMessage(error)
        setAwaiting3ds(false)
        setShowPopup(true)
        if (typeof window.gtag !== 'undefined') {
          window.gtag('event', 'checkout_3DS_fail', {
            page: 'sign_up_checkout_new'
          })
        }
        // This isn't an error, but the Logger component only catches errors
        // https://www.npmjs.com/package/cloudwatch-front-logger
        console.error(`Problem adding card - ${JSON.stringify(response)}`)
      }

      if (response?.setupIntent) {
        console.error(`confirmCardSetup3DSSucceed - ${JSON.stringify(response)}`)
        if (typeof window.gtag !== 'undefined') {
          window.gtag('event', 'checkout_3DS_succeed', {
            page: 'sign_up_checkout_new'
          })
        }
        setIntent(response?.setupIntent)
        console.error(`setIntentPayEvent: ${JSON.stringify(payEvent)}`)
        if (!payEvent || response?.setupIntent?.status === 'succeeded') {
          setPayEvent(null) // Not 100% sure if needed, maybe above we can strip out !payEvent completely?
          console.error(`3DS confirmed`)
          formikRef.current.handleSubmit()
        }
      }
    }

    if (typeof setupIntent !== 'undefined') {
      confirmCardSetup()
    }
  }, [setupIntent])

  //Dispatch useEffects
  useEffect(() => {
    dispatch(resetLoadingStatus())
    dispatch(resetErrorMessageForShipping())
  }, [localErrorMessage])

  useEffect(() => {
    setOngoingWeeks(getOngoingWeeks())
    setOngoingPackSize(getOngoingPackSize())
    setOngoingNoOfPacks(getOngoingNoOfPacks())
  }, [lastValidatedCoupon, packTypeForCheckout])

  useEffect(() => {
    if (currCouponCode) setCouponCode(currCouponCode)
    else setCouponCode(coupon.code)
  }, [coupon, currCouponCode])

  useEffect(() => {
    if (lastValidatedCoupon || formikRef?.current?.values?.deliveryDate) {
      dispatch(setHashLoaded(true))
    }
  }, [lastValidatedCoupon, formikRef?.current?.values?.deliveryDate])

  useEffect(() => {
    if (key !== 'checkout') {
      navigate(`/signup/${key}/${location.search && '?' + stringify(parse(location.search))}`)
    }
  }, [key])

  useEffect(() => {
    if (typeof ttq !== 'undefined') {
      ttq.track('InitiateCheckout')
    }
  }, [])

  const nextClick = (call: any) => {
    call()
  }

  const getMutlipleDogsGramsPerDay = () => {
    let multipleDogsString: any = []
    {
      summary?.map((dog: any) => {
        multipleDogsString.push(`${dog.gramsPerDay}g a day for ${dog.name}`)
      })
    }
    return multipleDogsString.join(' and ')
  }

  const getPercentageOfPack = (packs: any, packSize: number) => {
    const percentage = Math.round(((packs.gramsPerDay / packSize) * 100) / 5) * 5
    if (percentage === 100) {
      return 'a whole pack'
    }
    return `${Math.round(((packs.gramsPerDay / packSize) * 100) / 5) * 5}% of a pack`
  }

  const getPacksPerDay = (packs: any, packSize: number) => {
    let percentageOfPack = 0
    percentageOfPack = Math.round(((packs.gramsPerDay / packSize) * 100) / 5) * 5
    return `${Math.floor(100 / percentageOfPack / 0.5) * 0.5} ${
      Math.floor(100 / percentageOfPack / 0.5) * 0.5 <= 1 ? 'day' : 'days'
    }`
  }

  const getOngoingWeeks = () => {
    let ongoingWeeks: any = 0

    if (packTypeForCheckout === 'partial') {
      ongoingWeeks = packs?.partial.weeks
    } else if (packTypeForCheckout === 'full') {
      ongoingWeeks = packs?.full.weeks
    }

    return ongoingWeeks
  }

  const getOngoingPackSize = () => {
    let ongoingPackSize: any = 0

    if (packTypeForCheckout === 'partial') {
      ongoingPackSize = packs?.partial.packSize
    } else if (packTypeForCheckout === 'full') {
      ongoingPackSize = packs?.full.packSize
    }

    return ongoingPackSize
  }

  const getOngoingNoOfPacks = () => {
    let ongoingNoOfPacks: any = 0

    if (packTypeForCheckout === 'partial') {
      ongoingNoOfPacks = packs?.partial.numberOfPacks
    } else if (packTypeForCheckout === 'full') {
      ongoingNoOfPacks = packs?.full.numberOfPacks
    }

    return ongoingNoOfPacks
  }

  const calculateTotalAddonPriceDiscount = (totalAddonPrice: number) => {
    let total = 0

    if (totalAddonPrice > 0 && lastValidatedCoupon.code) {
      if (lastValidatedCoupon.type === 'percentage') {
        total = totalAddonPrice - (lastValidatedCoupon.amount / 100) * totalAddonPrice
      } else {
        total = totalAddonPrice
      }
    }

    return total
  }

  const calculatePlanDiscount = (planPrice: number) => {
    let total = 0

    if (lastValidatedCoupon.code) {
      if (lastValidatedCoupon.type === 'percentage') {
        total = planPrice - (lastValidatedCoupon.amount / 100) * planPrice
      } else {
        total = planPrice - lastValidatedCoupon.amount
      }
    }

    return total
  }

  const getDiscountedCostPerDay = () => {
    if (plan.costPerGrams) {
      return getDiscountedDeliveryPrice(plan.costPerGrams)
    }
    if (planPriceDiscount < 0) {
      return 0
    }
    if (planPriceDiscount && weeks) {
      return planPriceDiscount / (weeks * 7)
    }

    return currentPrices?.costPerDay
  }

  const getDiscountedDeliveryPrice = (price: number) => {
    let total = 0

    if (price > 0 && lastValidatedCoupon.code) {
      if (lastValidatedCoupon.type === 'percentage') {
        total = price - (lastValidatedCoupon.amount / 100) * price
      } else {
        total = price
      }
    }

    return total
  }

  const getBillingDate = () => {
    if (formikRef.current && formikRef.current.values.deliveryDate) {
      const date = dayjs(formikRef.current.values.deliveryDate).subtract(redZoneDays, 'day')
      date.isBefore(dayjs()) ? setBillingDateToday(true) : setBillingDateToday(false)
      return date.isBefore(dayjs()) ? '' : date.format('DD-MM-YYYY')
    }
    return ''
  }

  const getFormErrors = (props: any) => {
    setFormErrors([])
    let displayErrors: string[] = []
    // if (cardError) {
    //   displayErrors.push(cardError)
    // }
    if (props.errors) {
      setSubmitErrors(true)
      for (const error of Object.keys(props.errors)) {
        if (error === 'phoneNumber') {
          displayErrors.push('Phone number')
        } else if (error === 'firstName') {
          displayErrors.push('First name')
        } else if (error === 'lastName') {
          displayErrors.push('Last name')
        } else if (error === 'deliveryDate') {
          displayErrors.push('Delivery date')
        } else {
          displayErrors.push(error.charAt(0).toUpperCase() + error.slice(1))
        }
      }
      // handle all address errors to show one address error
      const addressFields = [
        'ShippingAddressSearch',
        'ShippingAddress1',
        'ShippingCity',
        'ShippingCounty',
        'ShippingPostcode'
      ]
      const containsAddressField = addressFields.some(field => displayErrors.includes(field))
      if (containsAddressField) {
        const filteredErrors = displayErrors.filter(error => !addressFields.includes(error))
        filteredErrors.push('Address')
        displayErrors = filteredErrors
        setOpenAddressInputs(true)
      }
      // handle all billing address errors to show one address error
      const billingAddressFields = [
        'BillingFirstName',
        'BillingLastName',
        'BillingAddress1',
        'BillingCity',
        'BillingCounty',
        'BillingPostcode'
      ]
      const containsBillingAddressField = billingAddressFields.some(field => displayErrors.includes(field))
      if (containsBillingAddressField) {
        const filteredErrors = displayErrors.filter(error => !billingAddressFields.includes(error))
        if (!isBillingAddressSame) {
          filteredErrors.push('Billing details')
        }
        displayErrors = filteredErrors
      }
    }
    if (typeof window.gtag !== 'undefined' && displayErrors) {
      window.gtag('event', 'checkout_form_errors', {
        page: 'sign_up_checkout_new',
        errors: JSON.stringify(displayErrors)
      })
    }
    setFormErrors(displayErrors)
  }

  const calcDogAge = (dob: string) => {
    const birthdate = new Date(dob)
    const today = new Date()
    let year = today.getFullYear() - birthdate.getFullYear() - (today.getMonth() < birthdate.getMonth())
    let month = today.getMonth() - birthdate.getMonth()
    if (year < 0) year = ''
    if (month < 0) month = month + 12
    return `${year === 1 ? year + ' year ' : ''}${year > 1 ? year + ' years ' : ''} ${
      month > 0 && year ? ' and ' : ''
    }${month === 1 ? month + ' month' : ''}${month > 1 ? month + ' months' : ''}`
  }

  const renderPackText = () => {
    if (
      packType === 'FULL_TRIAL' ||
      packType === 'PARTIAL_TRIAL' ||
      packType === 'fullTrial' ||
      packType === 'partialTrial'
    ) {
      return (
        <>
          <span className="font-bold">Starter Box </span>
          {window.innerWidth < 500 && <br />}
          <span>
            ({noOfPacks} x {packSize}g pots)
          </span>
        </>
      )
    } else {
      return (
        <>
          <span className="font-bold">{packType.charAt(0).toUpperCase() + packType.slice(1).toLowerCase()} Plan </span>
          {window.innerWidth < 400 && <br />}
          <span>
            ({noOfPacks} x {packSize}g pots)
          </span>
        </>
      )
    }
  }

  const recipesList = recipeList?.map((r: any) => {
    const matched = recipesSelected.map((rs: any) => {
      if (rs.id === r.itemId) {
        return (
          <div className="flex flex-row items-center mb-2 text-sm">
            <img className="mr-2" width={16} src={BulletPointImage} alt={'Logo'} />
            <p className="text-sm">{rs.quantity + ' x ' + r.name}</p>
          </div>
        )
      }
    })
    return matched
  })

  const treatsList = addonList?.map((a: any) => {
    const matched = addonsSelected.map((ts: any) => {
      if (ts.id === a.itemId) {
        return (
          <div className="flex flex-row items-center mb-2 text-sm">
            <img className="mr-2" width={16} src={BulletPointImage} alt={'Logo'} />
            <p className="text-sm">{ts.quantity + ' x ' + a.name}</p>
          </div>
        )
      }
    })
    return matched
  })

  const sendPhoneNumberAndEmail = (values: any, errors: any, smsOptIn = false) => {
    if (!errors.phoneNumber && values.email && values.phoneNumber) {
      Api.post('wwwREST', '/v1/public/signup/phone', {
        body: {
          email: values.email,
          telephone: values.phoneNumber,
          smsOptIn,
          marketingCookieConsent: window?.Cookiebot?.consent?.marketing,
          visitorId: dogInfo.visitorId
        }
      }).catch((err: any) => {
        console.error(JSON.stringify(err, null, 2))
      })
    }
  }

  const handleFieldData = (values: any, event?: any) => {
    const selectedAddOn = addonsSelected.map((item: any) => {
      return { id: item.id, quantity: item.quantity }
    })

    const selectedRecipes = recipesSelected.map((item: any) => {
      return { id: item.id, quantity: item.quantity }
    })

    function getFbcFbp() {
      let fbc = '',
        fbp = ''
      try {
        const cookies = document.cookie.split(';')
        for (const cookie of cookies) {
          const [name, value] = cookie.trim().split('=')
          if (name === '_fbc') fbc = value
          if (name === '_fbp') fbp = value
        }
        return { fbc, fbp }
      } catch (_err) {
        return { fbc, fbp }
      }
    }
    const { fbc, fbp } = getFbcFbp()
    // Logging here as values duplicate surname
    console.error(
      `handleFieldDataNamesAndValues - ${JSON.stringify(values.firstName)}, ${JSON.stringify(values.lastName)}, ${JSON.stringify(values)}`
    )
    const data: any = {
      customer: {
        name: values.firstName,
        surname: values.lastName,
        email: values.email.trim(),
        marketingOptIn: parentDetails.customer.marketingOptIn || marketingOptIn,
        telephone: values.phoneNumber,
        smsOptIn: values.smsOptIn,
        mailConsentFirstParty: values.mailConsentFirstParty,
        mailConsentThirdParty: values.mailConsentThirdParty,
        password: createTemporaryPassword(12),
        total: currentPrices?.total?.toFixed(2)
      },
      address: {
        shipping: {
          address1: values.shippingAddress1,
          address2: values.shippingAddress2,
          city: values.shippingCity,
          county: values.shippingCounty,
          postcode: values.shippingPostcode
        },
        billing: {
          name: isBillingAddressSame ? values.firstName.replace(/( ?)PHONE PAYMENT/g, '') : values.billingFirstName,
          surname: isBillingAddressSame ? values.lastName : values.billingLastName,
          address1: isBillingAddressSame ? values.shippingAddress1 : values.billingAddress1,
          address2: isBillingAddressSame ? values.shippingAddress2 : values.billingAddress2,
          city: isBillingAddressSame ? values.shippingCity : values.billingCity,
          county: isBillingAddressSame ? values.shippingCounty : values.billingCounty,
          postcode: isBillingAddressSame ? values.shippingPostcode : values.billingPostcode
        }
      },
      coupon: lastValidatedCoupon.code,
      notes: values.deliveryInstructions,
      recipes: selectedRecipes,
      addons: selectedAddOn,
      dogs: dogInfo.dogs.filter((d: any) => d.name),
      fb: {
        fbc,
        fbp
      }
    }
    console.error(`handleFieldData - ${JSON.stringify(payEvent)}, ${JSON.stringify(intent)}, ${JSON.stringify(data)}`)

    //This should fix the double submit (state of payEvent not updated when this runs, now uses event direct from function call)
    if (!event) {
      if (!payEvent) {
        dispatch(createShippingConfirm(data, false))
        // setStopHashCheck(true)
      }
    }
    return data
  }

  const renderSubscriptionSummaryDesktop = () => {
    return (
      <div className="flex flex-col w-full h-full">
        <div className="p-8 pb-4 w-full mx-auto bg-white h-full rounded-3xl">
          <div className="flex flex-col">
            <h2 className="text-left text-base md:text-2xl font-sans font-semibold">Subscription summary</h2>
            <div className="flex flex-col py-1">
              <p className="font-bold mb-1 text-base">Our chefs will be cooking for</p>
              {dogInfo.dogs.map(dog => {
                return (
                  dog.name && (
                    <div className="flex flex-row">
                      <div className="flex justify-center items-center pr-2">
                        {' '}
                        <img width={14} src={DDIcon} alt={'Different Dog logo'} />
                      </div>
                      <p className="text-sm font-sans flex items-center" key={dog.name}>
                        {dog.name}, {calcDogAge(dog.dob)}
                      </p>
                    </div>
                  )
                )
              })}
              <div></div>
            </div>
            <div className="mt-2">
              <p className="font-bold mb-1 text-base">This is their plan</p>
              {packType === 'FULL_TRIAL' ||
              packType === 'PARTIAL_TRIAL' ||
              packType === 'fullTrial' ||
              packType === 'partialTrial' ? (
                <div className={`${isMobileView ? 'mb-2' : 'leading-7'} sm:leading-normal text-sm`}>
                  <p>
                    Starter Box: {noOfPacks} x {packSize}g packs
                  </p>
                  <p>
                    Ongoing Box: {ongoingNoOfPacks} x {ongoingPackSize}g packs every {ongoingWeeks} weeks
                  </p>
                </div>
              ) : (
                <div className={`${isMobileView ? 'mb-2' : 'leading-7'} sm:leading-normal text-sm`}>
                  {noOfPacks} x {packSize}g every {weeks} weeks
                </div>
              )}
            </div>
            {!isMobileView && (
              <>
                <p className="font-bold mt-4 text-base">{DogNames}'s list of recipes</p>
                <div className="w-full mt-2 mb-4 text-base">{recipesList}</div>
                {treatPageBeforeCheckout && addonsSelected.length > 0 && (
                  <>
                    <p className="font-bold text-base">Treats</p>
                    <div className="w-full mt-2 mb-4 text-base">{treatsList}</div>
                  </>
                )}

                <div className="flex flex-col mb-4">
                  <p className="font-bold text-base">You will get your first delivery on</p>
                  <p className="text-left text-sm font-sans">
                    {deliveryDateText && <span className="mt-1 text-left text-sm font-sans">{deliveryDateText} </span>}
                    {formikRef?.current?.values?.shippingAddress1 && (
                      <span className="ml-1 text-sm md:ml-0">
                        to
                        {formikRef?.current?.values?.shippingAddress1 && (
                          <> {formikRef?.current?.values?.shippingAddress1}</>
                        )}
                      </span>
                    )}
                  </p>
                  {getBillingDate() && (
                    <p className="mt-1 text-left text-sm">
                      You will not be charged until <span data-testid="billingDate">{getBillingDate()}</span>
                    </p>
                  )}
                </div>
              </>
            )}
            <p className="font-bold text-base">Your tasty discount {checkoutV3 ? ' or referral code' : ''}</p>
            <div
              className={`mx-auto flex flex-row justify-center w-full pr-2 ${checkoutV3 ? '' : 'mt-2 mb-8'} ${isValidCoupon ? 'my-2' : 'mt-2 mb-8'}`}
            >
              {!checkoutV3 && (
                <div
                  className={`flex items-center justify-center ${
                    isValidCoupon && lastValidatedCoupon.amount > 0 && 'bg-ddBlue'
                  } m-auto w-full rounded-full`}
                >
                  <div className={`flex flex-col ${isValidCoupon ? `w-1/2` : 'w-full'}`}>
                    <Coupon
                      defaultCouponCode={coupon.code}
                      couponCode={couponCode}
                      setCouponCode={setCouponCode}
                      isValid={isValidCoupon}
                      isCouponValidationLoading={isCouponValidationLoading}
                      labelClass="inherit text-center transform-none"
                      CouponChangeGA={{
                        category: 'Sign_Up_Your_Details',
                        action: 'Clicked_Discount',
                        label: 'submit'
                      }}
                      validateFailGA={{
                        category: 'Sign_Up_Your_Details',
                        action: 'Invalid_Discount',
                        label: 'submit'
                      }}
                      isRounded={true}
                      page={'checkoutAlternate'}
                    />
                  </div>
                  {renderCouponText('relative flex flex-grow justify-center text-center text-sm text-black')}
                </div>
              )}
              {checkoutV3 && (
                <CouponAlternative
                  defaultCouponCode={coupon.code}
                  couponCode={couponCode}
                  setCouponCode={setCouponCode}
                  isValid={isValidCoupon}
                  isCouponValidationLoading={isCouponValidationLoading}
                  labelClass="inherit text-center transform-none"
                  CouponChangeGA={{
                    category: 'Sign_Up_Your_Details',
                    action: 'Clicked_Discount',
                    label: 'submit'
                  }}
                  validateFailGA={{
                    category: 'Sign_Up_Your_Details',
                    action: 'Invalid_Discount',
                    label: 'submit'
                  }}
                  isRounded={true}
                  page={'checkoutAlternate'}
                />
              )}
            </div>

            {checkoutV3 && isValidCoupon && (
              <div className="flex flex-row justify-center mb-8">
                <img width={15} src={Celebrate} alt="Celebrate icon" />
                <p className="font-sans font-normal text-sm pl-2 flex flex-row">
                  {renderCouponText('relative flex flex-grow justify-center text-center font-sans font-normal text-sm')}
                  &nbsp;applied
                </p>
              </div>
            )}
            <p className="font-bold text-base">Please check your order</p>
            {((!currentPrices && !ongoingPrices) || pricesLoading || isCouponValidationLoading) && (
              <div>
                <div className="m-auto w-full text-center" style={{ marginTop: '5vh' }}>
                  <img className="m-auto" width="200px" src={loadingGIF} alt="Loading, please wait..." />
                </div>
              </div>
            )}
            {pricesError && (
              <div>
                <p>There has been an error retrieving the plan price totals.</p>
              </div>
            )}
            {currentPrices && ongoingPrices && !pricesError && !isCouponValidationLoading && !pricesLoading && (
              <>
                <div className="mb-2 bg-[#F5F1ED] rounded-xl p-4 mr-2">
                  <div className="mx-auto w-full text-center">
                    <button
                      onClick={() => {
                        setShowFirstBox(0)

                        if (typeof window.gtag !== 'undefined') {
                          window.gtag('event', 'selected_first_box', {
                            page: 'sign_up_checkout_new'
                          })
                        }
                      }}
                      className={`pr-2 text-sm text-primary hover:underline focus:underline ${
                        showFirstBox === 0 ? 'font-bold underline' : ''
                      }`}
                    >
                      First Box
                    </button>

                    <button
                      data-testid="ongoing"
                      onClick={() => {
                        setShowFirstBox(1)

                        if (typeof window.gtag !== 'undefined') {
                          window.gtag('event', 'selected_ongoing_box', {
                            page: 'sign_up_checkout_new'
                          })
                        }
                      }}
                      className={`pl-2 text-sm text-primary hover:underline focus:underline ${
                        showFirstBox === 1 ? 'font-bold underline' : ''
                      }`}
                    >
                      Ongoing
                    </button>
                  </div>

                  {showFirstBox === 0 && (
                    <>
                      <div className="mt-2">
                        <div className="flex flex-col gap-2">
                          <div className="flex justify-between text-sm">
                            <div>{renderPackText()}</div>
                            <div className="flex min-w-max justify-items-end">
                              {coupon.code && (
                                <span className="strikethrough">
                                  &pound;{((currentPrices?.plan + currentPrices?.recipes) / 100).toFixed(2)}
                                </span>
                              )}
                              <span className="pl-1 font-bold">
                                &pound;{planPriceDiscount > 0 ? (planPriceDiscount / 100)?.toFixed(2) : '0.00'}
                              </span>
                            </div>
                          </div>
                          {treatPageBeforeCheckout && addonsSelected.length > 0 && (
                            <div className="flex justify-between text-sm">
                              <div>Treats &amp; Extras</div>
                              <div>
                                {coupon.code && currentPrices?.addons !== totalAddonPriceDiscount && (
                                  <span className="strikethrough">
                                    &pound;{(currentPrices?.addons / 100)?.toFixed(2)}
                                  </span>
                                )}{' '}
                                <span className="font-bold">
                                  {totalAddonPriceDiscount === 0
                                    ? 'FREE'
                                    : `£${(totalAddonPriceDiscount / 100)?.toFixed(2)}`}
                                </span>
                              </div>
                            </div>
                          )}

                          <div className="flex justify-between text-sm">
                            <div>
                              Delivery{' '}
                              {dayjs(formikRef.current?.values['deliveryDate'])?.day() === 6 ? '(Saturday)' : ''}
                            </div>
                            <div>
                              {currentPrices?.delivery > 0 && (
                                <div>
                                  <span data-testid="saturdayDelivery" className="strikethrough">
                                    {dayjs(formikRef.current?.values['deliveryDate'])?.day() === 6 ? '£4.99' : '£3.99'}
                                  </span>
                                  <span className="pl-1 uppercase">
                                    &pound;{getDiscountedDeliveryPrice(currentPrices?.delivery / 100).toFixed(2)}
                                  </span>
                                </div>
                              )}
                              {currentPrices?.delivery === 0 && (
                                <div>
                                  <span className="strikethrough">
                                    {dayjs(formikRef.current?.values['deliveryDate'])?.day() === 6 ? '£4.99' : '£3.99'}
                                  </span>
                                  <span className="pl-1 font-bold uppercase">Free</span>
                                </div>
                              )}
                            </div>
                          </div>
                          {checkoutV3 && (
                            <>
                              <div className="flex justify-between text-sm">
                                <div>Vet support</div>
                                <div>
                                  <div>
                                    <span className="pl-1 font-bold uppercase">Free</span>
                                  </div>
                                </div>
                              </div>

                              <div className="flex justify-between text-sm">
                                <div>VIP Facebook group membership</div>
                                <div>
                                  <div>
                                    <span className="pl-1 font-bold uppercase">Free</span>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          <div
                            className={`flex justify-center text-sm ${
                              treatPageBeforeCheckout && addonsSelected?.length > 0 ? 'pt-1' : 'py-1'
                            }`}
                          >
                            <div className="text-[#628F5B] font-bold">
                              You are saving &pound;{(currentPrices?.discount / 100)?.toFixed(2)} today
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div data-testid="total" className="flex justify-between py-2 text-sm font-bold">
                          <div className="font-bold">Total</div>
                          <div>
                            <span>&pound;{(currentPrices?.total / 100)?.toFixed(2)}</span>
                          </div>
                        </div>
                      </div>
                      {currentPrices?.maxDiscount && <p className="my-4 center">Maximum discount £80. See T&amp;Cs</p>}
                      <div className="mt-2 border-t border-gray-300 pt-2 center">
                        <p className="text-xs">You can amend, pause or cancel your subscription anytime.</p>
                      </div>
                    </>
                  )}

                  {showFirstBox === 1 && <div className="mt-2">{renderOngoingBox()}</div>}
                </div>
              </>
            )}
            {(getDiscountedCostPerDay() / 100)?.toFixed(2) !== 'NaN' && (
              <div className="my-1 mb-4 font-light">
                <p className="text-center text-sm">
                  Just <span className="font-bold">&pound;{(getDiscountedCostPerDay() / 100)?.toFixed(2)}</span> a day
                  to change your dog's life
                </p>
              </div>
            )}
          </div>
        </div>
        {renderCardLogos()}
        {checkoutReviews && (
          <div className="mt-8 hidden lg:block">
            {showReviews && reviewsType && <Reviews reviewsType={reviewsType} />}
          </div>
        )}
      </div>
    )
  }

  const renderSubscriptionSummaryTopMobile = () => {
    return (
      <div className="mb-4 p-4 lg:p-8 py-4 w-full mx-auto bg-white h-full rounded-3xl xl:w-9/12">
        <div className="flex flex-col">
          <div className="flex flex-col py-1">
            <p className="font-semibold font-sans mb-1 text-base">Our chefs will be cooking for</p>
            {dogInfo.dogs.map(dog => {
              return (
                dog.name && (
                  <div className="flex flex-row">
                    <div className="flex justify-center items-center pr-2 py-2">
                      {' '}
                      <img width={14} src={DDIcon} alt={'Different Dog logo'} />
                    </div>
                    <p className="text-sm font-sans flex items-center" key={dog.name}>
                      {dog.name}
                    </p>
                  </div>
                )
              )
            })}
            <div></div>
          </div>
          <div className="mt-2">
            <p className="font-semibold mb-1 text-base">This is their plan</p>
            {packType === 'FULL_TRIAL' ||
            packType === 'PARTIAL_TRIAL' ||
            packType === 'fullTrial' ||
            packType === 'partialTrial' ? (
              <div className={`${isMobileView ? 'mb-2' : 'leading-7'} sm:leading-normal text-sm`}>
                <p>
                  Starter Box: {noOfPacks} x {packSize}g packs
                </p>
                <p>
                  Ongoing Box: {ongoingNoOfPacks} x {ongoingPackSize}g packs every {ongoingWeeks} weeks
                </p>
              </div>
            ) : (
              <div className={`${isMobileView ? 'mb-2' : 'leading-7'} sm:leading-normal text-sm`}>
                {noOfPacks} x {packSize}g every {weeks} weeks
              </div>
            )}
          </div>
          {checkoutV3 && (
            <div className="mt-2">
              <p className="font-semibold text-base">You will get your first delivery on</p>
              {deliveryDateText && (
                <span className="text-left text-sm font-sans font-semibold">{deliveryDateText} </span>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
  const renderDiscountMobile = () => {
    return (
      <div className="p-4 lg:p-8 py-4 mb-8 w-full mx-auto bg-white h-full rounded-3xl xl:w-9/12">
        <div className="flex flex-col">
          <p className="font-semibold font-sans text-base">
            Your tasty discount {checkoutV3 ? ' or referral code' : ''}
          </p>
          <div className="mx-auto flex flex-row justify-center w-full my-2">
            {!checkoutV3 && (
              <div
                className={`flex items-center justify-center ${
                  isValidCoupon && lastValidatedCoupon.amount > 0 && 'bg-ddBlue'
                } m-auto w-full rounded-full`}
              >
                <div className={`flex flex-col ${isValidCoupon ? `w-1/2` : 'w-full'}`}>
                  <Coupon
                    defaultCouponCode={coupon.code}
                    couponCode={couponCode}
                    setCouponCode={setCouponCode}
                    isValid={isValidCoupon}
                    isCouponValidationLoading={isCouponValidationLoading}
                    labelClass="inherit text-center transform-none"
                    CouponChangeGA={{
                      category: 'Sign_Up_Your_Details',
                      action: 'Clicked_Discount',
                      label: 'submit'
                    }}
                    validateFailGA={{
                      category: 'Sign_Up_Your_Details',
                      action: 'Invalid_Discount',
                      label: 'submit'
                    }}
                    isRounded={true}
                    page={'checkoutAlternate'}
                  />
                </div>

                {isValidCoupon && lastValidatedCoupons?.length > 1 && (
                  <p className="relative flex flex-grow justify-center text-center text-sm text-black">
                    {lastValidatedCoupons[0]?.type === 'percentage'
                      ? lastValidatedCoupons[0].amount + '% off'
                      : '£' + (lastValidatedCoupons[0].amount / 100).toFixed(2) + ' off'}{' '}
                    {lastValidatedCoupons[0].uses > 1
                      ? `your first ${lastValidatedCoupons[0].uses} boxes`
                      : 'your first box'}
                    <br />
                    {lastValidatedCoupons[1].type === 'percentage'
                      ? lastValidatedCoupons[1].amount + '% off'
                      : '£' + (lastValidatedCoupons[1].amount / 100).toFixed(2) + ' off'}{' '}
                    {lastValidatedCoupons[1].uses > 1
                      ? `your next ${lastValidatedCoupons[1].uses} boxes`
                      : 'your first box'}
                  </p>
                )}
                {isValidCoupon && lastValidatedCoupon.amount > 0 && lastValidatedCoupons?.length <= 1 && (
                  <p className="relative flex flex-grow justify-center text-center text-sm text-black">
                    {lastValidatedCoupon.type === 'percentage'
                      ? lastValidatedCoupon.amount + '% off'
                      : '£' + (lastValidatedCoupon.amount / 100).toFixed(2) + ' off'}{' '}
                    {lastValidatedCoupon.uses > 1 ? `your first ${lastValidatedCoupon.uses} boxes` : 'your first box'}
                  </p>
                )}
              </div>
            )}
            {checkoutV3 && (
              <CouponAlternative
                defaultCouponCode={coupon.code}
                couponCode={couponCode}
                setCouponCode={setCouponCode}
                isValid={isValidCoupon}
                isCouponValidationLoading={isCouponValidationLoading}
                labelClass="inherit text-center transform-none"
                CouponChangeGA={{
                  category: 'Sign_Up_Your_Details',
                  action: 'Clicked_Discount',
                  label: 'submit'
                }}
                validateFailGA={{
                  category: 'Sign_Up_Your_Details',
                  action: 'Invalid_Discount',
                  label: 'submit'
                }}
                isRounded={true}
                page={'checkoutAlternate'}
              />
            )}
          </div>
          {checkoutV3 && isValidCoupon && (
            <div className="flex flex-row justify-center">
              <img width={15} src={Celebrate} alt="Celebrate icon" />
              <p className="font-sans font-normal text-sm pl-2 flex flex-row">
                {renderCouponText('relative flex flex-grow justify-center text-center font-sans font-normal text-sm')}
                &nbsp;applied
              </p>
            </div>
          )}
        </div>
      </div>
    )
  }

  const renderSubscriptionSummaryPricingMobile = () => {
    return (
      <div className="lg:p-8 p-4 pb-4 pt-6 w-full mx-auto bg-white h-full rounded-3xl xl:w-9/12">
        <div className="flex flex-col">
          <p className="font-semibold font-sans text-base pb-2">Please check your order</p>
          {((!currentPrices && !ongoingPrices) || pricesLoading || isCouponValidationLoading) && (
            <div>
              <div className="m-auto w-full text-center" style={{ marginTop: '5vh' }}>
                <img className="m-auto" width="200px" src={loadingGIF} alt="Loading, please wait..." />
              </div>
            </div>
          )}
          {pricesError && (
            <div>
              <p>There has been an error retrieving the plan price totals.</p>
            </div>
          )}
          {currentPrices && ongoingPrices && !pricesError && !isCouponValidationLoading && !pricesLoading && (
            <>
              <div className="mb-2 bg-[#F5F1ED] rounded-xl p-4">
                <div className="mx-auto w-full text-center">
                  <button
                    onClick={() => {
                      setShowFirstBox(0)

                      if (typeof window.gtag !== 'undefined') {
                        window.gtag('event', 'selected_first_box', {
                          page: 'sign_up_checkout_new'
                        })
                      }
                    }}
                    className={`pr-2 text-sm text-primary hover:underline focus:underline ${
                      showFirstBox === 0 ? 'font-bold underline' : ''
                    }`}
                  >
                    First Box
                  </button>

                  <button
                    data-testid="ongoing"
                    onClick={() => {
                      setShowFirstBox(1)

                      if (typeof window.gtag !== 'undefined') {
                        window.gtag('event', 'selected_ongoing_box', {
                          page: 'sign_up_checkout_new'
                        })
                      }
                    }}
                    className={`pl-2 text-sm text-primary hover:underline focus:underline ${
                      showFirstBox === 1 ? 'font-bold underline' : ''
                    }`}
                  >
                    Ongoing
                  </button>
                </div>

                {showFirstBox === 0 && (
                  <>
                    <div className="mt-2">
                      <div className="flex flex-col gap-2">
                        <div className="flex justify-between text-sm">
                          <div>{renderPackText()}</div>
                          <div className="flex min-w-max justify-items-end">
                            {coupon.code && (
                              <span className="strikethrough">
                                &pound;{((currentPrices?.plan + currentPrices?.recipes) / 100).toFixed(2)}
                              </span>
                            )}
                            <span className="pl-1 font-bold">
                              &pound;{planPriceDiscount > 0 ? (planPriceDiscount / 100)?.toFixed(2) : '0.00'}
                            </span>
                          </div>
                        </div>
                        {treatPageBeforeCheckout && addonsSelected.length > 0 && (
                          <div className="flex justify-between text-sm">
                            <div>Treats &amp; Extras</div>
                            <div>
                              {coupon.code && currentPrices?.addons !== totalAddonPriceDiscount && (
                                <span className="strikethrough">
                                  &pound;{(currentPrices?.addons / 100)?.toFixed(2)}
                                </span>
                              )}{' '}
                              <span className="font-bold">
                                {totalAddonPriceDiscount === 0
                                  ? 'FREE'
                                  : `£${(totalAddonPriceDiscount / 100)?.toFixed(2)}`}
                              </span>
                            </div>
                          </div>
                        )}

                        <div className="flex justify-between text-sm">
                          <div>
                            Delivery {dayjs(formikRef.current?.values['deliveryDate'])?.day() === 6 ? '(Saturday)' : ''}
                          </div>
                          <div>
                            {currentPrices?.delivery > 0 && (
                              <div>
                                <span data-testid="saturdayDelivery" className="strikethrough !p-0 !m-0">
                                  {dayjs(formikRef.current?.values['deliveryDate'])?.day() === 6 ? '£4.99' : '£3.99'}
                                </span>
                                <span className="pl-1 uppercase">
                                  &pound;{getDiscountedDeliveryPrice(currentPrices?.delivery / 100).toFixed(2)}
                                </span>
                              </div>
                            )}
                            {currentPrices?.delivery === 0 && (
                              <div>
                                <span className="strikethrough !p-0 !m-0">
                                  {dayjs(formikRef.current?.values['deliveryDate'])?.day() === 6 ? '£4.99' : '£3.99'}
                                </span>
                                <span className="pl-1 font-bold uppercase">Free</span>
                              </div>
                            )}
                          </div>
                        </div>
                        {checkoutV3 && (
                          <>
                            <div className="flex justify-between text-sm">
                              <div>Vet support</div>
                              <div>
                                <div>
                                  <span className="pl-1 font-bold uppercase">Free</span>
                                </div>
                              </div>
                            </div>

                            <div className="flex justify-between text-sm">
                              <div>VIP Facebook group membership</div>
                              <div>
                                <div>
                                  <span className="pl-1 font-bold uppercase">Free</span>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        <div
                          className={`flex justify-center text-sm ${
                            treatPageBeforeCheckout && addonsSelected?.length > 0 ? 'pt-1' : 'py-1'
                          }`}
                        >
                          <div className="text-[#628F5B] font-bold">
                            You are saving &pound;{(currentPrices?.discount / 100)?.toFixed(2)} today
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div data-testid="total" className="flex justify-between py-2 text-sm font-bold">
                        <div className="font-bold">Total</div>
                        <div>
                          <span>&pound;{(currentPrices?.total / 100)?.toFixed(2)}</span>
                        </div>
                      </div>
                    </div>
                    {currentPrices?.maxDiscount && <p className="my-4">Maximum discount £80. See T&amp;Cs</p>}
                  </>
                )}

                {showFirstBox === 1 && <div className="mt-2">{renderOngoingBox()}</div>}
              </div>
            </>
          )}
          {(getDiscountedCostPerDay() / 100)?.toFixed(2) !== 'NaN' && (
            <div className="my-1 font-light">
              <p className="text-center text-sm">
                Just <span className="font-bold">&pound;{(getDiscountedCostPerDay() / 100)?.toFixed(2)}</span> a day to
                change your dog's life
              </p>
            </div>
          )}
        </div>
      </div>
    )
  }

  const renderOngoingBox = () => {
    return (
      <div className="text-center">
        {packType === 'FULL_TRIAL' ||
        packType === 'PARTIAL_TRIAL' ||
        packType === 'fullTrial' ||
        packType === 'partialTrial' ? (
          <div>
            <p className="mb-2 text-xs">After your starter box, we'll automatically update your plan</p>
            <p className="mb-2 text-xs">
              from{' '}
              <span className="font-bold">
                {noOfPacks} x {packSize}g
              </span>{' '}
              to{' '}
              <span className="font-bold">
                {ongoingNoOfPacks} x {ongoingPackSize}g
              </span>
              .
            </p>
            <p className="text-xs">
              They will be delivered {ongoingPrices?.delivery === 0 && 'free'} every {ongoingWeeks} weeks{' '}
              {containsHealthPlusRecipes}{' '}
              <span className="font-bold">
                &pound;
                {packType === 'FULL_TRIAL' && packs?.full?.costPerGrams
                  ? (packs?.full?.costPerGrams / 100)?.toFixed(2)
                  : (ongoingPrices?.costPerDay / 100)?.toFixed(2)}
              </span>{' '}
              per day
              {ongoingPrices?.delivery / 100 > 0 &&
                (dayjs(formikRef.current?.values['deliveryDate'])?.day() === 6
                  ? ' + £4.99 delivery'
                  : ' + £3.99 delivery')}
              .
            </p>
          </div>
        ) : (
          <div>
            <p className="text-xs">
              Your boxes will be delivered {ongoingPrices?.delivery === 0 && 'free'} every {ongoingWeeks} weeks{' '}
              {containsHealthPlusRecipes}{' '}
              <span className="font-bold">
                &pound;
                {plan.costPerGrams
                  ? (plan?.costPerGrams / 100)?.toFixed(2)
                  : (ongoingPrices?.costPerDay / 100)?.toFixed(2)}
              </span>{' '}
              per day
              {ongoingPrices?.delivery / 100 > 0 &&
                (dayjs(formikRef.current?.values['deliveryDate'])?.day() === 6
                  ? ' + £4.99 delivery'
                  : ' + £3.99 delivery')}
              .
            </p>
          </div>
        )}
        <div className="mt-2 border-t border-gray-300 pt-2">
          <p className="text-xs">You can amend, pause or cancel your subscription anytime.</p>
        </div>
      </div>
    )
  }

  const renderCardLogos = () => {
    return (
      <div className="w-4/5 mx-auto flex flex-col justify-center items-center mt-6 mb-3">
        <StaticImage className="w-auto mx-auto" src={'../../../images/checkout/cardLogos.png'} alt="Card logos" />
        <div className="flex flex-row">
          <img width={12} src={Lock} alt="Lock" />
          <p className="font-sans font-normal text-xs text-base pl-2">All transactions are secure and encrypted</p>
        </div>
      </div>
    )
  }

  const renderCouponText = (className: string) => {
    if (isValidCoupon && lastValidatedCoupons?.length > 1) {
      return (
        <p className={className}>
          {lastValidatedCoupons[0]?.type === 'percentage'
            ? lastValidatedCoupons[0].amount + '% off'
            : '£' + (lastValidatedCoupons[0].amount / 100).toFixed(2) + ' off'}{' '}
          {lastValidatedCoupons[0].uses > 1 ? `your first ${lastValidatedCoupons[0].uses} boxes` : 'your first box'}
          <br />
          {lastValidatedCoupons[1].type === 'percentage'
            ? lastValidatedCoupons[1].amount + '% off'
            : '£' + (lastValidatedCoupons[1].amount / 100).toFixed(2) + ' off'}{' '}
          {lastValidatedCoupons[1].uses > 1 ? `your next ${lastValidatedCoupons[1].uses} boxes` : 'your first box'}
        </p>
      )
    }
    if (isValidCoupon && lastValidatedCoupon.amount > 0 && lastValidatedCoupons?.length <= 1) {
      return (
        <p className={className}>
          {lastValidatedCoupon.type === 'percentage'
            ? lastValidatedCoupon.amount + '% off'
            : '£' + (lastValidatedCoupon.amount / 100).toFixed(2) + ' off'}{' '}
          {lastValidatedCoupon.uses > 1 ? `your first ${lastValidatedCoupon.uses} boxes` : 'your first box'}
        </p>
      )
    }
  }

  const ExtendedShippingSchema = ShippingSchema.shape({
    billingFirstName: yup.string().when([], {
      is: () => !isBillingAddressSame,
      then: yup.string().required('Please enter your billing name'),
      otherwise: yup.string()
    }),
    billingLastName: yup.string().when([], {
      is: () => !isBillingAddressSame,
      then: yup.string().required('Please enter your billing surname'),
      otherwise: yup.string()
    }),
    billingAddress1: yup.string().when([], {
      is: () => !isBillingAddressSame,
      then: yup.string().required('Please enter the first line of your billing address'),
      otherwise: yup.string()
    }),
    billingAddress2: yup.string(),
    billingCity: yup.string().when([], {
      is: () => !isBillingAddressSame,
      then: yup.string().required('Please enter your billing city'),
      otherwise: yup.string()
    }),
    billingCounty: yup.string().when([], {
      is: () => !isBillingAddressSame,
      then: yup.string().required('Please enter your billing county'),
      otherwise: yup.string()
    }),
    billingPostcode: yup.string().when([], {
      is: () => !isBillingAddressSame,
      then: yup.string().required('Please enter your billing postcode'),
      otherwise: yup.string()
    })
  })

  return (
    <>
      <div className="relative bg-checkoutBG">
        <div>
          <Formik
            innerRef={formikRef}
            initialValues={{
              deliveryDate: shipping.deliveryDate,
              shippingAddressSearch: '',
              shippingAddress1: shipping.address.shipping.address1,
              shippingAddress2: shipping.address.shipping.address2,
              shippingCity: shipping.address.shipping.city,
              shippingCounty: shipping.address.shipping.county,
              shippingPostcode: shipping.address.shipping.postcode,
              phoneNumber: shipping.customer.telephone,
              firstName: parentDetails.customer.name ? parentDetails.customer.name : shipping.customer.name,
              lastName: parentDetails.customer.surname ? parentDetails.customer.surname : shipping.customer.surname,
              email: parentDetails.customer.email ?? dogInfo.customer.email,
              billingFirstName: '',
              billingLastName: '',
              billingAddressSearch: '',
              billingAddress1: '',
              billingAddress2: '',
              billingCity: '',
              billingCounty: '',
              billingPostcode: '',
              coupon: parentDetails.coupon,
              cardNumberElement: '',
              payment: shipping.payment
            }}
            validationSchema={ExtendedShippingSchema}
            onSubmit={async (values: any) => {
              console.error(
                `onSubmit checkout - ${JSON.stringify(values)}, ${JSON.stringify(payEvent)}, ${JSON.stringify(intent)}`
              )
              if (localErrorMessage) {
                setErrorMessage('')
              }
              setSubmitting(true)

              if (typeof window.gtag !== 'undefined') {
                window.gtag('event', 'confirm_order', {
                  page: 'sign_up_checkout_new'
                })
              }

              // if (payEvent) return

              ////////////////////////////////////////////////////
              try {
                dispatch(setHashLoaded(false))
                await checkHash({
                  planLevel: pack.planLevel,
                  hashValuePlan: planHash || packNew.hash,
                  hashValueCoupon: couponHash,
                  hashValueProducts: productListHash,
                  visitorId: dogInfo?.visitorId
                })
                dispatch(setHashLoaded(true))
              } catch (e: any) {
                dispatch(setHashLoaded(false))
                // This only handles the plan hash at the moment
                // This can be extended out to handle other hash updates
                if (!e?.hashValuePlan) {
                  // only reset the plan hash
                  await dispatch(resetSingleHashes('planHash'))
                  await dispatch(setHashChanged(true))
                  dispatch(updateKey(`plan`))
                  navigate(`/signup/plan`)
                  return
                }
              }
              ////////////////////////////////////////////////////

              //Confirm_Order
              if (!values.firstName.includes('PHONE PAYMENT') && (!stripe || !elements)) return
              console.error(`Clicked confirm order`)

              let payment = null

              // if (!values.firstName.includes('PHONE PAYMENT')) {
              if (!payEvent && !values.firstName.includes('PHONE PAYMENT')) {
                // @ts-ignore
                const cardElement = elements.getElement(CardNumberElement)!
                try {
                  payment = await stripe.createPaymentMethod({
                    type: 'card',
                    card: cardElement,
                    billing_details: {
                      name: isBillingAddressSame
                        ? `${values.firstName} ${values.lastName}`
                        : `${values.billingFirstName} ${values.billingLastName}`,
                      address: {
                        line1: isBillingAddressSame ? values.shippingAddress1 : values.billingAddress1,
                        line2: isBillingAddressSame ? values.shippingAddress2 : values.billingAddress2,
                        city: isBillingAddressSame ? values.shippingCity : values.billingCity,
                        state: isBillingAddressSame ? values.shippingCounty : values.billingCounty,
                        postal_code: isBillingAddressSame ? values.shippingPostcode : values.billingPostcode
                      },
                      email: values.email.trim(),
                      phone: values.phoneNumber
                    }
                  })
                } catch (e: any) {
                  console.error(`onSubmit stripeCreatePaymentMethodError - ${JSON.stringify(e)}`)
                }
                console.error(`onSubmit card payment - ${JSON.stringify(payment)}`)
              }
              if (payEvent) payment = payEvent
              // if(payEvent) setIntent(payEvent)
              // console.log({payEvent, intent})
              console.error(
                `onSubmit payment - ${JSON.stringify(payment)}, payevent - ${JSON.stringify(payEvent)}, intent - ${JSON.stringify(intent)}`
              )
              const deliveryDate = dayjs(values.deliveryDate).format('YYYY-MM-DD')

              if (payment?.error) {
                //failure
                console.error(`onSubmit paymentError - ${JSON.stringify(payment)}`)

                setErrorMessage(payment.error.message)
                setSubmitting(false)
                setFormErrors(['Card details'])
              } else {
                const planObj = {
                  id: packSize + 'g-' + weeks + 'w',
                  type: fixedOrCustomPlan,
                  grams: packSize
                }

                if (packType !== 'CUSTOM') {
                  planObj.level = packs.planLevel

                  if (packType !== 'CUSTOM') {
                    planObj.size = packType
                  }
                }

                // TODO: Updating packType on submit to handle old code through account.
                // When moving to new code update this if statement!
                if (packType.includes('Trial')) {
                  planObj.size = packType.toUpperCase().replace('TRIAL', '_TRIAL')
                }
                console.error(`handleFieldDataOnSubmitValues - ${JSON.stringify(values)}`)
                let data = handleFieldData(values)

                /**
                 * 3DS step by step
                 * 1. Create payment method on form submission (above)
                 * 2. Send payment method to BE (payment?.method)
                 * 3. BE returns intent (setupIntent in redux)
                 * 4. User is asked to confirm and confirms through banking app
                 * 5. Confirmed card setup returns setupIntent (further above)
                 * 6. Form gets resubmitted when intent state is populated
                 * 7. If validation passes, checkout is completed
                 */

                data = { ...data, plan: planObj, deliveryDate, visitorId: dogInfo.visitorId }

                if (intent) {
                  data = { ...data, intent }
                  // This isn't an error, but the Logger component only catches errors
                  // https://www.npmjs.com/package/cloudwatch-front-logger
                  console.error(`Intent - ${JSON.stringify(intent)}`)
                } else {
                  data = { ...data, payment: payment?.paymentMethod || null }
                  setAwaiting3ds(true)
                  // This isn't an error, but the Logger component only catches errors
                  // https://www.npmjs.com/package/cloudwatch-front-logger
                  console.error(`Awaiting 3DS confirmation`)
                  console.error(`Payment method - ${JSON.stringify(payment?.paymentMethod)}`)
                  if (typeof window.gtag !== 'undefined') {
                    window.gtag('event', 'checkoutPaymentBegin', {
                      page: 'sign_up_checkout_new'
                    })
                  }
                }

                // console.log('before createShippingConfirm', { data, intent })
                dispatch(createShippingConfirm(data, true))
                // setStopHashCheck(true)
                dispatch(updateCurrCouponCode(lastValidatedCoupon.code as string))
                dispatch(updateCouponCode(lastValidatedCoupon.code as string))
                dispatch(updateCustomerEmail(values.email))
                dispatch(updateParentEmail(values.email))
                setSubmitting(false)
              }
            }}
            validateOnMount={false}
          >
            {props => {
              function checkInputState(fieldName: string) {
                if (!props.errors[fieldName] && props.values[fieldName]) {
                  if (fieldName === 'shippingPostcode') {
                    if (errorMessage === 'Postcode is not valid!' || localErrorMessage?.includes('Invalid postcode')) {
                      return checkoutV3 ? 'field-error-alternate' : 'field-error'
                    }
                  }
                  return checkoutV3 ? 'field-success-alternate' : 'field-success'
                } else if (props.errors?.[fieldName] && props.touched?.[fieldName]) {
                  return checkoutV3 ? 'field-error-alternate' : 'field-error'
                }
                return 'field-focus'
              }
              return (
                <div id="checkout" className="relative bg-checkoutBG">
                  <div className="pt-4">
                    <h1
                      data-testid="reviewPlanAndCheckout"
                      className="p-4 text-center tracking-tight text-4xl md:text-5xl"
                    >
                      Checkout
                    </h1>
                  </div>

                  <div className="mx-auto flex flex-wrap items-center justify-center w-10/12 md:w-full">
                    <p className="font-sans font-bold relative text-center text-sm py-2 px-6 bg-[#D7EAD2] rounded-3xl">
                      {emptyBowlVsMoneyBack === 0 && '92% of owners see a positive change since feeding our recipes'}
                      {emptyBowlVsMoneyBack === 1 && 'Try our recipes risk-free with our Empty Bowl Guarantee'}
                      {emptyBowlVsMoneyBack === 2 && 'Try our recipes risk-free with our Money Back Guarantee'}
                    </p>
                  </div>

                  <div className={`relative ${isMobileView ? 'my-4' : 'my-12'} mx-auto md:w-11/12 xl:w-3/4 max-w-7xl`}>
                    <div className="h-auto">
                      <div className="w-11/12 md:w-full mx-auto justify-center items-center">
                        <div className="flex flex-col-reverse lg:flex-row lg:gap-x-[4rem] xl:gap-x-[6rem]">
                          <div className="flex flex-col lg:w-3/4 xl:w-full mt-6 lg:mt-0">
                            {/* Subscription summary top section mobile view */}
                            {isMobileView && renderSubscriptionSummaryTopMobile()}
                            {/* Express checkout */}
                            <div id="topExpressButton">
                              {!payEvent && paymentRequest && expressCheckoutAB && !isMobileDevice() && (
                                <div className="w-11/12 mx-auto mt-4 lg:mt-0 mb-4 lg:mb-6 flex flex-col justify-center items-center">
                                  <Elements stripe={stripePromise}>
                                    <button
                                      className="bg-black text-white py-2 px-2 font-sans font-medium rounded-full text-xl lg:text-2xl w-full lg:w-11/12 flex justify-center items-center mx-auto"
                                      onClick={e => {
                                        if (paymentRequest) {
                                          paymentRequest.show()
                                          e.preventDefault()
                                          if (typeof window.gtag !== 'undefined') {
                                            window.gtag('event', 'express_checkout_clicked', {
                                              page: 'sign_up_checkout_new'
                                            })
                                          }
                                        }
                                      }}
                                    >
                                      Express checkout
                                      {paymentRequest._activeBackingLibraryName === 'APPLE_PAY' && (
                                        <StaticImage
                                          className="ml-3"
                                          alt="Apple Pay"
                                          objectFit="contain"
                                          width={60}
                                          src="../../../images/checkout/ApplePay.svg"
                                        />
                                      )}
                                      {(paymentRequest._activeBackingLibraryName === 'GOOGLE_PAY' ||
                                        (paymentRequest._activeBackingLibraryName === 'BROWSER' &&
                                          browser !== 'Apple')) && (
                                        <StaticImage
                                          className="ml-3"
                                          alt="Google Pay"
                                          objectFit="contain"
                                          width={60}
                                          src="../../../images/checkout/GPay.svg"
                                        />
                                      )}
                                    </button>
                                  </Elements>
                                  <div className="mt-4 text-center font-bold">or use the form below</div>
                                </div>
                              )}
                            </div>
                            {payEvent && expressCheckoutAB && !isMobileDevice() && (
                              <div className="mb-6 text-center font-bold">Your mobile payment has been verified</div>
                            )}
                            {/* Your details section */}
                            <div className="w-full mx-auto bg-white rounded-3xl p-4 lg:p-8">
                              <div className="flex flex-col">
                                <div className="flex flex-row items-center">
                                  <p className="font-sans font-semibold text-base md:text-2xl text-left ml-2">
                                    Next, let us know your details
                                  </p>
                                </div>
                                <div className="w-full my-4">
                                  <div
                                    className={`${
                                      props.errors.firstName ? 'mb-3' : 'mb-7'
                                    } inline-flex w-full sm:w-1/2`}
                                  >
                                    <div className="flex w-full flex-col">
                                      <div className="relative flex w-full flex-shrink-0 flex-row items-center">
                                        <Field
                                          data-testid="firstName"
                                          type="text"
                                          name="firstName"
                                          id="firstName"
                                          autoComplete="off"
                                          className={`${checkInputState(
                                            'firstName'
                                          )} fName peer block w-full self-center rounded-full border border-gray-300 bg-white pt-5 text-center focus:border-highlight focus:ring-highlight mr-1`}
                                          onBlur={() => {
                                            console.error(`First name: ${props.values['firstName']}`)
                                            if (typeof window.gtag !== 'undefined') {
                                              window.gtag('event', 'entered_first_name', {
                                                page: 'sign_up_checkout_new',
                                                firstName: props.values['firstName']
                                              })
                                            }
                                          }}
                                        />
                                        <label
                                          className={`absolute top-[3px] inline-flex h-[80%] w-full transform cursor-text appearance-none items-center justify-center duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                            props.values.firstName ? 'ml-0 -translate-y-[0.80rem] scale-75' : ''
                                          }`}
                                          htmlFor="firstName"
                                        >
                                          First name<span className="ml-1">*</span>
                                        </label>
                                      </div>
                                      {props.errors.firstName && formErrors.includes('First name') ? (
                                        <div className="p-2 text-sm text-[#FF220C] sm:mr-2 flex justify-center">
                                          {props.errors.firstName}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="mb-7 inline-flex w-full sm:w-1/2">
                                    <div className="flex w-full flex-col">
                                      <div className="relative flex w-full flex-shrink-0 flex-row items-center">
                                        <Field
                                          type="text"
                                          name="lastName"
                                          id="lastName"
                                          autoComplete="off"
                                          className={`${checkInputState(
                                            'lastName'
                                          )} peer block w-full self-center rounded-full border border-gray-300 bg-white pt-5 text-center focus:border-highlight focus:ring-highlight ml-1`}
                                          onBlur={() => {
                                            props.setFieldTouched('lastName')
                                            console.error(`Last name: ${props.values['lastName']}`)
                                            if (typeof window.gtag !== 'undefined') {
                                              window.gtag('event', 'entered_last_name', {
                                                page: 'sign_up_checkout_new',
                                                lastName: props.values['lastName']
                                              })
                                            }
                                          }}
                                        />
                                        <label
                                          className={`absolute top-[3px] inline-flex h-[80%] w-full transform cursor-text appearance-none items-center justify-center duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                            props.values.lastName ? 'ml-0 -translate-y-[0.80rem] scale-75' : ''
                                          }`}
                                          htmlFor="lastName"
                                        >
                                          Last name<span className="ml-1">*</span>
                                        </label>
                                      </div>
                                      {(props.errors.lastName && formErrors.includes('Last name')) ||
                                      (props.errors?.['lastName'] && props.touched?.['lastName']) ? (
                                        <div className="p-2 text-sm text-[#FF220C] sm:ml-2 flex justify-center">
                                          {props.errors.lastName}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="mb-7 inline-flex w-full">
                                    <div className="flex w-full flex-col">
                                      <div className="relative flex w-full flex-shrink-0 flex-row items-center">
                                        <Field
                                          type="email"
                                          name="email"
                                          id="email"
                                          className={`${checkInputState(
                                            'email'
                                          )} peer block w-full self-center rounded-full border border-gray-300 bg-white pt-5 text-center focus:border-highlight focus:ring-highlight`}
                                          onBlur={() => {
                                            if (typeof window.gtag !== 'undefined') {
                                              window.gtag('event', 'entered_email', {
                                                page: 'sign_up_checkout_new',
                                                email: props.values['email']
                                              })
                                            }
                                          }}
                                        />
                                        <label
                                          className={`absolute top-[3px] inline-flex h-[80%] w-full transform cursor-text appearance-none items-center justify-center duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                            props.values.email ? 'ml-0 -translate-y-[0.80rem] scale-75' : ''
                                          }`}
                                          htmlFor="email"
                                        >
                                          Email<span className="ml-1">*</span>
                                        </label>
                                      </div>
                                      {props.errors.email && formErrors.includes('Email') ? (
                                        <div className="p-2 text-sm text-[#FF220C] sm:ml-2 flex justify-center">
                                          {props.errors.email}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="mb-3 inline-flex w-full">
                                    <div className="flex w-full flex-col">
                                      <div className="relative flex w-full flex-shrink-0 flex-row items-center">
                                        <Field
                                          data-testid="phoneNbr"
                                          id="phoneNumber"
                                          type="tel"
                                          autoComplete="off"
                                          name="phoneNumber"
                                          className={`${checkInputState(
                                            'phoneNumber'
                                          )} peer block w-full self-center rounded-full border border-gray-300 bg-white pt-5 text-center focus:border-highlight focus:ring-highlight`}
                                          onBlur={() => {
                                            props.setFieldTouched('phoneNumber')
                                            sendPhoneNumberAndEmail(props.values, props.errors)
                                            if (typeof window.gtag !== 'undefined') {
                                              window.gtag('event', 'entered_phone_number', {
                                                page: 'sign_up_checkout_new',
                                                phone_number: props.values['phoneNumber']
                                              })
                                            }
                                            console.error(
                                              `handleFieldDataPhoneNumberValidationValues - ${JSON.stringify(props.values)}`
                                            )
                                            handleFieldData(props.values)
                                          }}
                                        />
                                        <label
                                          className={`absolute top-[3px] inline-flex h-[80%] w-full transform cursor-text appearance-none items-center justify-center duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                            props.values.phoneNumber ? 'ml-0 -translate-y-[0.80rem] scale-75' : ''
                                          }`}
                                          htmlFor="phoneNumber"
                                        >
                                          Mobile number<span className="ml-1">*</span>
                                        </label>
                                      </div>
                                      {(props.errors.phoneNumber && formErrors.includes('Phone number')) ||
                                      (props.errors?.['phoneNumber'] && props.touched?.['phoneNumber']) ? (
                                        <div className="p-2 text-sm text-[#FF220C] sm:ml-2 flex justify-center">
                                          {props.errors.phoneNumber}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="mb-1 grid items-center">
                                    <label className="flex flex-row items-center">
                                      <Field
                                        id="offers2"
                                        type="checkbox"
                                        name="smsOptIn"
                                        className="text-fourth-checkbox mt-1 h-4 w-4 rounded-[4px] ml-1"
                                        onClick={(e: any) => {
                                          sendPhoneNumberAndEmail(props.values, props.errors, e.target.checked)
                                        }}
                                      />
                                      <span className="flex-wrap pl-2 text-sm">
                                        I want to receive tips from your vet and tasty discounts via SMS and Calls
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Your address section */}
                            <div className={`w-full mx-auto ${isMobileView ? 'mt-4' : 'mt-8'} `}>
                              <div className="mt-4 md:mt-0 w-full mx-auto bg-white rounded-3xl p-4 lg:p-8">
                                <div className="flex flex-col">
                                  <p className="font-sans font-semibold text-base md:text-2xl text-left ml-2">
                                    Where the food should be delivered to?
                                  </p>
                                  {!openAddressInputs && showAddressFinder && (
                                    <div className="mt-6 flex w-full flex-col items-start">
                                      <div className="relative w-full">
                                        <Field
                                          type="text"
                                          id="shippingAddressSearch"
                                          name="shippingAddressSearch"
                                          placeholder="Your postcode *"
                                          className={`${checkInputState(
                                            'shippingAddressSearch'
                                          )} peer block w-full self-center rounded-full border border-gray-300 bg-white py-3 text-center focus:border-highlight focus:ring-highlight placeholder-base`}
                                          onBlur={() => {
                                            console.error(
                                              `handleFieldDataAddressSearchValuesOnBlur - ${JSON.stringify(props.values)}`
                                            )
                                            handleFieldData(props.values)
                                          }}
                                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            console.error(
                                              `handleFieldDataAddressSearchValuesOnChange - ${e.target.value} - ${JSON.stringify(props.values)}`
                                            )
                                            props.setFieldValue('shippingAddressSearch', e.target.value)
                                            if (typeof window.gtag !== 'undefined') {
                                              const recipeItems = plan?.recipes?.map(r => {
                                                return {
                                                  item_id: r.id,
                                                  item_name: r.name,
                                                  quantity: r.quantity,
                                                  item_list_id: 'recipes',
                                                  item_list_name: 'Recipes'
                                                }
                                              })

                                              const addonItems = plan?.addons?.map(a => {
                                                return {
                                                  item_id: a.id,
                                                  item_name: a.name,
                                                  price: (a.price / 100).toFixed(2),
                                                  quantity: a.quantity,
                                                  item_list_id: 'addons',
                                                  item_list_name: 'Addons'
                                                }
                                              })

                                              window.gtag('event', 'add_shipping_info', {
                                                currency: 'GBP',
                                                value: (currentPrices?.total / 100).toFixed(2),
                                                coupon: lastValidatedCoupon?.code,
                                                items: recipeItems.concat(addonItems)
                                              })
                                              window.gtag('event', 'entered_shipping_address_search', {
                                                page: 'sign_up_checkout_new'
                                              })
                                            }
                                          }}
                                        />
                                        {props.errors.shippingAddressSearch ? (
                                          <div className="text-sm text-red-500 flex justify-center">
                                            {props.errors.shippingAddressSearch}
                                          </div>
                                        ) : null}
                                      </div>
                                      <button
                                        className="w-full text-center text-sm underline "
                                        onClick={() => setOpenAddressInputs(true)}
                                      >
                                        My address isn't shown
                                      </button>
                                    </div>
                                  )}

                                  <div
                                    className={`${
                                      formikRef.current?.values['shippingAddress1'] || openAddressInputs
                                        ? 'block'
                                        : 'hidden'
                                    }`}
                                  >
                                    <div className="my-7">
                                      <div>
                                        <div className="relative flex w-full flex-row items-center">
                                          <Field
                                            type="text"
                                            name="shippingAddress1"
                                            id="shippingAddress1"
                                            className={`${checkInputState(
                                              'shippingAddress1'
                                            )} peer block w-full self-center rounded-full border border-gray-300 bg-white pt-5 text-center focus:border-highlight focus:ring-highlight`}
                                            onBlur={() => {
                                              if (typeof window.gtag !== 'undefined') {
                                                window.gtag('event', 'entered_shipping_address_1', {
                                                  page: 'sign_up_checkout_new',
                                                  shippingAddress1: props.values['shippingAddress1']
                                                })
                                              }
                                            }}
                                          />
                                          <label
                                            className={`absolute top-[3px] inline-flex h-[80%] w-full transform cursor-text appearance-none items-center justify-center duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                              props.values.shippingAddress1
                                                ? 'ml-0 -translate-y-[0.80rem] scale-75'
                                                : ''
                                            }`}
                                            htmlFor="shippingAddress1"
                                          >
                                            Address line 1<span className="ml-1">*</span>
                                          </label>
                                        </div>
                                        {props.errors.shippingAddress1 && formErrors.includes('Address') ? (
                                          <div className="p-2 text-sm text-[#FF220C] flex justify-center">
                                            {props.errors.shippingAddress1}
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>

                                    <div className="relative mb-7">
                                      <div>
                                        <Field
                                          type="text"
                                          name="shippingAddress2"
                                          id="shippingAddress2"
                                          className={`${checkInputState(
                                            'shippingAddress2'
                                          )} peer block w-full self-center rounded-full border border-gray-300 bg-white pt-5 text-center focus:border-highlight focus:ring-highlight ml-1`}
                                          onBlur={() => {
                                            if (typeof window.gtag !== 'undefined') {
                                              window.gtag('event', 'entered_shipping_address_2', {
                                                page: 'sign_up_checkout_new',
                                                shippingAddress2: props.values['shippingAddress2']
                                              })
                                            }
                                          }}
                                        />
                                        <label
                                          className={`absolute top-[3px] inline-flex h-[80%] w-full transform cursor-text appearance-none items-center justify-center duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                            props.values.shippingAddress2 ? 'ml-0 -translate-y-[0.80rem] scale-75' : ''
                                          }`}
                                          htmlFor="shippingAddress2"
                                        >
                                          Address line 2
                                        </label>
                                        {props.errors.shippingAddress2 && props.touched.shippingAddress2 ? (
                                          <div className="p-2 text-sm text-[#FF220C]">
                                            {props.errors.shippingAddress2}
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>

                                    <div className="mb-7">
                                      <div>
                                        <div className="relative flex w-full flex-row items-center">
                                          <Field
                                            type="text"
                                            name="shippingCity"
                                            id="shippingCity"
                                            className={`${checkInputState(
                                              'shippingCity'
                                            )} peer block w-full self-center rounded-full border border-gray-300 bg-white pt-5 text-center focus:border-highlight focus:ring-highlight ml-1`}
                                            onBlur={() => {
                                              if (typeof window.gtag !== 'undefined') {
                                                window.gtag('event', 'entered_shipping_city', {
                                                  page: 'sign_up_checkout_new',
                                                  shippingCity: props.values['shippingCity']
                                                })
                                              }
                                            }}
                                          />
                                          <label
                                            className={`absolute top-[3px] inline-flex h-[80%] w-full transform cursor-text appearance-none items-center justify-center duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                              props.values.shippingCity ? 'ml-0 -translate-y-[0.80rem] scale-75' : ''
                                            }`}
                                            htmlFor="shippingCity"
                                          >
                                            City<span className="ml-1">*</span>
                                          </label>
                                        </div>
                                        {props.errors.shippingCity && formErrors.includes('Address') ? (
                                          <div className="p-2 text-sm text-[#FF220C] flex justify-center">
                                            {props.errors.shippingCity}
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>

                                    <div className="mb-7">
                                      <div>
                                        <div className="relative flex w-full flex-row items-center">
                                          <Field
                                            type="text"
                                            name="shippingCounty"
                                            id="shippingCounty"
                                            className={`${checkInputState(
                                              'shippingCounty'
                                            )} peer block w-full self-center rounded-full border border-gray-300 bg-white pt-5 text-center focus:border-highlight focus:ring-highlight ml-1`}
                                            onBlur={() => {
                                              if (typeof window.gtag !== 'undefined') {
                                                window.gtag('event', 'entered_shipping_county', {
                                                  page: 'sign_up_checkout_new',
                                                  shippingCounty: props.values['shippingCounty']
                                                })
                                              }
                                            }}
                                          />
                                          <label
                                            className={`absolute top-[3px] inline-flex h-[80%] w-full transform cursor-text appearance-none items-center justify-center duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                              props.values.shippingCounty ? 'ml-0 -translate-y-[0.80rem] scale-75' : ''
                                            }`}
                                            htmlFor="shippingCounty"
                                          >
                                            County<span className="ml-1">*</span>
                                          </label>
                                        </div>

                                        {props.errors.shippingCounty && formErrors.includes('Address') ? (
                                          <div className="p-2 text-sm text-[#FF220C] flex justify-center">
                                            {props.errors.shippingCounty}
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>

                                    <div className="mb-1">
                                      <div>
                                        <div className="relative flex w-full flex-row items-center">
                                          <Field
                                            type="text"
                                            name="shippingPostcode"
                                            id="shippingPostcode"
                                            onBlur={(e: any) => {
                                              props.setFieldTouched('shippingPostcode')
                                              setErrorMessage('')
                                              if (e.target.value) {
                                                dispatch(validatePostcode(e.target.value, dogInfo?.visitorId))
                                                if (typeof window.gtag !== 'undefined') {
                                                  window.gtag('event', 'entered_shipping_postcode', {
                                                    page: 'sign_up_checkout_new',
                                                    shippingPostcode: e.target.value
                                                  })
                                                }
                                              }
                                            }}
                                            className={`${checkInputState(
                                              'shippingPostcode'
                                            )} peer block w-full self-center rounded-full border border-gray-300 bg-white pt-5 text-center focus:border-highlight focus:ring-highlight ml-1`}
                                          />
                                          <label
                                            className={`absolute top-[3px] inline-flex h-[80%] w-full transform cursor-text appearance-none items-center justify-center duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                              props.values.shippingPostcode
                                                ? 'ml-0 -translate-y-[0.80rem] scale-75'
                                                : ''
                                            }`}
                                            htmlFor="shippingPostcode"
                                          >
                                            Postcode<span className="ml-1">*</span>
                                          </label>
                                        </div>
                                        {(props.errors.shippingPostcode && formErrors.includes('Address')) ||
                                        (props.errors.shippingPostcode && props.touched?.['shippingPostcode']) ? (
                                          <div className="p-2 text-sm text-[#FF220C] flex justify-center">
                                            {props.errors.shippingPostcode}
                                          </div>
                                        ) : null}
                                        {!props.errors.shippingPostcode &&
                                        localErrorMessage?.includes('Invalid postcode') ? (
                                          <div className="p-2 text-sm text-[#FF220C] flex justify-center">
                                            {localErrorMessage}
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                  {openAddressInputs && (
                                    <>
                                      <div className="mt-4 mb-2 grid items-center">
                                        <label className="flex flex-row items-center">
                                          <Field
                                            id="offers2"
                                            type="checkbox"
                                            name="mailConsentFirstParty"
                                            className="text-fourth-checkbox mt-1 h-4 w-4 rounded-[4px] ml-1"
                                            onClick={(e: any) => {
                                              sendPhoneNumberAndEmail(props.values, props.errors, e.target.checked)
                                            }}
                                          />
                                          <span className="flex-wrap pl-2 text-sm">
                                            I'm happy to receive tips and tasty discounts from Different Dog via post
                                          </span>
                                        </label>
                                      </div>
                                      <div className="mb-1 grid items-center">
                                        <label className="flex flex-row items-center">
                                          <Field
                                            id="offers2"
                                            type="checkbox"
                                            name="mailConsentThirdParty"
                                            className="text-fourth-checkbox mt-1 h-4 w-4 rounded-[4px] ml-1"
                                            onClick={(e: any) => {
                                              sendPhoneNumberAndEmail(props.values, props.errors, e.target.checked)
                                            }}
                                          />
                                          <span className="flex-wrap pl-2 text-sm">
                                            I'm happy to receive post from other trusted brands I might like,{' '}
                                            <a
                                              className="underline"
                                              target="_blank"
                                              href="/privacy-policy"
                                              rel="noreferrer"
                                            >
                                              learn more about our partners here
                                            </a>
                                          </span>
                                        </label>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>

                            {/* Delivery Date Section */}
                            {showNextDayDeliveryBanner && (
                              <div
                                id="nextDayDeliveryModule"
                                className="relative z-10 bg-ddBlue -mb-12 p-4 pb-8 rounded-t-3xl text-center mt-6 flex justify-center items-center"
                              >
                                <div className="flex items-center justify-center md:pr-1">
                                  <img className="mt-1" width={24} src={Clock} alt="Clock" />
                                </div>
                                <div className="flex flex-row items-center justify-center px-3">
                                  <p className="font-sans font-normal text-xs md:text-sm text-center">
                                    <span className="font-bold">Want your first order delivered tomorrow?</span>
                                    <br />
                                    Order within{' '}
                                    {remainingTime.hours !== '00' && (
                                      <span className="font-bold">
                                        {remainingTime.hours}
                                        <span className="font-normal"> hours</span>
                                      </span>
                                    )}{' '}
                                    {remainingTime.minutes !== '00' && (
                                      <span className="font-bold">
                                        {remainingTime.minutes} <span className="font-normal"> minutes</span>
                                      </span>
                                    )}{' '}
                                    <span className="font-bold">{remainingTime.seconds}</span> seconds
                                  </p>
                                </div>
                              </div>
                            )}
                            <div className={`w-full mx-auto z-20 ${isMobileView ? 'mt-4 mb-8' : 'my-8'}`}>
                              <div className="mt-4 md:mt-0 w-full mx-auto bg-white rounded-3xl p-4 lg:p-8">
                                <div className="flex flex-col">
                                  <div className="flex flex-row items-center">
                                    <p className="font-sans font-semibold text-base md:text-2xl text-left ml-2">
                                      When are we delivering {DogNames}'s food?
                                    </p>
                                  </div>
                                  <div className="w-full mt-4">
                                    <div className="mb-3 inline-flex w-full">
                                      <div className="flex w-full flex-col">
                                        <div className="inlineDatepicker relative flex w-full flex-shrink-0 flex-row items-center">
                                          {/* <Field
                                            type="text"
                                            name="deliveryDate"
                                            id="deliveryDate"
                                            value={deliveryDateText}
                                            readOnly
                                            onClick={() => {
                                              // setShowSelectDeliveryDateModal(true)
                                            }}
                                            className={`${checkInputState(
                                              'deliveryDate'
                                            )} peer block w-full self-center rounded-full border border-gray-300 bg-white pt-5 text-center focus:border-highlight focus:ring-highlight`}
                                            onBlur={() => {
                                              if (typeof window.gtag !== 'undefined') {
                                                window.gtag('event', 'clicked_delivery_date', {
                                                  page: 'sign_up_checkout_new'
                                                })
                                              }
                                            }}
                                          /> */}
                                          <DatePicker
                                            name="deliveryDate"
                                            id="deliveryDate"
                                            disabled={shippingDates?.length === 0}
                                            placeholder="Delivery date *"
                                            calendarStartDay={1}
                                            value={deliveryDateText}
                                            includeDates={shippingDates?.map(date => {
                                              return new Date(date.date)
                                            })}
                                            className={`${checkInputState(
                                              'deliveryDate'
                                            )} z-10 peer block w-full self-center rounded-full border border-gray-300 bg-white py-3 text-center focus:border-highlight focus:ring-highlight`}
                                            inputMode="none"
                                            onFocus={(e: any) => {
                                              if (typeof window.gtag !== 'undefined') {
                                                window.gtag('event', 'clicked_delivery_date', {
                                                  page: 'sign_up_checkout_new'
                                                })
                                              }

                                              e.target.blur()
                                            }}
                                            dayClassName={(date: any) => {
                                              const dateObj = new Date(date)
                                              const validDate = shippingDates?.filter(
                                                dateFromShippingDates =>
                                                  dayjs(dateFromShippingDates.date).format('DD/MM/YYYY') ===
                                                  dayjs(date).format('DD/MM/YYYY')
                                              )

                                              // if (validDate.length === 1 && dateObj.getDay() === 6) {
                                              //   return '!text-red-500'
                                              // } else if (validDate.length === 0 && dateObj.getDay() === 6) {
                                              //   return '!text-red-300'
                                              // } else {
                                              //   return undefined
                                              // }
                                            }}
                                            calendarContainer={({ className, children }) => (
                                              <div>
                                                <CalendarContainer
                                                  className={`block w-full ${checkoutV3 ? 'rounded-2xl border-2 border-[#489BAB] focus:border-[#B0CDD5]' : 'rounded-md border border-gray-300 focus:border-[#B0CDD5]'}  bg-white p-4 text-left placeholder-base focus:ring-0`}
                                                >
                                                  <div style={{ position: 'relative' }}>{children}</div>
                                                  <div className="mx-auto md:w-full pl-3 text-sm">
                                                    {activeDeliveryPrice === 0 && (
                                                      <span className="flex w-full pt-4">
                                                        Free delivery on first order
                                                      </span>
                                                    )}
                                                    {activeDeliveryPrice === 399 && (
                                                      <span className="flex w-full">£3.99 Weekday delivery</span>
                                                    )}
                                                    {/* <span className="flex w-full text-[#F58C82]">
                                                      £4.99 - Saturday delivery
                                                    </span> */}
                                                  </div>
                                                </CalendarContainer>
                                              </div>
                                            )}
                                            onBlur={() => {
                                              console.error(
                                                `handleFieldDataDeliveryDateValues - ${JSON.stringify(props.values)}, ${JSON.stringify(formikRef?.current?.values)} `
                                              )
                                              handleFieldData(formikRef?.current?.values)
                                              formikRef?.current?.setFieldTouched('deliveryDate')
                                            }}
                                            onSelect={(date: Date) => {
                                              formikRef?.current?.setFieldValue('deliveryDate', date)
                                              setDeliveryDateSelected(date)
                                              if (typeof window.gtag !== 'undefined') {
                                                window.gtag('event', 'selected_delivery_date', {
                                                  page: 'sign_up_checkout_new',
                                                  date: date.toString()
                                                })
                                              }
                                            }}
                                          />
                                        </div>
                                        {props.errors.deliveryDate && formErrors.includes('Delivery date') ? (
                                          <div className="p-2 text-sm text-[#FF220C] sm:ml-2 flex justify-center">
                                            {props.errors.deliveryDate}
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Discount section mobile view */}
                            {isMobileView && renderDiscountMobile()}

                            {/* Pricing section mobile view */}
                            {isMobileView && renderSubscriptionSummaryPricingMobile()}

                            {/* Payment Section */}
                            <>
                              <div className={`${isMobileView ? 'mt-8' : 'mt-2'} w-full mx-auto`}>
                                <div className="flex flex-col bg-white rounded-3xl p-4 lg:p-8">
                                  <p className="font-sans font-semibold text-base md:text-2xl text-left">
                                    Finally, your payment details
                                  </p>
                                  {paymentRequest && !payEvent && (
                                    <>
                                      {/* <div className="mt-4 flex justify-center items-center mx-auto">
                                            <Elements stripe={stripePromise}>
                                              <button
                                                className="bg-black text-white px-8 py-2 font-sans font-medium rounded-full text-2xl w-full flex flex-row items-center"
                                                onClick={() => {
                                                  if (standardPaymentRequest) {
                                                    standardPaymentRequest.show()
                                                  }
                                                }}
                                              >
                                                {standardPaymentRequest._activeBackingLibraryName === 'APPLE_PAY' && (
                                                  <StaticImage
                                                    className="flex items-center justify-center mx-auto"
                                                    alt="Apple Pay"
                                                    objectFit="contain"
                                                    width={60}
                                                    src="../../../images/checkout/ApplePay.svg"
                                                  />
                                                )}
                                                {standardPaymentRequest._activeBackingLibraryName === 'GOOGLE_PAY' && (
                                                  <StaticImage
                                                    className="flex items-center justify-center mx-auto"
                                                    alt="Google Pay"
                                                    objectFit="contain"
                                                    width={60}
                                                    src="../../../images/checkout/GPay.svg"
                                                  />
                                                )}
                                                // This is not working correctly
                                                {standardPaymentRequest._activeBackingLibraryName !== 'GOOGLE_PAY' &&
                                                  standardPaymentRequest._activeBackingLibraryName !== 'APPLE_PAY' &&
                                                  stringFormatter(
                                                    standardPaymentRequest._activeBackingLibraryName
                                                  ).replace('_', ' ')}
                                              </button>
                                            </Elements>
                                          </div> */}
                                      <div className="mt-4">
                                        <PaymentRequestButtonElement
                                          onClick={() => {
                                            if (typeof window.gtag !== 'undefined') {
                                              const recipeItems = plan?.recipes?.map(r => {
                                                return {
                                                  item_id: r.id,
                                                  item_name: r.name,
                                                  quantity: r.quantity,
                                                  item_list_id: 'recipes',
                                                  item_list_name: 'Recipes'
                                                }
                                              })
                                              const addonItems = plan?.addons?.map(a => {
                                                return {
                                                  item_id: a.id,
                                                  item_name: a.name,
                                                  price: (a.price / 100).toFixed(2),
                                                  quantity: a.quantity,
                                                  item_list_id: 'addons',
                                                  item_list_name: 'Addons'
                                                }
                                              })
                                              window.gtag('event', 'add_payment_info', {
                                                currency: 'GBP',
                                                value: (currentPrices?.total / 100).toFixed(2),
                                                coupon: lastValidatedCoupon.code,
                                                payment_type: paymentRequest._activeBackingLibraryName,
                                                items: recipeItems.concat(addonItems)
                                              })
                                            }
                                            // This isn't an error, but the Logger component only catches errors
                                            // https://www.npmjs.com/package/cloudwatch-front-logger
                                            console.error(`Clicked_${paymentRequest._activeBackingLibraryName} button`)
                                          }}
                                          options={{ paymentRequest }}
                                        />
                                      </div>
                                      <div className="-mb-1 mt-4 text-center font-bold">OR</div>
                                    </>
                                  )}
                                  {!payEvent && (
                                    <div>
                                      <div>
                                        {!formikRef.current?.values.firstName.includes('PHONE PAYMENT') && (
                                          <CardElementCtxAlternative.Provider value={{ cardError, setCardError }}>
                                            <CardCheckoutAlternate
                                              errorMessage={localErrorMessage}
                                              onFocus={() => {
                                                if (typeof ttq !== 'undefined') {
                                                  ttq.track('AddPaymentInfo')
                                                }

                                                if (typeof window.gtag !== 'undefined') {
                                                  const recipeItems = plan?.recipes?.map(r => {
                                                    return {
                                                      item_id: r.id,
                                                      item_name: r.name,
                                                      quantity: r.quantity,
                                                      item_list_id: 'recipes',
                                                      item_list_name: 'Recipes'
                                                    }
                                                  })

                                                  const addonItems = plan?.addons?.map(a => {
                                                    return {
                                                      item_id: a.id,
                                                      item_name: a.name,
                                                      price: (a.price / 100).toFixed(2),
                                                      quantity: a.quantity,
                                                      item_list_id: 'addons',
                                                      item_list_name: 'Addons'
                                                    }
                                                  })

                                                  window.gtag('event', 'add_payment_info', {
                                                    currency: 'GBP',
                                                    value: (currentPrices?.total / 100).toFixed(2),
                                                    coupon: lastValidatedCoupon.code,
                                                    payment_type: 'Debit/Credit Card',
                                                    items: recipeItems.concat(addonItems)
                                                  })
                                                }
                                              }}
                                            />
                                          </CardElementCtxAlternative.Provider>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                  {payEvent && (
                                    <div className="mt-4 text-center font-bold mb-4">
                                      Your mobile payment has been verified
                                    </div>
                                  )}
                                  <div className={`${isBillingAddressSame ? 'mb-4' : 'mb-6'} grid items-center mt-3`}>
                                    <div className={`${formErrors ? 'mb-3' : 'mb-7'} grid items-center`}>
                                      <div>
                                        <label className="flex flex-row items-center">
                                          <Field
                                            data-testid="isBillingAddressSame"
                                            id="isBillingAddressSame"
                                            type="checkbox"
                                            name="isBillingAddressSame"
                                            checked={isBillingAddressSame}
                                            onChange={() => {
                                              setIsBillingAddressSame(prev => !prev)

                                              if (!isBillingAddressSame) {
                                                if (typeof window.gtag !== 'undefined') {
                                                  window.gtag('event', 'entered_billing_address', {
                                                    page: 'sign_up_checkout_new'
                                                  })
                                                }
                                              }
                                            }}
                                            className="text-fourth-checkbox mt-1 h-4 w-4 rounded-[4px] ml-1"
                                          />
                                          <span className="flex-wrap pl-2 text-sm">
                                            Billing and delivery address are the same
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  {/* Billing address section */}
                                  <div className={`${!isBillingAddressSame ? 'block' : 'hidden'}`}>
                                    {!openBillingAddressInputs && showBillingAddressFinder && (
                                      <div className="mb-7">
                                        <div>
                                          <Field
                                            type="text"
                                            id="billingAddressSearch"
                                            name="billingAddressSearch"
                                            placeholder="Billing address *"
                                            className="block w-full rounded-full border border-gray-300 bg-white py-3 px-4 text-center placeholder-base focus:border-[#B0CDD5] focus:ring-0"
                                          />
                                          {props.errors.billingAddressSearch && props.touched.billingAddressSearch ? (
                                            <div className="text-xs text-red-500">
                                              {props.errors.billingAddressSearch}
                                            </div>
                                          ) : null}
                                        </div>
                                        <button
                                          data-testid="billingAddressNotShown"
                                          className="w-full text-center text-sm underline "
                                          onClick={() => setOpenBillingAddressInputs(true)}
                                        >
                                          My address isn't shown
                                        </button>
                                      </div>
                                    )}
                                    <div
                                      className={`${
                                        formikRef.current?.values['billingAddress1'] || openBillingAddressInputs
                                          ? 'block'
                                          : 'hidden'
                                      }`}
                                    >
                                      <div className="mt-3">
                                        <div className="mb-7 inline-flex w-full sm:w-1/2">
                                          <div className="flex w-full flex-col">
                                            <div className="relative flex w-full flex-shrink-0 flex-row items-center">
                                              <Field
                                                data-testid="billingFirstName"
                                                type="text"
                                                name="billingFirstName"
                                                id="billingFirstName"
                                                className={`${checkInputState(
                                                  'billingFirstName'
                                                )} peer block w-full self-center rounded-full border border-gray-300 bg-white pt-4 text-center focus:border-highlight focus:ring-highlight mr-1`}
                                              />
                                              <label
                                                className={`absolute top-[3px] inline-flex h-[80%] w-full transform cursor-text appearance-none items-center justify-center duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                                  props.values.billingFirstName ? 'ml-0 -translate-y-3 scale-75' : ''
                                                }`}
                                                htmlFor="billingFirstName"
                                              >
                                                Billing name<span className="ml-1">*</span>
                                              </label>
                                            </div>
                                            {props.errors.billingFirstName && props.touched.billingFirstName ? (
                                              <div className="p-2 text-sm text-[#FF220C] sm:mr-2">
                                                {props.errors.billingFirstName}
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                        <div className="mb-7 inline-flex w-full sm:w-1/2">
                                          <div className="flex w-full flex-col">
                                            <div className="relative flex w-full flex-shrink-0 flex-row items-center">
                                              <Field
                                                data-testid="billingLastName"
                                                type="text"
                                                name="billingLastName"
                                                id="billingLastName"
                                                className={`${checkInputState(
                                                  'billingLastName'
                                                )} peer block w-full self-center rounded-full border border-gray-300 bg-white pt-4 text-center focus:border-highlight focus:ring-highlight ml-1`}
                                              />
                                              <label
                                                className={`absolute top-[3px] inline-flex h-[80%] w-full transform cursor-text appearance-none items-center justify-center duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                                  props.values.billingLastName ? 'ml-0 -translate-y-3 scale-75' : ''
                                                }`}
                                                htmlFor="billingLastName"
                                              >
                                                Billing surname<span className="ml-1">*</span>
                                              </label>
                                            </div>
                                            {props.errors.billingLastName && props.touched.billingLastName ? (
                                              <div className="p-2 text-sm text-[#FF220C] sm:ml-2">
                                                {props.errors.billingLastName}
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>

                                      <div className="relative mb-7">
                                        <div className="relative flex w-full flex-shrink-0 flex-row items-center">
                                          <Field
                                            data-testid="billingAddress1"
                                            type="text"
                                            name="billingAddress1"
                                            id="billingAddress1"
                                            className={`${checkInputState(
                                              'billingAddress1'
                                            )} peer block w-full self-center rounded-full border border-gray-300 bg-white pt-4 text-center focus:border-highlight focus:ring-highlight`}
                                          />
                                          <label
                                            className={`absolute top-[3px] inline-flex h-[80%] w-full transform cursor-text appearance-none items-center justify-center duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                              props.values.billingAddress1 ? 'ml-0 -translate-y-3 scale-75' : ''
                                            }`}
                                            htmlFor="billingAddress1"
                                          >
                                            Billing address 1<span className="ml-1">*</span>
                                          </label>
                                        </div>
                                        {props.errors.billingAddress1 && props.touched.billingAddress1 ? (
                                          <div className="p-2 text-sm text-[#FF220C]">
                                            {props.errors.billingAddress1}
                                          </div>
                                        ) : null}
                                      </div>

                                      <div className="relative mb-7">
                                        <div className="relative flex w-full flex-shrink-0 flex-row items-center">
                                          <Field
                                            data-testid="billingAddress2"
                                            type="text"
                                            name="billingAddress2"
                                            id="billingAddress2"
                                            className={`${checkInputState(
                                              'billingAddress2'
                                            )} peer block w-full self-center rounded-full border border-gray-300 bg-white pt-4 text-center focus:border-highlight focus:ring-highlight`}
                                          />
                                          <label
                                            className={`absolute top-[3px] inline-flex h-[80%] w-full transform cursor-text appearance-none items-center justify-center duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                              props.values.billingAddress2 ? 'ml-0 -translate-y-3 scale-75' : ''
                                            }`}
                                            htmlFor="billingAddress2"
                                          >
                                            Billing address 2
                                          </label>
                                        </div>
                                      </div>

                                      <div className="relative mb-7">
                                        <div className="relative flex w-full flex-shrink-0 flex-row items-center">
                                          <Field
                                            data-testid="billingCity"
                                            type="text"
                                            name="billingCity"
                                            id="billingCity"
                                            className={`${checkInputState(
                                              'billingCity'
                                            )} peer block w-full self-center rounded-full border border-gray-300 bg-white pt-4 text-center focus:border-highlight focus:ring-highlight`}
                                          />
                                          <label
                                            className={`absolute top-[3px] inline-flex h-[80%] w-full transform cursor-text appearance-none items-center justify-center duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                              props.values.billingCity ? 'ml-0 -translate-y-3 scale-75' : ''
                                            }`}
                                            htmlFor="billingCity"
                                          >
                                            City<span className="ml-1">*</span>
                                          </label>
                                        </div>
                                        {props.errors.billingCity && props.touched.billingCity ? (
                                          <div className="p-2 text-sm text-[#FF220C]">{props.errors.billingCity}</div>
                                        ) : null}
                                      </div>

                                      <div className="relative mb-7">
                                        <div className="relative flex w-full flex-shrink-0 flex-row items-center">
                                          <Field
                                            data-testid="billingCounty"
                                            type="text"
                                            name="billingCounty"
                                            id="billingCounty"
                                            className={`${checkInputState(
                                              'billingCounty'
                                            )} peer block w-full self-center rounded-full border border-gray-300 bg-white pt-4 text-center focus:border-highlight focus:ring-highlight`}
                                          />
                                          <label
                                            className={`absolute top-[3px] inline-flex h-[80%] w-full transform cursor-text appearance-none items-center justify-center duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                              props.values.billingCounty ? 'ml-0 -translate-y-3 scale-75' : ''
                                            }`}
                                            htmlFor="billingCounty"
                                          >
                                            County<span className="ml-1">*</span>
                                          </label>
                                        </div>
                                        {props.errors.billingCounty && props.touched.billingCounty ? (
                                          <div className="p-2 text-sm text-[#FF220C]">{props.errors.billingCounty}</div>
                                        ) : null}
                                      </div>

                                      <div className="relative mb-8">
                                        <div className="relative flex w-full flex-shrink-0 flex-row items-center">
                                          <Field
                                            data-testid="billingPostcode"
                                            type="text"
                                            name="billingPostcode"
                                            id="billingPostcode"
                                            className={`${checkInputState(
                                              'billingPostcode'
                                            )} peer block w-full self-center rounded-full border border-gray-300 bg-white pt-4 text-center focus:border-highlight focus:ring-highlight`}
                                          />
                                          <label
                                            className={`absolute top-[3px] inline-flex h-[80%] w-full transform cursor-text appearance-none items-center justify-center duration-300 peer-focus:ml-0 peer-focus:-translate-y-3 peer-focus:scale-75 ${
                                              props.values.billingPostcode ? 'ml-0 -translate-y-3 scale-75' : ''
                                            }`}
                                            htmlFor="billingPostcode"
                                          >
                                            Postcode<span className="ml-1">*</span>
                                          </label>
                                        </div>
                                        {props.errors.billingPostcode && props.touched.billingPostcode ? (
                                          <div className="p-2 text-sm text-[#FF220C]">
                                            {props.errors.billingPostcode}
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                  <p className="mb-4 -mt-4 text-center text-sm font-bold hover:underline focus:underline">
                                    <a
                                      href="/terms-and-conditions"
                                      className="text-xs"
                                      target={'_blank'}
                                      rel="noreferrer"
                                    >
                                      Terms and conditions
                                    </a>
                                  </p>
                                  {submitErrors && formErrors?.length > 0 && (
                                    <div
                                      id="errorModule"
                                      className="relative !z-0 bg-[#F6E9DB] -mb-6 pb-12 p-4 rounded-t-xl text-center"
                                    >
                                      <p className={`font-sans-serif pb-2 ${checkoutV3 ? 'font-bold' : ''}`}>
                                        Please fill in these fields before you confirm the order:
                                      </p>
                                      <ul className="inline-block text-left">
                                        {formErrors?.length > 0 &&
                                          formErrors?.map((error: string, i: number) => (
                                            <li key={i} className="flex items-center justify-start mx-auto">
                                              {checkoutV3 && (
                                                <StaticImage
                                                  className="flex-shrink-0"
                                                  alt="Cross"
                                                  objectFit="contain"
                                                  width={12}
                                                  src={'../../../images/redCross.svg'}
                                                />
                                              )}
                                              {!checkoutV3 && (
                                                <StaticImage
                                                  className="flex-shrink-0"
                                                  alt="Cross"
                                                  objectFit="contain"
                                                  width={10}
                                                  src={'../../../images/crossRed.svg'}
                                                />
                                              )}

                                              <p
                                                className={`justify-center items-center text-left ml-1 font-sans-serif text-sm ${checkoutV3 ? 'font-normal' : 'font-bold'}`}
                                              >
                                                {error}
                                              </p>
                                            </li>
                                          ))}
                                      </ul>
                                    </div>
                                  )}
                                  <button
                                    id="confirmOrder"
                                    data-testid="confirmOrder"
                                    onClick={() => {
                                      const startSubscriptionBtn = document.getElementById('confirmOrder')
                                      startSubscriptionBtn?.scrollIntoView({ block: 'center' })
                                      getFormErrors(props)
                                      setSendConsoleErrorToCloudWatch(true)
                                      // Log to GA, confirm order attempt with errors
                                      if (Object.keys(formikRef.current.errors).length > 0) {
                                        if (typeof window.gtag !== 'undefined') {
                                          window.gtag('event', 'invalid_confirm_order', {
                                            page: 'sign_up_checkout_new',
                                            errors: JSON.stringify(formikRef.current.errors)
                                          })
                                        }
                                      }
                                      nextClick(props.handleSubmit)
                                    }}
                                    disabled={loading || submitting || awaiting3ds}
                                    type="submit"
                                    className="btn-cta scale-animation !px-6 !py-3 !text-xl w-full font-semibold !z-10"
                                  >
                                    {loading || submitting || props.isSubmitting || awaiting3ds ? (
                                      <div className="align-center flex justify-center">
                                        <div className="border-white-900 mr-2 h-5 w-5 animate-spin rounded-full border-b-2"></div>
                                        <div>Please wait</div>
                                      </div>
                                    ) : (
                                      confirmBtnText
                                    )}
                                  </button>
                                  {coupon?.newCustomerTreatOffer && !treatPageBeforeCheckout && (
                                    <div className="grid grid-cols-1 rounded-3xl px-0 sm:grid-cols-1 sm:gap-x-3 md:mx-auto w-full relative my-2 md:w-3/4 md:px-8 lg:w-full">
                                      <div className="md:pt-4 flex flex-row items-center justify-center">
                                        <StaticImage
                                          objectFit={'contain'}
                                          className="mr-2 flex-shrink-0"
                                          alt="1"
                                          width={40}
                                          src="../../../images/dog-details/bone.svg"
                                        />
                                        <p className="text-left text-xs md:text-base font-sans">
                                          Choose your
                                          <span className="font-bold"> FREE Treat </span>
                                          after Checkout
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              {isMobileView && renderCardLogos()}
                              {checkoutReviews && (
                                <div className="mt-8 block lg:hidden">
                                  {isMobileView && showReviews && reviewsType && <Reviews reviewsType={reviewsType} />}
                                </div>
                              )}
                            </>
                          </div>

                          {/* Subscription summary section desktop view */}
                          {!isMobileView && renderSubscriptionSummaryDesktop()}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* {!expressButtonVisible && !payEvent && paymentRequest && expressCheckoutAB && !isMobileDevice() && (
                    <StickyFooter className="h-20 fixed bottom-0 left-0 right-0 ml-auto max-h-32 bg-[#F6E9DB] shadow-[0_-5px_15px_rgba(0,0,0,0.25)] z-50 lg:hidden">
                      <div className="flex w-full flex-col">
                        <div className={`relative flex w-full items-center justify-between py-2`}>
                          <div className="w-11/12 mx-auto lg:mt-0 lg:mb-6 flex flex-col justify-center items-center">
                            <Elements stripe={stripePromise}>
                              <button
                                className="bg-black text-white py-2 px-2 font-sans font-medium rounded-full text-lg lg:text-xl w-full lg:w-11/12 flex justify-center items-center mx-auto"
                                onClick={() => {
                                  if (paymentRequest) {
                                    paymentRequest.show()
                                    if (typeof window.gtag !== 'undefined') {
                                      window.gtag('event', 'express_checkout_footer_clicked', {
                                        page: 'sign_up_checkout_new'
                                      })
                                    }
                                  }
                                }}
                              >
                                Express checkout
                                {paymentRequest._activeBackingLibraryName === 'APPLE_PAY' && (
                                  <StaticImage
                                    className="ml-3"
                                    alt="Apple Pay"
                                    objectFit="contain"
                                    width={50}
                                    src="../../../images/checkout/ApplePay.svg"
                                  />
                                )}
                                {(paymentRequest._activeBackingLibraryName === 'GOOGLE_PAY' ||
                                  (paymentRequest._activeBackingLibraryName === 'BROWSER' && browser !== 'Apple')) && (
                                  <StaticImage
                                    className="ml-3"
                                    alt="Google Pay"
                                    objectFit="contain"
                                    width={50}
                                    src="../../../images/checkout/GPay.svg"
                                  />
                                )}
                              </button>
                            </Elements>
                          </div>
                        </div>
                      </div>
                    </StickyFooter>
                  )} */}
                </div>
              )
            }}
          </Formik>
        </div>

        {/* Checkout Icons and FAQS Section */}
        <div className="w-10/12 mx-auto rounded-b-2xl mb-8 -pt-2">
          {/* Checkout Icons */}
          {/* {emptyBowlVsMoneyBack === 0 && ( */}
          <div className="-mb-8 grid grid-cols-1 px-2 sm:grid-cols-1 sm:pt-4 md:mx-auto md:w-3/4 md:px-8 lg:w-full max-w-3xl">
            <div className="grid grid-cols-2 items-center justify-center gap-2 md:grid-cols-4">
              <div className="flex flex-row justify-center items-center">
                <div>
                  <StaticImage
                    className="flex-shrink-0"
                    alt="Fussy dog approved"
                    objectFit="contain"
                    width={50}
                    src="../../../images/dog-details/fussy-dog.svg"
                  />
                </div>{' '}
                <p className="mx-auto w-1/2 text-center text-xs md:w-2/3">Fussy dog approved</p>
              </div>
              <div className="flex flex-row justify-center items-center">
                <div>
                  <StaticImage
                    className="flex-shrink-0"
                    alt="Amend, pause or cancel anytime"
                    objectFit="contain"
                    width={50}
                    src="../../../images/dog-details/pause.svg"
                  />
                </div>{' '}
                <p className="text-center text-xs">Amend, pause or cancel anytime</p>
              </div>
              <div className="flex flex-row justify-center items-center">
                <div>
                  <StaticImage
                    className="flex-shrink-0"
                    alt="Vet support available"
                    objectFit="contain"
                    width={50}
                    src="../../../images/dog-details/vet-support.svg"
                  />
                </div>{' '}
                <p className="mx-auto w-1/2 text-center text-xs md:w-2/3">Vet support available</p>
              </div>
              <div className="flex flex-row justify-center items-center">
                <div>
                  <StaticImage
                    className="flex-shrink-0 "
                    alt="Hand cooked in our Shropshire kitchen"
                    objectFit="contain"
                    width={50}
                    src="../../../images/dog-details/cook.svg"
                  />
                </div>{' '}
                <p className="text-center text-xs">Hand cooked in our Shropshire kitchen</p>
              </div>
            </div>
          </div>
          {/* // )}  */}

          {/* {emptyBowlVsMoneyBack === 1 && (
            <div className="flex flex-col items-center justify-center text-right md:flex-row w-full md:w-2/3 mx-auto">
              <img className="relative mr-6 flex-shrink-0" alt="Empty bowl" width={80} src={EmptyBowl} />

              <div className="text-center md:text-start">
                <p className="h3 mb-4 text-xl md:text-2xl font-medium font-serif">Empty bowl guarantee</p>
                <p>
                  Try our food risk-free with our Empty bowl guarantee; if your dog doesn't like our food on their first
                  delivery, we'll refund you.
                </p>
              </div>
            </div>
          )}
          {emptyBowlVsMoneyBack === 2 && (
            <div className="flex flex-col items-center justify-center text-right md:flex-row w-full md:w-2/3 mx-auto">
              <img className="relative mr-6 flex-shrink-0" alt="Empty bowl" width={80} src={EmptyBowl} />

              <div className="text-center md:text-start">
                <p className="h3 mb-4 text-xl md:text-2xl font-medium font-serif">Money back guarantee</p>
                <p>
                  Try our food risk-free with our Money back guarantee; if your dog doesn't like our food on their first
                  delivery, we'll refund you.
                </p>
              </div>
            </div>
          )} */}
          <FaqAccordion
            faqs={faqs}
            backgroundColor={'#A6C7D1'}
            headingText={'FAQs'}
            headingTextColor={'primary'}
            faqQuestionColor={'primary'}
            faqAnswerColor={'primary'}
            headingType={HeadingType.P}
            headingClass={HeadingClass.H3}
            iconClass={'text-white'}
            page={'checkoutAlternative'}
          />
        </div>

        {/* Footer Box */}
        <div className="footer-wave-bottom-signup -mb-32 p-4 md:p-8">
          <div className="mb-4 w-full text-center font-serif">
            <div className="mx-auto mt-8 text-base !leading-[1.3] md:text-xl">
              <p className="pb-3">Still got questions?</p>
              <p>Get in touch with our team - they could talk dogs all day long</p>
            </div>
            <p className="mt-8 text-base md:text-xl">
              Call us on{' '}
              <a className="font-bold underline" href="tel:+441743384562">
                01743 384 562
              </a>{' '}
              or email{' '}
              <a className="font-bold underline" href="mailto:feedme@differentdog.com">
                feedme@differentdog.com
              </a>{' '}
            </p>
          </div>
          <div className="mt-10 hidden sm:block">
            <TrustPilotMicroCombo backgroundColor="transparent" />
          </div>
          <div className="mt-5 -mb-8 block sm:hidden">
            <TrustPilotMini backgroundColor="transparent" />
          </div>
          <div className="mx-auto mb-16 w-full md:w-4/5">
            <TrustBoxAlternate />
          </div>
        </div>
      </div>
      {errorMessage && errorMessage.message !== 'Generic error' && !localErrorMessage && (
        <ErrorModal
          show={showPopup}
          setShow={setShowPopup}
          errorMessage={errorMessage?.popup?.message ?? errorMessage?.message}
          url={errorMessage?.popup?.url}
          action={errorMessage?.popup?.action}
          actionText={errorMessage?.popup?.actionText}
        />
      )}
      {localErrorMessage && (
        <ErrorModal
          show={showPopup}
          setShow={setShowPopup}
          errorMessage={localErrorMessage}
          url={errorMessage?.popup?.url}
          action={errorMessage?.popup?.action}
          actionText={errorMessage?.popup?.actionText}
        />
      )}
      {/* {showSelectDeliveryDateModal && (
        <SelectDeliveryDateModal
          key={`selectDeliveryDateModal`}
          show={showSelectDeliveryDateModal}
          setShow={setShowSelectDeliveryDateModal}
          selectedDelivery={formikRef?.current?.values?.deliveryDate || shipping.deliveryDate}
          setSelectedDelivery={setDeliveryDateSelected}
          weeks={weeks}
        />
      )} */}
    </>
  )
}

export default ShippingConfirmAlternative
