export interface TopTenBreeds {
  [key: string]: string
}

export const topTenBreeds: TopTenBreeds = {
  '2391d364-bccd-4a4e-816c-51a3b6d4578a': 'cavapoo',
  '82ce1c77-d52d-4cf9-8cc1-88039e4d69a0': 'cockapoo',
  '94bde5a5-2ddb-47ed-ac3d-28f95f189aa5': 'staffordshireBullTerrier',
  '4c7c572d-cbc2-4d95-8b96-9fba0746b158': 'dachshund',
  '7d6bbe72-7652-4fd4-89d4-6e2ce9df386c': 'cockerSpaniel',
  'f91a51bb-34b7-4456-ae00-ecfbaf9acdf5': 'frenchBulldog',
  '20481671-e591-474b-85e5-daf441f7ea99': 'labradorRetriever'
}

export interface Review {
  type:
    | fussy
    | allergy
    | cavapoo
    | cockapoo
    | staffordshireBullTerrier
    | dachshund
    | cockerSpaniel
    | frenchBulldog
    | labradorRetriever
  name: string
  text: string
  link: string
  generic: boolean
}

export const reviews: Review[] = [
  {
    type: 'fussy',
    name: 'Stafford',
    text: `There is absolutely no hesitation when I put her bowl down and she licks it clean!`,
    link: 'https://uk.trustpilot.com/reviews/6716ca5c462ce1290e97f0ec',
    generic: true
  },
  {
    type: 'fussy',
    name: 'Annalisa',
    text: `One of my dogs is very fussy and he is now licking the bowl clean!`,
    link: 'https://uk.trustpilot.com/reviews/6715337b4cbe76f93170d27e',
    generic: false
  },
  {
    type: 'fussy',
    name: 'Rhiannon',
    text: `We're 3 days in to Different Dog - and my god is he a different dog!`,
    link: 'https://uk.trustpilot.com/reviews/671231400b4cfc0f7baa94f6',
    generic: true
  },
  {
    type: 'fussy',
    name: 'Gaynor',
    text: `Fussy dog no more! Different Dog - a game changer.`,
    link: 'https://uk.trustpilot.com/reviews/6705592619edefa34c7babae',
    generic: false
  },
  {
    type: 'fussy',
    name: 'Jo',
    text: `You can see the vegetables and meats in the food, it looks healthy and freshly prepared.`,
    link: 'https://uk.trustpilot.com/reviews/66fad090c18fb54e9ec7bd10',
    generic: true
  },
  {
    type: 'fussy',
    name: 'Kayleigh',
    text: `Tried a lot of other brands, with this one she even gets excited for dinner time and licks the bowl clean.`,
    link: 'https://uk.trustpilot.com/reviews/6702a0d4cb9641a44191b0c4',
    generic: true
  },
  {
    type: 'fussy',
    name: 'Monica',
    text: `Highly recommend this company to any dog owners out there unsure about trying their food.`,
    link: 'https://uk.trustpilot.com/reviews/66f02d0730478fc16ffef244',
    generic: true
  },
  {
    type: 'fussy',
    name: 'Mike',
    text: `It's a real joy to see 3 clean bowls every day of the week.`,
    link: 'https://uk.trustpilot.com/reviews/66f11a4197f8ed315d764034',
    generic: true
  },
  {
    type: 'fussy',
    name: 'Jacqueline',
    text: `If you love your dog, give it a go! Since finding Different Dog, Darcy can't wait for her meals.`,
    link: 'https://uk.trustpilot.com/reviews/668ab1b7591eec6eb27d0dae',
    generic: true
  },
  {
    type: 'allergy',
    name: 'Anne',
    text: `This food is a game changer. Malshi absolutely loves it and keeps licking his bowl.`,
    link: 'https://uk.trustpilot.com/reviews/6724ff4981f730e9bef0cda3',
    generic: true
  },
  {
    type: 'allergy',
    name: 'Lizzie',
    text: `Allergy-friendly for Rocco and he loves it! Couldn't be happier with how well he is doing on this food.`,
    link: 'https://uk.trustpilot.com/reviews/647766dc543172398687538f',
    generic: false
  },
  {
    type: 'allergy',
    name: 'Marty',
    text: `The best food for our best girl. Our dog has more energy and has had zero allergy symptoms.`,
    link: 'https://uk.trustpilot.com/reviews/630789096a3e1ed2c3c07f46',
    generic: false
  },
  {
    type: 'allergy',
    name: 'Natasha',
    text: `Perfect for my dog with a grain allergy.`,
    link: 'https://uk.trustpilot.com/reviews/5cd049cca843690a7cced1aa',
    generic: false
  },
  {
    type: 'allergy',
    name: 'Bonnie',
    text: `Do not look any further than Different Dog to feed your dog actual real good food.`,
    link: 'https://uk.trustpilot.com/reviews/630770c87f7a8621ee4931d2',
    generic: true
  },
  {
    type: 'cavapoo',
    name: 'Gaynor',
    text: `Fussy dog no more! Different Dog - a game changer.`,
    link: 'https://uk.trustpilot.com/reviews/6705592619edefa34c7babae',
    generic: false
  },
  {
    type: 'cavapoo',
    name: 'Avril',
    text: `My cavapoo loves this food! He demolished his bowl in seconds.`,
    link: 'https://uk.trustpilot.com/reviews/670a33346ce60b124e51e679',
    generic: false
  },
  {
    type: 'cavapoo',
    name: 'Kerry',
    text: `Different dog is the only food my cavapoo will eat and clean his bowl.`,
    link: 'https://uk.trustpilot.com/reviews/66d9f607f29317f4fbd82172',
    generic: false
  },
  {
    type: 'cavapoo',
    name: 'Bonnie',
    text: `My 10 month old cavapoo gets excited for meal times for the first time..`,
    link: 'https://uk.trustpilot.com/reviews/65c0d60df450e708e1377d82',
    generic: false
  },
  {
    type: 'cavapoo',
    name: 'Ratna',
    text: `Our gorgeous Cavapoo absolutely goes mental for the Different Dog food!`,
    link: 'https://uk.trustpilot.com/reviews/65732a68a09ab51de9b782de',
    generic: false
  },
  {
    type: 'cockapoo',
    name: 'Emma & Sam',
    text: `This food is a game changer. Malshi absolutely loves it and keeps licking his bowl.`,
    link: 'https://uk.trustpilot.com/reviews/66f97ed65cd83af4e344bb63',
    generic: true
  },
  {
    type: 'cockapoo',
    name: 'Angela',
    text: `Allergy-friendly for Rocco and he loves it! Couldn't be happier with how well he is doing on this food.`,
    link: 'https://uk.trustpilot.com/reviews/64672ae45f6f2657f8bffe23',
    generic: false
  },
  {
    type: 'cockapoo',
    name: 'Jo',
    text: `The best food for our best girl. Our dog has more energy and has had zero allergy symptoms.`,
    link: 'https://uk.trustpilot.com/reviews/638fcef00b7fc02715d6855f',
    generic: false
  },
  {
    type: 'staffordshireBullTerrier',
    name: 'Donna',
    text: `With Different Dog Loki waits at his bowl for his meal, eats it down and is waiting for more!`,
    link: 'https://uk.trustpilot.com/reviews/663777d4c745b1e1e68b8a90',
    generic: true
  },
  {
    type: 'staffordshireBullTerrier',
    name: 'Tessa',
    text: `Watching my dog get so excited when I put this food in his bowl is amazing.`,
    link: 'https://uk.trustpilot.com/reviews/65c35e4dea041da8ddb93da9',
    generic: true
  },
  {
    type: 'staffordshireBullTerrier',
    name: 'Tony',
    text: `As soon as that nose got a whiff of Different Dog it was gone in seconds!`,
    link: 'https://uk.trustpilot.com/reviews/6471c1d609e7ee0e32064e44',
    generic: true
  },
  {
    type: 'dachshund',
    name: 'Happy customer',
    text: `Our miniature dachshund now jumps in excitement when he sees us take the food from the fridge.`,
    link: 'https://uk.trustpilot.com/reviews/65f9db613c9b2f2f1ea127ce',
    generic: false
  },
  {
    type: 'dachshund',
    name: 'Tricia',
    text: `Her coat is softer and shinier and she genuinely is a different dog!`,
    link: 'https://uk.trustpilot.com/reviews/65765995d48895bdeaaa5a3f',
    generic: true
  },
  {
    type: 'dachshund',
    name: 'CT',
    text: `Cannot say how good it has been for my very fussy dachshund! He licks the bowl clean!`,
    link: 'https://uk.trustpilot.com/reviews/6353c5918056669a30a82d82',
    generic: false
  },
  {
    type: 'cockerSpaniel',
    name: 'Lesley',
    text: `My cocker spaniel loves the food! I know it's not for humans but looks very appealing to me too.`,
    link: 'https://uk.trustpilot.com/reviews/672b40a3272f6fcf78d4623e',
    generic: false
  },
  {
    type: 'cockerSpaniel',
    name: 'Jacqueline',
    text: `If you love your dog, give it a go! Since finding Different Dog, Darcy can't wait for her meals.`,
    link: 'https://uk.trustpilot.com/reviews/668ab1b7591eec6eb27d0dae',
    generic: true
  },
  {
    type: 'cockerSpaniel',
    name: 'Brian',
    text: `Our dog devours her meals and has more energy!`,
    link: 'https://uk.trustpilot.com/reviews/6672ad0de7d5b9e6bdc51aea',
    generic: true
  },
  {
    type: 'cockerSpaniel',
    name: 'Kim',
    text: `My rescue spaniel Grace loves the meals!`,
    link: 'https://uk.trustpilot.com/reviews/65a99aaf2c977dbd73e804c4',
    generic: false
  },
  {
    type: 'cockerSpaniel',
    name: 'Alex',
    text: `My cocker spaniel IS a different dog. This food is fantastic!`,
    link: 'https://uk.trustpilot.com/reviews/654ac6264d86cf48ed64aeba',
    generic: false
  },
  {
    type: 'cockerSpaniel',
    name: 'Louise',
    text: `Stomach problems, a thing of the past!`,
    link: 'https://uk.trustpilot.com/reviews/649021b3d90f3ca761c4beee',
    generic: false
  },
  {
    type: 'frenchBulldog',
    name: 'Chris',
    text: `Brilliant combination of healthy meals, a hit with our large bulldog.`,
    link: 'https://uk.trustpilot.com/reviews/66fe93aa92d8f4dcb885adfc',
    generic: false
  },
  {
    type: 'frenchBulldog',
    name: 'Rebecca',
    text: `Our English Bulldog is absolutely loving all his meals!`,
    link: 'https://uk.trustpilot.com/reviews/63eabef79b64b1bdaf567891',
    generic: false
  },
  {
    type: 'frenchBulldog',
    name: 'Kerry',
    text: `My bulldog Maggie absolutely loves the food! You can tell it's freshly made.`,
    link: 'https://uk.trustpilot.com/reviews/65dcf3f05630cbb0056e9b7a',
    generic: false
  },
  {
    type: 'frenchBulldog',
    name: 'Nicole',
    text: `One week in and we are seizure free! Will be continuing with different dog.`,
    link: 'https://uk.trustpilot.com/reviews/64073c6400e20d6f7a842952',
    generic: false
  },
  {
    type: 'frenchBulldog',
    name: 'Lena',
    text: `My girl loves her food and the bone broth. We are super excited!`,
    link: 'https://uk.trustpilot.com/reviews/65a8fee0cfda6430b48e1c86',
    generic: false
  },
  {
    type: 'labradorRetriever',
    name: 'Wendy',
    text: `For the first time Pixie seeks me out at her feed times and licks her bowl out every time.`,
    link: 'https://uk.trustpilot.com/reviews/6656f06f1a963ad71c3c63a2',
    generic: true
  },
  {
    type: 'labradorRetriever',
    name: 'Clair',
    text: `Different Dog has been a completely changed our dog's life!`,
    link: 'https://uk.trustpilot.com/reviews/63bc0f034d0773066a3258f3',
    generic: true
  },
  {
    type: 'labradorRetriever',
    name: 'Will',
    text: `Decided to try it for my boy. He absolutely loves it and hasn't refused anything.`,
    link: 'https://uk.trustpilot.com/reviews/63f91c339bfcb75ea8c6aba4',
    generic: true
  },
  {
    type: 'labradorRetriever',
    name: 'Sheena',
    text: `Rolo is absolutely loving his new food and is thriving!`,
    link: 'https://uk.trustpilot.com/reviews/64a687fab73e729b2a2438f9',
    generic: true
  },
  {
    type: 'labradorRetriever',
    name: 'LW',
    text: `Can't wait for meal times! My Labrador, Cooper, is loving his Different Dog food.`,
    link: 'https://uk.trustpilot.com/reviews/64157f5949d4305e600b779c',
    generic: false
  }
]
